import React from 'react';
import {twMerge} from 'tailwind-merge';

interface ComptInitialsAvatarProps {
  children: React.ReactNode;
  className?: string;
}

export const ComptInitialsAvatar = ({children, className}: ComptInitialsAvatarProps) => (
  <div
    className={twMerge(`flex h-[62px] w-[62px] border-[3px] bg-brand-50 rounded-full aspect-square
   border-brand-500 place-content-center items-center ${className}`)}
  >
    {children}
  </div>
);

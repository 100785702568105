import React, {HTMLAttributes} from 'react';
import {Allotment} from '@compt/types/allotments';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import DOMPurify from 'dompurify';

export interface NonMonetaryRecognitionContentProps extends HTMLAttributes<HTMLElement> {
  allotment: Allotment;
  userCountryCode?: SupportedCountriesType;
  onFullDescriptionClicked?: (allotment: Allotment) => void;
}

export const NonMonetaryRecognitionCardContent = (props: NonMonetaryRecognitionContentProps) => (
  <div className="flex flex-col justify-between">
    <p className="label3 mb-2 text-color-body1">Unlimited 🎉</p>
    <p
      className="body3 line-clamp-[3] inner-html mb-300"
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(props.allotment.cycle.description),
      }}
    />
    <div className="flex flex-row">
      <button className="label3 mr-4 text-color-link" onClick={props.onClick}>
        Give shout out
      </button>
      {props.allotment.cycle.description && (
        <button
          className="flex flex-row items-center"
          onClick={() => {
            if (props.onFullDescriptionClicked) {
              props.onFullDescriptionClicked(props.allotment);
            }
          }}
        >
          <p className="label3 text-color-body1">View details</p>
          <ComptSvgIcon
            className="flex-row"
            iconName="chevron-right-icon-gray-700"
            svgProp={{width: '18px', height: '18px'}}
          />
        </button>
      )}
    </div>
  </div>
);

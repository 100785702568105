import {GroupType, GroupTypeOption, UserGroup} from '@compt/types/user-groups/user-groups';
import {FormAmountOverride} from './compt-group-table.types';

/**
 * Return all group options associated with the column's groupType.
 * @param selectedGroupType  (e.g. department, country, etc..)
 * @param userGroups List of all options for every group type
 */
export function getGroupOptions(selectedGroupTypeKey: string, userGroups: UserGroup[]) {
  if (!selectedGroupTypeKey) return [];

  const filteredAndFormatted = userGroups
    .filter((option) => option[0] === selectedGroupTypeKey)
    .map((option) => ({key: option[1], name: option[2], groupType: option[0]}));

  return filteredAndFormatted;
}

/**
 * Retrieves the formatted option for a group key
 * @param selectedGroupKey a value for a given group type key (e.g. the group type
 * "Department" contains group "HR department")
 * @param selectedGroupTypeKey  (e.g. department, country, etc..)
 * @param userGroups List of all options for every group type
 * @returns
 */
export function getGroupOption(
  selectedGroupKey: string,
  selectedGroupTypeKey: string,
  userGroups: UserGroup[],
) {
  const groupOptions = getGroupOptions(selectedGroupTypeKey, userGroups);
  const selectedGroupOption = groupOptions.find((option) => option.key === selectedGroupKey);

  return selectedGroupOption;
}

/**
 * Returns the group type as a group type option for proper display in fields
 * @param groupType (e.g. department, country, etc..)
 * @param groupTypeOptions contains formatted options for groupTypes, including the key, header, and type
 */
export function getGroupTypeOption(groupType: GroupType, groupTypeOptions: GroupTypeOption[]) {
  const groupTypeOption = groupTypeOptions.find((option) => option.key === groupType);
  return groupTypeOption;
}

export function findDuplicateRowIndices(rows: FormAmountOverride[]) {
  const seenOverridesSet = new Map<string, number[]>();
  const duplicatesSet = new Set<number>();

  rows.forEach((row, rowIndex) => {
    if (!row.overrides) return;
    const hasUndefinedOverride = Object.values(row.overrides).some((override) => !override);

    if (hasUndefinedOverride) return;

    const sortedOverrides = Object.values(row.overrides)
      .map((override) => `${override.key}-${override.groupType}`)
      .sort()
      .join('|');

    if (seenOverridesSet.has(sortedOverrides)) {
      duplicatesSet.add(rowIndex);
      seenOverridesSet.get(sortedOverrides)?.forEach((index) => duplicatesSet.add(index));
    } else {
      seenOverridesSet.set(sortedOverrides, [rowIndex]);
    }
  });

  return duplicatesSet;
}

import React from 'react';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';

interface ComptFeatureProps {
  featureFlag: FeatureFlags;
  showErrorPage?: boolean;
  children: React.ReactElement;
}

/**
 * Renders the children only if the given feature is enabled.
 * @param featureFlag
 * @param children
 * @see featureEnabled
 * @example
 * Usage:
 * In the following example, the child, <p>International is enabled.</p>
 * will be rendered only if the feature is enabled.
 * ```
 * <ComptFeature featureFlag={FeatureFlags.INTERNATIONAL}>
 *   <p>International is enabled.</p>
 * </ComptFeature>
 * ```
 */
export const ComptFeature = (props: ComptFeatureProps) => {
  if (!featureEnabled(props.featureFlag) && props.showErrorPage) {
    return <Error404Page />;
  }

  if (!featureEnabled(props.featureFlag)) return null;

  return props.children;
};

import React, {ReactNode} from 'react';
import {Swiper} from 'swiper/react';
import {Swiper as SwiperCore} from 'swiper/types';
import {Navigation, Manipulation} from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './compt-carousel.scss';

interface ComptCarouselProps {
  children: ReactNode;
  className?: string;
  setSwiperRef?: React.Dispatch<React.SetStateAction<SwiperCore | null>>;
}

export const ComptCarousel = (props: ComptCarouselProps) => (
  <Swiper
    navigation
    pagination={{clickable: true}}
    spaceBetween={30}
    modules={[Navigation, Manipulation]}
    className={`
      flex flex-col xl:mr-10 w-full items-end lg:col-span-2 pb-6 mySwiper ${props.className}
    `}
    onSwiper={(swiper) => props.setSwiperRef?.(swiper)}
  >
    {props.children}
  </Swiper>
);

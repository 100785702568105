import React, {Dispatch, SetStateAction} from 'react';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';
import {TeamRosterSyncResult} from '@compt/types/team-roster-syncs/team-roster-syncs';
import {TeamRosterSyncResultStatus} from '@compt/types/team-roster-syncs/team-roster-syncs-status';
import {TeamRosterSyncStatus} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/components/team-roster-sync-status';

export class TeamRosterSyncResultTableController {
  getFilterConfiguration() {
    const statusOptions = Object.entries(TeamRosterSyncResultStatus)
      .map((status) => ({id: status[0], name: status[1]}))
      .filter(Boolean);

    const filterConfiguration = {
      textSearch: {
        filterType: SearchFilter,
        label: 'Team Roster Sync Result Table Search',
        options: [
          {
            id: 1,
            name: 'Team Roster Sync Result Table Search',
            placeholder: 'Search by email, name, or eid',
          },
        ],
        nonCollapsible: true,
      },
      status: {
        filterType: SelectFilter,
        label: 'Status',
        options: statusOptions,
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };

    return filterConfiguration;
  }

  static getInitialFilterValues() {
    const initialFilterValues = {
      textSearch: [''],
      status: [],
    };

    return initialFilterValues;
  }

  updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setSyncResultsQueryValues: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) {
      return;
    }

    const formattedFilter = formatFilterValuesOptions(filterValues, pagination, ordering);

    setSyncResultsQueryValues(formattedFilter);
    if (
      Array.isArray(filterValues?.status) &&
      filterValues?.status.length &&
      filterValues.status.length > 0
    ) {
      setFiltersApplied(true);
    } else if (
      filterValues?.textSearch &&
      filterValues?.textSearch?.length > 0 &&
      filterValues?.textSearch[0].length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }

  getColumnDefinition(onActionClicked: (result: TeamRosterSyncResult) => void) {
    const columnDefinition = {
      sync_result_id: {
        id: 'sync_result_id',
        name: 'Sync Result ID',
        selector: (syncResult: TeamRosterSyncResult) => (
          <p data-tag="allowRowEvents">{syncResult.id}</p>
        ),
        grow: 0.4,
        order: 1,
      },
      first_name: {
        id: 'first_name',
        name: 'First Name',
        selector: (syncResult: TeamRosterSyncResult) => (
          <p data-tag="allowRowEvents">{syncResult.first_name}</p>
        ),
        grow: 0.5,
        order: 2,
      },
      last_name: {
        id: 'last_name',
        name: 'Last Name',
        selector: (syncResult: TeamRosterSyncResult) => (
          <p data-tag="allowRowEvents">{syncResult.last_name}</p>
        ),
        grow: 0.5,
        order: 3,
      },
      email: {
        id: 'email',
        name: 'Email',
        selector: (syncResult: TeamRosterSyncResult) => (
          <p data-tag="allowRowEvents">{syncResult.email}</p>
        ),
        grow: 0.5,
        order: 4,
      },
      eid: {
        id: 'eid',
        name: 'EID',
        selector: (syncResult: TeamRosterSyncResult) => (
          <p data-tag="allowRowEvents">{syncResult.eid}</p>
        ),
        grow: 0.5,
        order: 5,
      },
      status: {
        id: 'status',
        name: 'Status',
        selector: (syncResult: TeamRosterSyncResult) => (
          <TeamRosterSyncStatus status={syncResult.status} />
        ),
        grow: 0.4,
        order: 6,
      },
      errors: {
        id: 'errors',
        name: 'Errors',
        grow: 0.75,
        order: 7,
        selector: () => <div />,
        format: (syncResult: TeamRosterSyncResult) => {
          let errorText = syncResult.errors;
          if (syncResult.errors && syncResult.errors.length > 50) {
            errorText = `${syncResult.errors?.slice(0, 50)}...`;
          }
          return (
            <p data-tag="allowRowEvents" className="body3 compt-text--medium text-color-error">
              {errorText}
            </p>
          );
        },
      },
      view: {
        id: 'view',
        ignoreRowClick: true,
        selector: (syncResult: TeamRosterSyncResult) => (
          <p className="text-color-link cursor-pointer" onClick={() => onActionClicked(syncResult)}>
            View
          </p>
        ),
        grow: 0,
        order: 7,
      },
    };

    return columnDefinition;
  }
}

const formatFilterValuesOptions = (
  filterValues?: FilterValues,
  pagination?: Pagination | null,
  ordering?: string | null,
) => {
  const formattedFilter: FormattedFilterObject = {};

  if (
    Array.isArray(filterValues?.status) &&
    filterValues?.status.length &&
    filterValues.status.length > 0
  ) {
    formattedFilter['status__in'] = filterValues?.status.map((status) => status.id).join(',');
  }

  if (filterValues?.textSearch && filterValues?.textSearch?.length > 0) {
    [formattedFilter['search']] = filterValues.textSearch;
  }

  formattedFilter.limit = MAX_ITEMS_PER_PAGE;

  if (pagination) {
    formattedFilter.offset = MAX_ITEMS_PER_PAGE * Math.max(pagination.page - 1, 0);
    formattedFilter.page = pagination.page;
  } else {
    formattedFilter.page = 1;
    formattedFilter.offset = 0;
  }

  if (ordering) {
    formattedFilter.ordering = ordering;
  }

  return formattedFilter;
};

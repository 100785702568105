import {TeamRecognitionUser} from '@compt/types/team-recognition/team-recognition';
import React from 'react';

export interface SelectedUsersProps {
  users: TeamRecognitionUser[];
  onRemove?: (user: TeamRecognitionUser) => void;
}

export const SelectedUsers = ({users, onRemove}: SelectedUsersProps) => (
  <ul className="divide-y divide-gray-100">
    {users.map((recipient) => (
      <li key={recipient.email} className="flex items-center justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <p className="text-sm font-semibold leading-6 text-gray-900">{recipient.full_name}</p>
            <p className="truncate text-xs leading-5 text-color-tertiary">{recipient.email}</p>
          </div>
        </div>
        {onRemove && (
          <button
            type="button"
            onClick={() => onRemove(recipient)}
            className="rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900
                     shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Remove
          </button>
        )}
      </li>
    ))}
  </ul>
);

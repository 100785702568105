export interface UserGroupingRule {
  user_properties: {[key: string]: string};
}

export type UserGroup = [string, string, string];

export type GroupType = string;

export enum OptionType {
  DATE = 'date',
  STRING = 'str',
  COUNTRY = 'country',
  EVERYONE = 'everyone',
}

export interface GroupTypeOption {
  key: string;
  header: string;
  type: OptionType;
}

export interface GroupOption {
  key: string;
  name: string;
  groupType: GroupType;
}

import React, {CSSProperties, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

interface ProgramCardProps {
  children: ReactNode;
  style?: CSSProperties;
}

export const ProgramCard = (props: ProgramCardProps) => (
  <div
    className={`flex flex-col flex-grow border 
      border-stroke-divider1 rounded-xl overflow-hidden
       min-w-[280px]`}
    style={props.style}
  >
    {props.children}
  </div>
);

interface ProgramCardHeaderProps {
  children: ReactNode;
  className?: string;
}
ProgramCard.Header = function ProgramCardHeader(props: ProgramCardHeaderProps) {
  return (
    <div
      className={twMerge(
        `flex-shrink-0 border-b border-stroke-divider1 px-6 py-4 ${props.className}`,
      )}
    >
      {props.children}
    </div>
  );
};

interface ProgramCardContentProps {
  children: ReactNode;
  className?: string;
}
ProgramCard.Content = function ProgramCardContent(props: ProgramCardContentProps) {
  return <div className={twMerge(`p-6 flex flex-grow ${props.className}`)}>{props.children}</div>;
};

import React, {HTMLAttributes} from 'react';

export type ComptRowElementType = JSX.Element;

export interface ComptRowProps extends HTMLAttributes<HTMLElement> {
  separator?: boolean;
}

export const ComptRow = ({className, children, id, separator = false}: ComptRowProps) => (
  <>
    <div className={`compt-row mb-500 ${className}`} id={id}>
      {children}
    </div>
    {separator && <hr />}
  </>
);

import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {
  BusinessExpense,
  BusinessExpenseType,
} from '@compt/types/business-expenses/business-expense';
import {CostCenter} from '@compt/types/cost-center';
import {BusinessExpenseCategory} from '@compt/types/business-expenses/business-expense-category';
import {Company} from '@compt/types/company';
import {BusinessExpenseConfiguration} from '@compt/types/business-expenses/business-expense-configuration';

export class EmployeeBusinessExpenseFormController {
  static resetFields(
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    defaultCostCenter?: CostCenter,
  ) {
    const assignedCostCenter = defaultCostCenter?.enabled ? defaultCostCenter : undefined;

    formMethods.resetField('cost_center', {defaultValue: assignedCostCenter});
    formMethods.resetField('amount');
    formMethods.resetField('vendor');
    formMethods.resetField('receipt_date');
    formMethods.resetField('start_date', {defaultValue: null});
    formMethods.resetField('end_date', {defaultValue: null});
    formMethods.resetField('origin');
    formMethods.resetField('destination');
    formMethods.resetField('distance');
    formMethods.resetField('receipt_key');
    formMethods.resetField('description');
    formMethods.resetField('per_diem_rate');
  }

  static getFilteredExpenseCodeOptions = (
    expenseCodeOptions: CostCenter[] | BusinessExpenseCategory[],
    isReview?: boolean,
  ) =>
    expenseCodeOptions.filter(
      (expenseCode) => expenseCode.enabled && (!expenseCode.only_selectable_by_finance || isReview),
    );

  static getExpenseReceiptRequired = (
    expenseType: BusinessExpenseType,
    companyConfig: BusinessExpenseConfiguration | undefined,
  ) => {
    if (!companyConfig) {
      return false;
    }

    const requiredString = 'Receipt is required';
    switch (expenseType) {
      case BusinessExpenseType.BUSINESS_EXPENSE:
        return companyConfig.business_expense_requires_receipt && requiredString;

      case BusinessExpenseType.MILEAGE:
        return companyConfig.mileage_requires_receipt && requiredString;

      case BusinessExpenseType.PER_DIEM:
        return companyConfig.per_diem_requires_receipt && requiredString;

      default:
        return false;
    }
  };

  static validateCostCenter = (costCenter: CostCenter) => {
    if (!costCenter) {
      return 'Cost center is required';
    }
    if (!costCenter.enabled) {
      // eslint-disable-next-line max-len
      return 'You do not have a cost center assigned. Please contact your program admin or support@compt.io.';
    }
  };

  static validateCategory = (category: BusinessExpenseCategory) => {
    if (!category) {
      return 'Category is required';
    }

    if (category?.only_selectable_by_finance || !category?.enabled) {
      // eslint-disable-next-line max-len
      return 'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.';
    }
  };

  static getCostCenterToAssign(
    expenseInfo: BusinessExpense | null,
    assignedCostCenter: CostCenter | undefined,
    company: Company | undefined,
  ) {
    const canSelectCostCenter =
      company?.business_expense_configuration?.users_may_select_cost_center;

    if (!canSelectCostCenter && !!assignedCostCenter?.enabled) {
      return assignedCostCenter;
    }

    if (canSelectCostCenter && expenseInfo?.cost_center.enabled) {
      return expenseInfo.cost_center;
    }

    return null;
  }

  static getExpenseCategoryToAssign(
    expenseInfo: BusinessExpense | null,
    company: Company | undefined,
  ) {
    const expenseType = expenseInfo?.expense_type;

    const defaultMileageCategory =
      company?.business_expense_configuration?.default_mileage_expense_category;
    const defaultPerDiemCategory =
      company?.business_expense_configuration?.default_per_diem_expense_category;

    if (expenseType === BusinessExpenseType.MILEAGE && !!defaultMileageCategory) {
      return defaultMileageCategory;
    }

    if (expenseType === BusinessExpenseType.PER_DIEM && !!defaultPerDiemCategory) {
      return defaultPerDiemCategory;
    }

    return expenseInfo?.category.enabled && !expenseInfo?.category.only_selectable_by_finance
      ? expenseInfo.category
      : null;
  }

  static validateCostCenterOnCreate(
    canSelectCostCenter: boolean,
    assignedCostCenter: CostCenter | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
  ) {
    if (!canSelectCostCenter && !assignedCostCenter?.enabled) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'You do not have a cost center assigned. Please contact your program admin or support@compt.io.',
      });
    }
  }

  static validateExpenseCategoryOnCreate(
    defaultCategory: BusinessExpenseCategory | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
  ) {
    if (
      defaultCategory &&
      (!defaultCategory.enabled || defaultCategory?.only_selectable_by_finance)
    ) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.',
      });
    }
  }

  static validateCostCenterOnEdit(
    assignedCostCenter: CostCenter | undefined,
    company: Company | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    expenseInfo?: BusinessExpense | null,
  ) {
    const canSelectCostCenter =
      company?.business_expense_configuration?.users_may_select_cost_center;
    const currentCostCenterValue = formMethods.getValues('cost_center');

    if (canSelectCostCenter && !!currentCostCenterValue) {
      formMethods.clearErrors('cost_center');
      return;
    }

    if (!canSelectCostCenter && !assignedCostCenter?.enabled) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'You do not have a cost center assigned. Please contact your program admin or support@compt.io.',
      });
      return;
    }

    if (canSelectCostCenter && !expenseInfo?.cost_center.enabled) {
      return formMethods.setError('cost_center', {
        type: 'custom',
        message: 'Please select a cost center.',
      });
    }

    if (!canSelectCostCenter && expenseInfo?.cost_center_id !== assignedCostCenter?.id) {
      formMethods.setError('cost_center', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Your cost center has changed since you drafted this expense. Please save the expense again to update the cost center.',
      });
      return;
    }
  }

  static validateExpenseCategoryOnEdit(
    company: Company | undefined,
    formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>,
    expenseInfo: BusinessExpense | null,
  ) {
    const expenseType = expenseInfo?.expense_type;
    const initialExpenseCategory = expenseInfo?.category;
    const currentExpenseCategoryValue = formMethods.getValues('expense_category');

    const defaultMileageCategory =
      company?.business_expense_configuration?.default_mileage_expense_category;
    const defaultPerDiemCategory =
      company?.business_expense_configuration?.default_per_diem_expense_category;

    const isMileageAndHasDefault =
      expenseType === BusinessExpenseType.MILEAGE && !!defaultMileageCategory;
    const isPerDiemAndHasDefault =
      expenseType === BusinessExpenseType.PER_DIEM && !!defaultPerDiemCategory;

    const unableToSelect =
      (isMileageAndHasDefault &&
        (!defaultMileageCategory?.enabled || defaultMileageCategory?.only_selectable_by_finance)) ||
      (isPerDiemAndHasDefault &&
        (!defaultPerDiemCategory?.enabled || defaultPerDiemCategory?.only_selectable_by_finance));

    const defaultCategoryHasChanged =
      (isMileageAndHasDefault &&
        defaultMileageCategory?.enabled &&
        !defaultMileageCategory?.only_selectable_by_finance &&
        defaultMileageCategory.id !== initialExpenseCategory?.id) ||
      (isPerDiemAndHasDefault &&
        defaultPerDiemCategory?.enabled &&
        !defaultPerDiemCategory?.only_selectable_by_finance &&
        defaultPerDiemCategory.id !== initialExpenseCategory?.id);

    if (
      !!currentExpenseCategoryValue &&
      currentExpenseCategoryValue.enabled &&
      !currentExpenseCategoryValue?.only_selectable_by_finance &&
      !defaultCategoryHasChanged
    ) {
      formMethods.clearErrors('expense_category');
      return;
    }

    if (unableToSelect) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Please select an expense category. If unable to select, please contact your program admin or support@compt.io.',
      });
    }

    if (defaultCategoryHasChanged) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message:
          // eslint-disable-next-line max-len
          'Your expense category has changed since you drafted this expense. Please save the expense again to update the category.',
      });
    }

    if (
      (!currentExpenseCategoryValue && !expenseInfo?.category.enabled) ||
      expenseInfo?.category.only_selectable_by_finance
    ) {
      return formMethods.setError('expense_category', {
        type: 'custom',
        message: 'Please select an expense category.',
      });
    }
  }
}

import React, {HTMLAttributes, JSX, ReactNode} from 'react';
import {ComptRowElementType} from '../compt-row/compt-row';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {twMerge} from 'tailwind-merge';

export interface ComptPageProps extends HTMLAttributes<HTMLElement> {
  title: string;
  subtitle?: string;
  children: ComptRowElementType | ComptRowElementType[];
  statusPill?: JSX.Element;
  description?: string;
  header?: ReactNode;
  action?: ReactNode;
  className?: string;
  useDefaultMargins?: boolean;
  includeBottomHR?: boolean;
  bottomHRClassName?: string;
  headerClassName?: string;
}

export const ComptPage = ({
  title,
  statusPill,
  description,
  subtitle,
  children,
  header,
  action,
  includeBottomHR = false,
  bottomHRClassName,
  headerClassName,
  className,
  useDefaultMargins = true,
}: ComptPageProps) => {
  const isMobileView = useIsMobileView();

  return (
    <div className={twMerge(`${useDefaultMargins && 'my-6 mx-4 sm:mx-10 mb-16'} ${className}`)}>
      {header}
      <div className={twMerge(`flex flex-col justify-between sm:flex-row ${headerClassName}`)}>
        <div className="flex flex-col">
          <div className="flex gap-2 items-center">
            <h1 className={`${isMobileView ? 'display2' : 'display1'}`}>{title}</h1>
            {statusPill}
          </div>
          {subtitle && <p className="body1 sm:mb-8">{subtitle}</p>}
          {description && <p className="heading3 sm:mb-8">{description}</p>}
        </div>
        <div className="sm:mb-8">{action}</div>
      </div>
      {includeBottomHR && <hr className={twMerge(`my-300 ${bottomHRClassName}`)} />}
      {children}
    </div>
  );
};

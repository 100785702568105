import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {TeamRosterSyncResultTable} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-results/team-roster-sync-results-table.container';

export const TeamRosterSyncResultPage = () => {
  const {syncAttemptId} = useParams();
  const hasRequiredRole = useHasRequiredRole([USER_ROLES.admin, USER_ROLES.stipendProgramManager]);

  if (!featureEnabled(FeatureFlags.TEAM_ROSTER_SYNC_LOGS) || !hasRequiredRole) {
    return <Error404Page />;
  }

  return (
    <ComptPage
      title="Employee Roster Sync Results"
      className="h-dvh"
      header={
        <div className="flex flex-row gap-3 mb-4 items-center">
          <Link to="/sync-attempts/">
            <p className="label4 text-color-link">Sync attempts</p>
          </Link>
          <ComptSvgIcon iconName="chevron-right-icon-gray" />
          <p className="label4 text-color-body1">{syncAttemptId}</p>
        </div>
      }
    >
      <TeamRosterSyncResultTable />
    </ComptPage>
  );
};

import React, {HTMLAttributes, Fragment, SetStateAction, Dispatch} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

export interface ComptModalShellProps extends HTMLAttributes<HTMLElement> {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  specifiedTopPadding?: string;
  specifiedMaxWidth?: string;
  className?: string;
  preventAccidentalDismiss?: boolean;
  'data-testid'?: string;
}

const defaults: ComptModalShellProps = {
  open: false,
  setOpen: () => {},
  specifiedTopPadding: '',
  specifiedMaxWidth: '',
};

export const ComptModalShell = (props: ComptModalShellProps = defaults) => {
  const isMobileView = useIsMobileView();

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40"
        onClose={() => !props.preventAccidentalDismiss && props.setOpen(false)}
        data-testid={props['data-testid']}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-75"
          leave="ease-in duration-200"
          leaveFrom="opacity-75"
          leaveTo="opacity-0"
        >
          <div
            className={`fixed inset-0 ${
              isMobileView ? 'bg-modal-black' : 'bg-gray-500'
            } bg-opacity-75 transition-opacity`}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`
              flex min-h-full items-end justify-center text-center sm:mt-6 sm:items-center sm:p-6
            `}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`
                relative transform overflow-y-auto rounded-xl bg-white
                text-left shadow-xl transition-all w-11/12 mb-4 sm:my-8 pt-0
                pb-4 lg:w-full sm:h-full max-w-screen-2xl ${
                  props.specifiedMaxWidth ? props.specifiedMaxWidth : 'sm:max-w-lg'
                }
                ${props.specifiedTopPadding ? props.specifiedTopPadding : 'sm:p-6 px-4 pt-5'}
                ${props.className}
              `}
              >
                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

import {
  AnalyticsTool,
  HelpScoutMessageClaimOptions,
  HelpScoutMessagePathOptions,
  ParsedSetting,
  SendEventProps,
} from '@compt/utils/analytics/types';
import {UserSession} from '@compt/types/account';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export class HelpScout implements AnalyticsTool {
  private isAnalyticsEnabled = window.identifyBeacon && window.Beacon;
  identify = (userSession?: UserSession) => {
    if (this.isAnalyticsEnabled) {
      window.identifyBeacon();
    }
  };

  sendEvent(_eventProps: SendEventProps): void {}

  sendPageView(_pathname: string, _search: string): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: document.title,
    });
  }

  handleClaimMessageId = (options: HelpScoutMessageClaimOptions, parsedSetting: ParsedSetting) => {
    if (
      options.type === 'claim' &&
      options.companyId &&
      parsedSetting.companyId === options.companyId &&
      options.allotmentCycleId &&
      parsedSetting.allotmentCycleId === options.allotmentCycleId &&
      options.categoryId &&
      parsedSetting.categoryId === options.categoryId
    ) {
      return parsedSetting.messageId;
    }

    return null;
  };

  handlePathMessageId = (options: HelpScoutMessagePathOptions, parsedSetting: ParsedSetting) => {
    if (
      options.type === 'path' &&
      parsedSetting &&
      parsedSetting.companyId === options.companyId &&
      options.path === parsedSetting.path
    ) {
      return parsedSetting.messageId;
    }

    return null;
  };

  handleParsedSetting = (
    options: HelpScoutMessageClaimOptions | HelpScoutMessagePathOptions,
    parsedSetting: ParsedSetting,
  ) => {
    if (!parsedSetting) {
      return null;
    }

    switch (parsedSetting.type) {
      case 'path':
        return this.handlePathMessageId(options as HelpScoutMessagePathOptions, parsedSetting);
      case 'claim':
        return this.handleClaimMessageId(options as HelpScoutMessageClaimOptions, parsedSetting);
      default:
        return null;
    }
  };

  getHelpScoutMessageSettings = () => {
    if (window.companyConfigHelpScoutMessageSettings) {
      return window.companyConfigHelpScoutMessageSettings;
    }
    if (
      !window.COMPANY_CONFIG_HELP_SCOUT_MESSAGE_SETTING ||
      window.COMPANY_CONFIG_HELP_SCOUT_MESSAGE_SETTING === 'None'
    ) {
      return null;
    }

    try {
      window.companyConfigHelpScoutMessageSettings = JSON.parse(
        window.COMPANY_CONFIG_HELP_SCOUT_MESSAGE_SETTING,
      );
      return window.companyConfigHelpScoutMessageSettings;
    } catch (e) {
      console.error(
        'Unable to get company config settings',
        e,
        window.COMPANY_CONFIG_HELP_SCOUT_MESSAGE_SETTING,
      );
      return null;
    }
  };

  getMessageIdToForceShow = (
    options: HelpScoutMessageClaimOptions | HelpScoutMessagePathOptions,
  ) => {
    const parsedSettings = this.getHelpScoutMessageSettings();
    if (parsedSettings) {
      for (const parsedSetting of parsedSettings) {
        if (parsedSetting) {
          const messageCode = this.handleParsedSetting(options, parsedSetting);
          if (messageCode) {
            return messageCode;
          }
        }
      }
    }

    return null;
  };

  forceShowMessage = (messageId: string) => {
    if (window.Beacon) {
      window.Beacon('show-message', messageId, {force: true, delay: 1000});
    }
  };

  showForcedHelpScoutMessages = (
    options: HelpScoutMessagePathOptions | HelpScoutMessageClaimOptions,
  ) => {
    try {
      const messageId = this.getMessageIdToForceShow(options);

      if (messageId) {
        this.forceShowMessage(messageId);
      }
    } catch (e) {
      console.error('Error trying to show HS message', e);
    }
  };

  sendFeedback = () => {
    if (window.HELP_SCOUT_NDS_MESSAGE_ID) {
      this.identify();
      this.forceShowMessage(window.HELP_SCOUT_NDS_MESSAGE_ID);
    } else {
      triggerCustomToast(
        'error',
        'There was a problem loading the survey',
        'Please contact support@compt.io.',
      );
    }
  };
}

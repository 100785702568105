import React, {Dispatch, SetStateAction, useEffect, useMemo} from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useUpdateBusinessExpenseMutation} from '@compt/app/services/api/business-expenses-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useScrollOutsideContainer} from '@compt/utils/scroll-helper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {stripImageNameFromS3URL} from '@compt/utils/image-helpers';
import {
  expenseTypeOptions,
  formatExpenseForm,
  getExpenseTypeOption,
} from '@compt/utils/business-expense-helpers';

// Components
import {AdminMileageExpenseFields} from './admin-mileage-expense-fields';
import {AdminPerDiemExpenseFields} from './admin-per-diem-fields';
import {AdminStandardBusinessExpenseFields} from './admin-standard-business-expense-fields';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptReceiptDisplayCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-display-carousel';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComputedRateText} from '@compt/pages/business-expenses/business-expense-page/components/employee-business-expense-form/computed-rate-text';
import {ComptRejectionReason} from '@compt/common/compt-rejection-reason/compt-rejection-reason';

// Types
import {
  BusinessExpense,
  BusinessExpenseType,
} from '@compt/types/business-expenses/business-expense';
import {CostCenter} from '@compt/types/cost-center';
import {EmployeeBusinessExpenseFormFieldValues} from '@compt/pages/business-expenses/business-expense-page/components/employee-business-expense-form/employee-business-expense-form.types';
import {BusinessExpenseCategory} from '@compt/types/business-expenses/business-expense-category';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';

type AdminBusinessExpenseFormFieldValues = EmployeeBusinessExpenseFormFieldValues;

interface AdminBusinessExpenseFormProps {
  selectedExpense: BusinessExpense | null;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
}

export const AdminBusinessExpenseForm = (props: AdminBusinessExpenseFormProps) => {
  const {selectedExpense} = props;
  const {contentRef} = useScrollOutsideContainer();
  const isReadOnly = !props.editMode;
  const formMethods = useForm<AdminBusinessExpenseFormFieldValues>();

  const sessionQuery = useGetSessionQuery();
  const isFinancialReviewer = sessionQuery.data?.roles.includes(USER_ROLES.financeReviewer);

  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);
  const company = companyQuery.data;
  const mileageRate = company?.mileage_rate;

  const [updateBusinessExpense, {isLoading: isUpdating}] = useUpdateBusinessExpenseMutation();

  const validExpenseCategories = useMemo(() => {
    if (!company?.business_expense_categories) return [];
    if (isFinancialReviewer) return company?.business_expense_categories;

    return company?.business_expense_categories.filter(
      (category) => !category.only_selectable_by_finance,
    );
  }, [company, isFinancialReviewer]);

  const validCostCenters = useMemo(() => {
    if (!company?.cost_centers) return [];
    if (isFinancialReviewer) return company?.cost_centers;

    return company?.cost_centers.filter((category) => !category.only_selectable_by_finance);
  }, [company, isFinancialReviewer]);

  const watchedDescription = formMethods.watch('description');

  useEffect(() => {
    if (!selectedExpense) return;
    resetFormToInitialValues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedExpense]);

  function resetFormToInitialValues() {
    // Assign supporting documents to react-hook-form field names
    const defaultSupportingDocuments: {[key: string]: string} = {};

    selectedExpense?.supporting_documents.forEach((doc, index) => {
      const fieldName = `supporting_doc_${index}`;
      defaultSupportingDocuments[fieldName] = doc.document_image;
    });

    formMethods.reset({
      id: selectedExpense?.id,
      amount: selectedExpense?.amount_of_expense,
      description: selectedExpense?.description,
      vendor: selectedExpense?.vendor,
      origin: selectedExpense?.origin,
      destination: selectedExpense?.destination,
      distance: selectedExpense?.distance,
      expense_type: getExpenseTypeOption(selectedExpense?.expense_type),
      receipt_date: selectedExpense?.receipt_date,
      cost_center: selectedExpense?.cost_center,
      expense_category: selectedExpense?.category,
      receipt_key: selectedExpense?.receipt_key,
      start_date: selectedExpense?.start_date,
      end_date: selectedExpense?.end_date,
      per_diem_rate: selectedExpense?.per_diem_rate,
      mileage_rate: company?.mileage_rate,
      ...defaultSupportingDocuments,
    });
  }

  function onBusinessExpenseSubmit(form: AdminBusinessExpenseFormFieldValues) {
    if (!mileageRate) {
      triggerCustomToast(
        'error',
        'There was a problem saving the business expense.',
        'You do not have your mileage rate set. Please contact your program admin or support@compt.io.',
      );
      return;
    }

    if (!selectedExpense) return;

    const adjustedForm = formatExpenseForm(form, mileageRate);
    const supportingDocList = [];

    for (const field in form) {
      if (field.startsWith('supporting_doc_') && !!form[field]) {
        supportingDocList.push(stripImageNameFromS3URL(form[field]));
      }
    }

    const payload = {
      ...adjustedForm,
      id: selectedExpense.id,
      supporting_document_keys: supportingDocList,
    } as BusinessExpense;

    updateBusinessExpense(payload).then((data) => {
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem saving the business expense');
        console.error(data.error);
        return;
      }

      formMethods.reset();
      triggerCustomToast('success', 'Successfully updated business expense');
      props.setEditMode(false);
    });
  }

  if (!selectedExpense || !company) {
    return null;
  }

  return (
    <>
      <ComptSidePanel.Content className="sm:overflow-y-hidden">
        <div className="h-full flex flex-col">
          <form
            className="flex h-full"
            onSubmit={formMethods.handleSubmit(onBusinessExpenseSubmit)}
          >
            <div className="flex flex-col w-full md:flex-row">
              <div className="flex justify-center bg-surface-tint sm:overflow-y-clip">
                <div className="flex flex-col w-full sm:w-[480px] items-end p-6">
                  {selectedExpense && selectedExpense.receipt_image && !props.editMode && (
                    <ComptReceiptDisplayCarousel
                      key={`receipt-display-for-${selectedExpense.id}`}
                      data-testid="display-only-receipt-carousel"
                      documents={[
                        selectedExpense.receipt_image,
                        ...selectedExpense.supporting_documents,
                      ]}
                    />
                  )}
                  {props.editMode && (
                    <ComptReceiptFormCarousel
                      formMethods={formMethods}
                      receiptLabel="Uploaded attachments"
                      userId={sessionQuery.data?.user_id}
                      initialSupportingDocs={selectedExpense?.supporting_documents}
                      initialReceiptValue={selectedExpense?.receipt_image}
                      receiptDomain="business_expenses"
                      supportingDocDomain="be_supporting_document"
                      receiptTestDataId="receipt-key-field"
                      data-testid="edit-only-receipt-carousel"
                      required={false}
                    />
                  )}
                </div>
              </div>
              <div ref={contentRef} className="w-full p-6 sm:overflow-y-auto">
                {selectedExpense?.rejection_reason && (
                  <ComptRejectionReason rejectionReason={selectedExpense.rejection_reason} />
                )}
                <ComptTextField
                  name="full_name"
                  label="Full name"
                  data-testid="full-name-field"
                  initialValue={selectedExpense?.full_name}
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly
                  disabled
                />
                <ComptTextField
                  name="report_number"
                  label="Report #"
                  data-testid="report-number-field"
                  initialValue={selectedExpense?.report_id}
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly
                  disabled
                />
                <ComptTextField
                  name="report_title"
                  label="Report title"
                  data-testid="report-title-field"
                  initialValue={selectedExpense?.report.title}
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly
                  disabled
                />
                <ComptTextField
                  name="report_description"
                  label="Report description"
                  data-testid="report-description-field"
                  initialValue={selectedExpense?.report.description}
                  control={formMethods.control}
                  register={formMethods.register}
                  readOnly
                  disabled
                />
                <ComptDropDownField
                  name="expense_type"
                  data-testid="expense-type-field"
                  label="Expense type"
                  options={expenseTypeOptions}
                  getKey={(option) => option.id}
                  getDisplayText={(option) => option.label}
                  validation={{required: 'Expense type is required'}}
                  register={formMethods.register}
                  control={formMethods.control}
                  errors={formMethods.formState.errors.expense_type}
                  readOnly
                  disabled
                />
                {selectedExpense?.expense_type === BusinessExpenseType.BUSINESS_EXPENSE && (
                  <AdminStandardBusinessExpenseFields
                    formMethods={formMethods}
                    isReadOnly={isReadOnly}
                  />
                )}
                {selectedExpense?.expense_type === BusinessExpenseType.MILEAGE && (
                  <AdminMileageExpenseFields formMethods={formMethods} isReadOnly={isReadOnly} />
                )}
                {selectedExpense?.expense_type === BusinessExpenseType.PER_DIEM && (
                  <AdminPerDiemExpenseFields formMethods={formMethods} isReadOnly={isReadOnly} />
                )}
                {/* TODO: address valid cost center options in COMPT-5485 */}
                <ComptDropDownField
                  name="cost_center"
                  data-testid="cost-center-field"
                  label="Cost center"
                  options={validCostCenters}
                  getKey={(option: CostCenter) => option.id}
                  getDisplayText={(option: CostCenter) => option.name}
                  validation={{required: 'Cost center is required'}}
                  register={formMethods.register}
                  control={formMethods.control}
                  errors={formMethods.formState.errors.cost_center}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                />
                {/* TODO: address valid expense category options in COMPT-5485 */}
                <ComptDropDownField
                  name="expense_category"
                  data-testid="expense-category-field"
                  label="Expense category"
                  options={validExpenseCategories}
                  getKey={(option: BusinessExpenseCategory) => option.id}
                  getDisplayText={(option: BusinessExpenseCategory) => option.name}
                  validation={{required: 'Expense category is required'}}
                  register={formMethods.register}
                  control={formMethods.control}
                  errors={formMethods.formState.errors.expense_category}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                />
                <ComptTextAreaField
                  name="description"
                  label="Description"
                  data-testid="description-field"
                  placeholder="Describe the expense"
                  validation={{
                    required: 'Description is required',
                    maxLength: {
                      value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
                      message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
                    },
                  }}
                  register={formMethods.register}
                  errors={formMethods.formState.errors.description}
                  disabled={isReadOnly}
                  readOnly={isReadOnly}
                  maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
                  watchedValue={watchedDescription}
                />
                {selectedExpense.expense_type !== BusinessExpenseType.BUSINESS_EXPENSE && (
                  <ComputedRateText
                    expenseType={selectedExpense.expense_type}
                    form={formMethods.watch()}
                  />
                )}
              </div>
            </div>
          </form>
        </div>
      </ComptSidePanel.Content>
      {props.editMode && (
        <ComptSidePanel.Footer>
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            size={ComptButtonSize.SMALL}
            disabled={isUpdating || !formMethods.formState.isValid}
            onClick={formMethods.handleSubmit(onBusinessExpenseSubmit)}
          >
            Save
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.BORDERLESS}
            size={ComptButtonSize.SMALL}
            disabled={isUpdating}
            className="pl-2"
            onClick={() => {
              props.setEditMode(() => false);
              resetFormToInitialValues();
            }}
          >
            Cancel
          </ComptButton>
        </ComptSidePanel.Footer>
      )}
    </>
  );
};

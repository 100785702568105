import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import React, {useEffect} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {EmployeeBusinessExpenseFormFieldValues} from './employee-business-expense-form.types';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';

type RouteAndDateRangeFieldProps = {
  formMethods: UseFormReturn<EmployeeBusinessExpenseFormFieldValues>;
  disabled: boolean;
  readOnly?: boolean;
};

export const RouteAndDateRangeFields = (props: RouteAndDateRangeFieldProps) => {
  const {formMethods, disabled} = props;
  const watchedStartDate = formMethods.watch('start_date');
  const watchedEndDate = formMethods.watch('end_date');

  useEffect(() => {
    function validateDateRange() {
      const startDate = formMethods.getValues('start_date');
      const endDate = formMethods.getValues('end_date');

      if (!startDate || !endDate) {
        return;
      }

      if (startDate < endDate || startDate === endDate) {
        formMethods.clearErrors('start_date');
        formMethods.clearErrors('end_date');
        return;
      }

      if (endDate < startDate) {
        formMethods.setError('start_date', {
          type: 'custom',
          message: 'Invalid date range',
        });

        formMethods.setError('end_date', {
          type: 'custom',
          message: 'Invalid date range',
        });
        return;
      }
    }

    validateDateRange();
  }, [watchedEndDate, watchedStartDate, formMethods]);

  return (
    <div className="space-y-2">
      <div className="grid gap-2">
        <ComptDatePickerField
          name="start_date"
          label="Start date"
          data-testid="start-date-field"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'Start date is required'}}
          errors={formMethods.formState.errors.start_date}
          disabled={disabled}
          readOnly={props.readOnly}
        />
        <ComptDatePickerField
          name="end_date"
          label="End date"
          data-testid="end-date-field"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'End date is required'}}
          errors={formMethods.formState.errors.end_date}
          disabled={disabled}
          readOnly={props.readOnly}
        />
      </div>
      <div className="grid gap-2">
        <ComptTextField
          name="origin"
          label="Origin"
          data-testid="origin-field"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'Origin is required'}}
          errors={formMethods.formState.errors.origin}
          disabled={disabled}
          readOnly={props.readOnly}
        />
        <ComptTextField
          name="destination"
          label="Destination"
          data-testid="destination-field"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'Destination is required'}}
          errors={formMethods.formState.errors.destination}
          disabled={disabled}
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
};

import React, {useCallback, useEffect, useRef, useState} from 'react';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllLearningDevelopmentProgramsQuery} from '@compt/app/services/api/learning-development-slice';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {LearningDevelopmentCard} from './components/learning-development-card';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {MAX_15_PAGE_LIMIT} from '@compt/constants';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

export const AdminManageProgramsPage = () => {
  const isMobileView = useIsMobileView();
  const [programs, setPrograms] = useState<LearningDevelopmentProgram[]>([]);
  const [offset, setOffset] = useState(0);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const prevProgramCount = useRef<number>(0);

  const navigate = useNavigate();

  const session = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(session.data?.user_id ?? skipToken);
  const {data, error, isLoading, isFetching} = useGetAllLearningDevelopmentProgramsQuery(
    {companyId: companyQuery.data?.id, filter: {limit: MAX_15_PAGE_LIMIT, offset}},
    {skip: !companyQuery.data?.id},
  );

  useEffect(() => {
    if (data && data?.results) {
      if (offset === 0) {
        setPrograms(data?.results);
        prevProgramCount.current = data?.count;
      } else if (data.count !== prevProgramCount.current) {
        setOffset(0);
      } else {
        setPrograms((prevPrograms) => {
          const newPrograms = data.results.filter(
            (program) => !prevPrograms.some((prev) => prev.id === program.id),
          );
          return [...prevPrograms, ...newPrograms];
        });
      }
    }
  }, [data]);

  const loadMorePrograms = useCallback(() => {
    if (data?.next) {
      const url = new URL(data.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0');
      setOffset(newOffset);
    }
  }, [data]);

  useEffect(() => {
    if (!loadMoreRef.current || programs.length === 0) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isLoading) {
        loadMorePrograms();
      }
    });
    observer.observe(loadMoreRef.current);
    return () => {
      if (loadMoreRef.current) observer.unobserve(loadMoreRef.current);
    };
  }, [loadMorePrograms, isLoading, programs]);

  const onUpdateProgram = useCallback(
    (updatedProgram: LearningDevelopmentProgram) => {
      const newProgramList = programs.map((program) => {
        if (program.id === updatedProgram.id) {
          return updatedProgram;
        }

        return program;
      });

      setPrograms(() => newProgramList);
    },
    [programs],
  );

  if (!data || companyQuery.isLoading) return <ComptLoadingIndicator isLoading={true} />;

  if (error) return <Error404Page />;

  return (
    <ComptPage
      title="Manage programs"
      subtitle="Manage your employee benefit programs"
      includeBottomHR={isMobileView}
      action={
        !isMobileView && (
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.PLUS}
            onClick={() => navigate('/learning-development-form?form_type=create')}
          >
            Create program
          </ComptButton>
        )
      }
    >
      <>
        {programs.length === 0 && (
          <div className="flex flex-col items-center mt-60">
            <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
            <p className="heading4 inner-html text-center">
              No professional development programs exist yet
            </p>
            <p>Click on &quot;Create program&quot; to get started!</p>
          </div>
        )}
      </>
      <>
        {programs.length > 0 && (
          <div
            className={`grid sm:grid-cols-1 min-[900px]:grid-cols-2
         min-[1200px]:grid-cols-3 lg:grid-cols-4 w-full gap-y-6 gap-x-6`}
          >
            {programs.map((program) => (
              <LearningDevelopmentCard
                key={`lnd-card-${program.id}`}
                program={program}
                onTerminateProgram={onUpdateProgram}
              />
            ))}
          </div>
        )}
        <div ref={loadMoreRef}>
          {(isLoading || isFetching) && (
            <div className="flex flex-col w-full mt-6">
              <div className="mb-[9px]">
                <ComptLoadingAnimation />
              </div>
              <p className="label3 text-color-body1 -mt-7 mx-auto">Loading programs...</p>
            </div>
          )}
        </div>
      </>
    </ComptPage>
  );
};

import React from 'react';
import {
  AnchoredComboBoxOption,
  ComptSearch,
  ComptSearchProps,
} from '@compt/common/forms/compt-search-field/compt-search';
import {
  Controller,
  ControllerRenderProps,
  FieldPathByValue,
  FieldPathValue,
  FieldValues,
  RegisterOptions,
  ValidateResult,
} from 'react-hook-form';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {ComptFormControlFieldMultiSelectBaseProps} from '@compt/types/form/compt-forms';

export interface ComptSearchFieldProps<
  TDataType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TDataType[]>,
> extends Omit<ComptSearchProps<TDataType>, 'value' | 'onChange' | 'name' | 'initialValue'>,
    ComptFormControlFieldMultiSelectBaseProps<TDataType, TFieldValues, TName> {
  validation?: RegisterOptions;
  value: FieldPathValue<TFieldValues, TName>;
  readOnly?: boolean;
  anchoredOption?: AnchoredComboBoxOption;
}

export const ComptSearchField = <
  TDataType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TDataType[]>,
>(
  props: ComptSearchFieldProps<TDataType, TFieldValues, TName>,
) => {
  const onChange = (selection: TDataType[], field: ControllerRenderProps<TFieldValues, TName>) => {
    if (props.onChange) {
      props.onChange(selection);
    }

    if (field) {
      field.onChange(selection);
    }
  };

  return (
    <>
      <Controller
        name={props.name}
        control={props.control}
        rules={{
          validate: {
            required: (value) => {
              if (props.validation?.required && (!value || value.length < 1)) {
                return props.validation.required as ValidateResult;
              }
              return true;
            },
          },
        }}
        defaultValue={props.value}
        render={({field}) => (
          <ComptFormField
            id={props.id || ''}
            errors={props.errors}
            label={props.label}
            subLabel={props.subLabel}
            validation={props.validation}
            readOnly={props.readOnly}
            field={
              <ComptSearch
                id={props.id}
                name={props.name}
                value={field.value}
                invalid={!!props.errors}
                by={props.by}
                loading={props.loading}
                options={props.options}
                inputRef={
                  (props.register && props.name ? props.register(props.name)?.ref : field.ref) ||
                  field.ref
                }
                onBlur={field.onBlur}
                getSecondaryText={props.getSecondaryText}
                onChange={(selection: TDataType[]) => onChange(selection, field)}
                onQueryChanged={props.onQueryChanged}
                getKey={props.getKey}
                getDisplayText={props.getDisplayText}
                renderSelectionDisplay={props.renderSelectionDisplay}
                disabled={props.disabled}
                required={!!props.validation?.required}
                placeholder={props.placeholder}
                anchoredOption={props.anchoredOption}
              />
            }
          />
        )}
      />
    </>
  );
};

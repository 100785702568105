import {STATUS_KEYS} from '@compt/types/stipend-expenses/stipend-expenses';
import {ExpenseStatus} from '@compt/types/stipend-expense';

export const getStipendStatusCode = (statusKey: STATUS_KEYS): ExpenseStatus => {
  switch (statusKey) {
    case STATUS_KEYS.Open:
      return ExpenseStatus.Open;
    case STATUS_KEYS.Approved:
      return ExpenseStatus.Approved;
    case STATUS_KEYS.Rejected:
      return ExpenseStatus.Rejected;
    case STATUS_KEYS.In_Review:
      return ExpenseStatus.InReview;
    case STATUS_KEYS.Processed:
      return ExpenseStatus.Processed;
  }
};

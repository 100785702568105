import React from 'react';
import {useNavigate} from 'react-router-dom';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

interface Error404Props {
  homePath?: string;
}

export const Error404Page = (props: Error404Props) => {
  const navigate = useNavigate();
  const isMobile = useIsMobileView();

  return (
    <div className="flex justify-center mt-32">
      <ComptSvgIcon iconName="error-404" className={isMobile ? 'hidden' : 'block'} />
      <div className="absolute mt-20">
        <h1
          className={`${
            isMobile ? 'heading2 mb-4' : 'h-24'
          } display1 compt-text--semi-bold text-center`}
        >
          We can&apos;t find this page!
        </h1>
        <p className="heading2 compt-text--regular text-color-body2 h-24 text-center">
          The page you are looking for doesn&apos;t exist or has been moved.
        </p>
        <div className={`flex justify-center ${isMobile ? 'flex-col-reverse' : ''}`}>
          <ComptButton
            className={`${isMobile ? 'mt-3' : 'mr-3'}`}
            buttonType={ComptButtonType.OUTLINED}
            iconId={ComptButtonIcon.ARROW_LEFT}
            onClick={() => navigate(-1)}
          >
            Go back
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            onClick={() => navigate(props.homePath || '/')}
            data-testid="error-404-home-button"
          >
            Go home
          </ComptButton>
        </div>
      </div>
    </div>
  );
};

import React from 'react';

// RTK Queries
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';

// Hooks and methods
import {
  calculateTotalDays,
  calculateTotalFromRateMileage,
  calculateTotalFromRatePerDiem,
} from '@compt/utils/business-expense-helpers';

// Types
import {BusinessExpenseType} from '@compt/types/business-expenses/business-expense';
import {FieldValues} from 'react-hook-form';

type ComputedRateTextProps = {
  expenseType: BusinessExpenseType.MILEAGE | BusinessExpenseType.PER_DIEM;
  form: FieldValues;
};

export const ComputedRateText = (props: ComputedRateTextProps) => {
  const {expenseType, form} = props;

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);
  const mileageRate = companyQuery.data?.mileage_rate;

  if (!mileageRate || typeof mileageRate !== 'string') {
    console.error('Expense rate is invalid');
    return null;
  }

  const mileageRateAsNum = parseFloat(mileageRate);
  let totalRate = '';
  let rateText = '';
  let rateSubtext = '';

  if (expenseType === BusinessExpenseType.MILEAGE) {
    const formattedDistance = form.distance ? parseFloat(form.distance).toFixed(1) : 0;
    totalRate = calculateTotalFromRateMileage(formattedDistance, mileageRateAsNum)
      .toFixed(2)
      .toString();

    rateText = 'Calculation by distance';
    rateSubtext = `${mileageRate} per mile`;
  }

  if (expenseType === BusinessExpenseType.PER_DIEM) {
    const totalDays = calculateTotalDays(form.start_date, form.end_date);
    const isRateNaN = form.per_diem_rate === 'NaN';

    totalRate = !isRateNaN
      ? calculateTotalFromRatePerDiem(totalDays, form.per_diem_rate ?? 0)
          .toFixed(2)
          .toString()
      : '0.00';

    rateText = 'Calculation by dates';
    rateSubtext = `${isRateNaN ? '0.00' : form.per_diem_rate} per night`;
  }

  return (
    <div className="grid gap-y-2 py-6">
      <div className="flex justify-between">
        <p className="body1">Expense amount</p>
        <p className="label1 text-color-heading">${totalRate ?? '0.00'}</p>
      </div>
      <div className="flex justify-between">
        <p className="body1">{rateText}</p>
        <p className="label1 text-color-heading">{rateSubtext}</p>
      </div>
    </div>
  );
};

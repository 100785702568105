import React, {useMemo, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/query';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetTeamRosterSyncResultListQuery} from '@compt/app/services/api/team-roster-sync-results-slice';

// Hooks and methods
import {useParams} from 'react-router-dom';
import {useDebounce} from '@uidotdev/usehooks';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {TeamRosterSyncResultTableController} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-results/team-roster-sync-results-table.controller';

// Components
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {SyncResultSidePanel} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/components/sync-result-side-panel';

// Types
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';
import {TeamRosterSyncResult} from '@compt/types/team-roster-syncs/team-roster-syncs';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';

export const TeamRosterSyncResultTable = () => {
  const {syncAttemptId} = useParams();
  const controller = new TeamRosterSyncResultTableController();
  const {updateQueryParamsOnValuesChanged} = controller;

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const [syncResultsQueryValues, setSyncResultsQueryValues] = useState<
    FormattedFilterObject | undefined
  >();
  const debouncedQueryValues = useDebounce(syncResultsQueryValues, 300);
  const teamRosterSyncResultListQuery = useGetTeamRosterSyncResultListQuery(
    {syncAttemptId: syncAttemptId, params: debouncedQueryValues},
    {skip: !userId},
  );

  const [selectedSyncResult, setSelectedSyncResult] = useState<TeamRosterSyncResult | null>(null);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [openSyncResultSidePanel, setOpenSyncResultSidePanel] = useState(false);

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No team roster sync results were found with the current filters.'
        : 'No team roster sync results exist for this sync attempt.',
    [filtersApplied],
  );

  if (teamRosterSyncResultListQuery.isError) {
    return <Error404Page />;
  }
  const onActionClicked = (result: TeamRosterSyncResult) => {
    setSelectedSyncResult(result);
    setOpenSyncResultSidePanel(true);
  };

  const columnDefinition = controller.getColumnDefinition(onActionClicked);
  const filterConfiguration = controller.getFilterConfiguration();
  const initialFilterValues = TeamRosterSyncResultTableController.getInitialFilterValues();

  return (
    <ComptLoadingIndicator
      isLoading={companyQuery.isLoading || teamRosterSyncResultListQuery.isLoading}
      className="pt-72"
    >
      {companyQuery.data && teamRosterSyncResultListQuery.data && (
        <ComptTableContextProvider>
          <SyncResultSidePanel
            open={openSyncResultSidePanel}
            selectedSyncResult={selectedSyncResult}
            setSelectedSyncResult={setSelectedSyncResult}
            syncResultListResults={teamRosterSyncResultListQuery.data?.results ?? []}
            setOpen={setOpenSyncResultSidePanel}
          />
          <ComptTable
            company={companyQuery.data}
            tableId="team-roster-sync-results-table"
            className="w-full"
            data={teamRosterSyncResultListQuery.data?.results ?? []}
            dataLoading={companyQuery.isFetching || teamRosterSyncResultListQuery.isFetching}
            noDataTitleText={noDataTitleText}
            allowShowHide={false}
            columnDefinition={columnDefinition}
            filterConfiguration={filterConfiguration}
            initialFilterValues={initialFilterValues}
            allowPagination={true}
            totalCount={teamRosterSyncResultListQuery.data?.count}
            itemsPerPage={MAX_ITEMS_PER_PAGE}
            onChangeQueryValues={(filterValues, pagination, ordering) =>
              updateQueryParamsOnValuesChanged(
                companyId,
                setSyncResultsQueryValues,
                setFiltersApplied,
                pagination,
                filterValues,
                ordering,
              )
            }
            onRowClicked={(row) => onActionClicked(row)}
            stickyLastColumn
          />
        </ComptTableContextProvider>
      )}
    </ComptLoadingIndicator>
  );
};

import React from 'react';

import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

/**
 * Component that ensures children are only displayed if we have a valid session.
 *
 * @param children
 * @constructor
 */
export const Protected = ({children}: React.PropsWithChildren) => {
  const sessionQuery = useGetSessionQuery();

  return (
    <ComptLoadingIndicator
      isLoading={sessionQuery.isLoading}
      dependentData={!!sessionQuery.data}
      noDataText={<h1 className="display1">Unauthorized</h1>}
    >
      {children}
    </ComptLoadingIndicator>
  );
};

import React, {Dispatch, SetStateAction, useEffect, useState, useRef, useCallback} from 'react';
import {TeamRecognition} from '@compt/types/team-recognition/team-recognition';
import {DateTime} from 'luxon';
import {ComptDropDown} from '@compt/common/forms/compt-dropdown/compt-dropdown';
import {useGetTeamRecognitionQuery} from '@compt/app/services/api/team-recognition-slice';
import {UserSession} from '@compt/types/account';
import {Company} from '@compt/types/company';
import {
  DEFAULT_FILTER_STATE,
  FilterValuesType,
  TeamRecognitionActivityFeedController,
  SelectOption,
} from '@compt/pages/team-recognition-page/components/team-recognition-activity-feed.controller';
import {produce} from 'immer';
import {useCacheableObjectState} from '@compt/utils/local-storage-helpers';
import DOMPurify from 'dompurify';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';

export interface TeamRecognitionActivityFeedProps {
  userSession: UserSession;
  company: Company;
}

type FilterProps = {
  controller: TeamRecognitionActivityFeedController;
  filterValues: FilterValuesType;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  onFilterChange: (filterKey: keyof FilterValuesType, filterValue: string | {page: number}) => void;
};

const RecognitionFilters = (props: FilterProps) => {
  const recipientDemographicFilterOptions = props.controller.getRecipientDemographicFilterOptions();
  const giverOrRecipientFilterOptions = props.controller.getGiverOrRecipientFilterOptions();
  const selectedValues = props.controller.getSelectedValues(props.filterValues);
  const hasFeedOptions =
    recipientDemographicFilterOptions && recipientDemographicFilterOptions.length > 1;

  const isMobileView = useIsMobileView();

  const handleSelect = (filterKey: keyof FilterValuesType, selected: SelectOption) => {
    props.onFilterChange(filterKey, selected.id);
    props.onFilterChange('pagination', {page: 1});
    props.setCurrentPage(1);
  };

  return (
    <div className="flex justify-between space-x-2 sm:space-x-0">
      <div className="flex flex-col md:flex-col sm:flex-row md:items-start sm:items-center sm:mr-400">
        <p className={`${isMobileView ? 'body3 mb-1' : 'body2'} text-color-body1 mr-2`}>Showing:</p>
        <ComptDropDown
          id="giver-or-recipient-filter"
          data-testid="giver-or-recipient-filter"
          getDisplayText={(option) => option.label}
          getKey={(option) => option.id}
          options={giverOrRecipientFilterOptions}
          onChange={(selected) =>
            selected && handleSelect('giverOrRecipientFilterSelection', selected)
          }
          value={selectedValues[0]}
          heightClass="h-10"
          textClass="text-color-body1"
          additionalClasses="min-w-full w-48 sm:w-56"
          by="id"
        />
      </div>

      {hasFeedOptions && (
        <div className="flex flex-col md:flex-col md:items-start sm:flex-row sm:items-center">
          <p className={`${isMobileView ? 'body3 mb-1' : 'body2'} text-color-body1 mr-2`}>
            Within:
          </p>
          <ComptDropDown
            id="recipient-demographic-filter"
            data-testid="recipient-demographic-filter"
            getDisplayText={(option) => option.label}
            getKey={(option) => option.id}
            options={recipientDemographicFilterOptions}
            onChange={(selected) =>
              selected && handleSelect('recipientDemographicFilterSelection', selected)
            }
            value={selectedValues[1]}
            heightClass="h-10"
            textClass="text-color-body1"
            additionalClasses="min-w-full w-40 sm:w-56"
            by="id"
          />
        </div>
      )}
    </div>
  );
};

const renderRelativeDate = (date: Date) => {
  const luxonGivenDate = DateTime.fromJSDate(date);

  return luxonGivenDate.toRelativeCalendar();
};

const RecognitionTitleText = ({teamRecognition}: {teamRecognition: TeamRecognition}) => (
  <p className="flex body3 sm:mr-100">
    <span className="label4 mr-2">{teamRecognition.giving_user.full_name}&nbsp;</span>
    <span className="body4 text-color-body2">
      {renderRelativeDate(new Date(teamRecognition.created_on))}
    </span>
  </p>
);

const RecognitionSubtitleText = ({teamRecognition}: {teamRecognition: TeamRecognition}) => (
  <p className="flex sm:mr-100">
    <span className="body3 text-color-body1">gave recognition to&nbsp;</span>
    <span className="label3 text-color-body1">{teamRecognition.receiving_user.full_name}</span>
  </p>
);

/** Class names with "sm" affixed define desktop styles */
const TeamRecognitionRow = (teamRecognition: TeamRecognition) => (
  <div className="flex">
    <div
      className="flex h-12 w-12 border bg-brand-50 rounded-full aspect-square
                   border-brand-500 place-content-center items-center mr-3"
    >
      <h2 className="m-[18px] text-brand-700">
        {teamRecognition.giving_user?.first_name[0]}
        {teamRecognition.giving_user?.last_name[0]}
      </h2>
    </div>
    <div
      className="team-recognition-row flex flex-col grow"
      key={`team-recognition-row-${teamRecognition.id}`}
    >
      <div className="flex flex-col items-start">
        <RecognitionTitleText teamRecognition={teamRecognition} />
        <RecognitionSubtitleText teamRecognition={teamRecognition} />
      </div>
      <div className="border rounded-r-lg rounded-b-lg p-3 mt-3 shadow-sm">
        <p
          className="body3 text-color-body1 inner-html"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(teamRecognition.message),
          }}
        ></p>
      </div>
    </div>
  </div>
);

const ActivityList = ({
  teamRecognitionData,
}: {
  teamRecognitionData: TeamRecognition[] | undefined;
}) => (
  <div className="p-300 sm:p-400 border rounded-xl border-stroke-divider1">
    {teamRecognitionData && teamRecognitionData.length > 0 ? (
      teamRecognitionData.map((recognitionRow, i) => (
        <>
          <TeamRecognitionRow key={`recognition-row-${recognitionRow.id}`} {...recognitionRow} />
          {i < teamRecognitionData.length - 1 && <hr className="my-4" />}
        </>
      ))
    ) : (
      <p className="text-center">No team recognition found with the selected filter.</p>
    )}
  </div>
);

export const TeamRecognitionActivityFeed = ({
  company,
  userSession,
}: TeamRecognitionActivityFeedProps) => {
  const controller = new TeamRecognitionActivityFeedController(userSession, company);
  const [filterValues, setFilterValues] = useCacheableObjectState<FilterValuesType>(
    controller.getFilterKey(),
    DEFAULT_FILTER_STATE,
    false,
    ['pagination'],
  ) as [FilterValuesType, React.Dispatch<React.SetStateAction<FilterValuesType>>];
  const [, setCurrentPage] = useState(1);
  const [teamRecognitionData, setTeamRecognitionData] = useState<TeamRecognition[]>([]);
  const [nextQuery, setNextQuery] = useState<boolean | null>(true);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const teamRecognitionQuery = useGetTeamRecognitionQuery(controller.getFilterParams(filterValues));

  useEffect(() => {
    if (teamRecognitionQuery.data) {
      if (filterValues?.pagination?.page === 1) {
        setTeamRecognitionData(teamRecognitionQuery.data.results || []);
      } else {
        setTeamRecognitionData((prevData) => [
          ...prevData,
          ...(teamRecognitionQuery.data?.results || []),
        ]);
      }
      setNextQuery(!!teamRecognitionQuery.data.next);
    }
  }, [teamRecognitionQuery.data]);

  const onFilterChange = useCallback(
    (filterKey: keyof FilterValuesType, newValue: string | {page: number}) => {
      setFilterValues(
        produce((draft) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: this actually works since we know filterKey is a keyof FilterValuesType
          draft[filterKey] = newValue;
          return draft;
        }),
      );
    },
    [setFilterValues],
  );

  const handleLoadMore = useCallback(() => {
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      onFilterChange('pagination', {page: nextPage});
      return nextPage;
    });
  }, [onFilterChange]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !teamRecognitionQuery.isLoading) {
        handleLoadMore();
      }
    });
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
  }, [handleLoadMore, teamRecognitionQuery.isLoading]);

  return (
    <>
      <div className="flex flex-col">
        <div
          className={`flex flex-col md:flex-col justify-between 
            md:items-start sm:items-center mb-400 sm:mb-300`}
        >
          <h2 className="recognition-feed-header mb-300 md:mb-200 sm:mb-0">Activity feed</h2>
          <RecognitionFilters
            controller={controller}
            onFilterChange={onFilterChange}
            filterValues={filterValues}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className="flex flex-col max-h-max mb-24">
          <ActivityList teamRecognitionData={teamRecognitionData} />
          <div ref={loadMoreRef}>
            {(teamRecognitionQuery.isLoading || teamRecognitionQuery.isFetching) && nextQuery && (
              <div className="flex flex-col w-full mt-6">
                <div className="mb-[9px]">
                  <ComptLoadingAnimation />
                </div>
                <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more recognition...</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

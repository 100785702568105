import React from 'react';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

// Types
import {PerkCategory} from '@compt/types/perk-category';
import {Tooltip} from 'react-tooltip';

const MAX_ICONS_DEFAULT = 9;

interface CategoryIconProps {
  perkCategory: PerkCategory;
}
export const CategoryIcon = (props: CategoryIconProps) => (
  <ComptSvgIcon
    iconName={props.perkCategory.internal_id}
    className="relative z-30 inline-block w-600 h-600 rounded-full ring-1 ring-stroke-divider1
     bg-surface-secondary-hover overflow-hidden"
    svgProp={{width: '40px', height: '40px'}}
  />
);

interface CategoryIconListProps {
  programId: number;
  perkCategoryList: PerkCategory[];
  maxIcons?: number;
}

export const CategoryIconList = (props: CategoryIconListProps) => {
  const maxIcons = props.maxIcons ?? MAX_ICONS_DEFAULT;

  const displayedIcons = props.perkCategoryList.slice(0, maxIcons);
  const hiddenIcons = props.perkCategoryList.slice(maxIcons);

  return (
    <div className="isolate flex -space-x-1 overflow-hidden h-12 items-center pl-1 my-6">
      {displayedIcons.map((perkCategory) => (
        <CategoryIcon key={perkCategory.id} perkCategory={perkCategory} />
      ))}
      {hiddenIcons.length > 0 && (
        <>
          <Tooltip
            anchorSelect={`#perk-categories-${props.programId}`}
            place="bottom"
            opacity={100}
            style={{borderRadius: '6px', display: 'flex', flexDirection: 'column'}}
          >
            <ul>
              {hiddenIcons.map((icon) => (
                <li key={icon.id}>{icon.name}</li>
              ))}
            </ul>
          </Tooltip>
          <div
            id={`perk-categories-${props.programId}`}
            className={`bg-surface-tint relative z-30 flex items-center w-600 h-600
          rounded-full ring-1 ring-stroke-divider1 overflow-hidden`}
          >
            <span className="flex-grow flex justify-center label2 text-color-body1">
              +{hiddenIcons.length}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

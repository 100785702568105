import React, {useCallback, useEffect, useRef, useState} from 'react';

// RTK queries
import {
  useGetActiveEmployeePreApprovalRequestsQuery,
  useGetCompletedEmployeePreApprovalRequestsQuery,
} from '@compt/app/services/api/employee-learning-development-slice';

// Types
import {Company} from '@compt/types/company';
import {
  EmployeePreApprovalRequest,
  PreApprovalRequest,
} from '@compt/types/learning-development/pre-approval-request';
import {MAX_15_PAGE_LIMIT} from '@compt/constants';

// Components
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptReimbursementCard} from '@compt/common/compt-reimbursement-card/compt-reimbursement-card';

export interface TeamRecognitionActivityFeedProps {
  company: Company;
}

export const LearningActivityFeed = ({company}: TeamRecognitionActivityFeedProps) => {
  const [activeRequests, setActiveRequests] = useState<EmployeePreApprovalRequest[]>([]);
  const [completedRequests, setCompletedRequests] = useState<EmployeePreApprovalRequest[]>([]);
  const [activeOffset, setActiveOffset] = useState<number>(0);
  const [completedOffset, setCompletedOffset] = useState<number>(0);
  const loadMoreActiveRef = useRef<HTMLDivElement>(null);
  const loadMoreCompletedRef = useRef<HTMLDivElement>(null);
  const prevActiveCount = useRef<number>(0);
  const prevCompletedCount = useRef<number>(0);

  const {
    data: activeReqData,
    isLoading: isActiveReqLoading,
    isFetching: isActiveReqFetching,
  } = useGetActiveEmployeePreApprovalRequestsQuery(
    {
      companyId: company.id,
      filter: {
        limit: MAX_15_PAGE_LIMIT,
        offset: activeOffset,
      },
    },
    {skip: !company.id},
  );

  const {
    data: completedReqData,
    isLoading: isCompletedReqLoading,
    isFetching: isCompletedReqFetching,
  } = useGetCompletedEmployeePreApprovalRequestsQuery(
    {
      companyId: company.id,
      filter: {
        limit: MAX_15_PAGE_LIMIT,
        offset: completedOffset,
      },
    },
    {skip: !company.id},
  );

  useEffect(() => {
    if (activeReqData && activeReqData?.results) {
      if (activeOffset === 0) {
        setActiveRequests(activeReqData.results);
        prevActiveCount.current = activeReqData.count;
      } else if (activeReqData.count > prevActiveCount.current) {
        setActiveOffset(0);
      } else {
        setActiveRequests((prevRequests) => {
          const newRequests = activeReqData.results.filter(
            (request) => !prevRequests.some((prev) => prev.id === request.id),
          );
          return [...prevRequests, ...newRequests];
        });
      }
    }
  }, [activeReqData]);

  useEffect(() => {
    if (completedReqData && completedReqData?.results) {
      if (completedOffset === 0) {
        setCompletedRequests(completedReqData.results);
        prevCompletedCount.current = completedReqData.count;
      } else if (completedReqData.count > prevCompletedCount.current) {
        setCompletedOffset(0);
      } else {
        setCompletedRequests((prevRequests) => {
          const newRequests = completedReqData.results.filter(
            (request) => !prevRequests.some((prev) => prev.id === request.id),
          );
          return [...prevRequests, ...newRequests];
        });
      }
    }
  }, [completedReqData]);

  const loadMoreActiveReqs = useCallback(() => {
    if (activeReqData?.next) {
      const url = new URL(activeReqData?.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0');
      setActiveOffset(newOffset);
    }
  }, [activeReqData]);

  useEffect(() => {
    if (!loadMoreActiveRef.current || activeRequests.length === 0) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isActiveReqLoading) {
        loadMoreActiveReqs();
      }
    });
    if (loadMoreActiveRef.current) {
      observer.observe(loadMoreActiveRef.current);
    }
    return () => {
      if (loadMoreActiveRef.current) observer.unobserve(loadMoreActiveRef.current);
    };
  }, [loadMoreActiveReqs, isActiveReqLoading, activeRequests]);

  const loadMoreCompletedReqs = useCallback(() => {
    if (completedReqData?.next) {
      const url = new URL(completedReqData?.next);
      const newOffset = parseInt(url.searchParams.get('offset') || '0');
      setCompletedOffset(newOffset);
    }
  }, [completedReqData]);

  useEffect(() => {
    if (!loadMoreCompletedRef.current || completedRequests.length === 0) return;
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !isCompletedReqLoading) {
        loadMoreCompletedReqs();
      }
    });
    if (loadMoreCompletedRef.current) {
      observer.observe(loadMoreCompletedRef.current);
    }
    return () => {
      if (loadMoreCompletedRef.current) observer.unobserve(loadMoreCompletedRef.current);
    };
  }, [loadMoreCompletedReqs, isCompletedReqLoading, completedRequests]);

  // Replace request with updated data in request list
  const onUpdateRequest = useCallback(
    (updatedRequest: PreApprovalRequest) => {
      const newRequestList = activeRequests.map((request) => {
        if (request.id === updatedRequest.id) {
          return updatedRequest;
        }
        return request;
      });

      setActiveRequests(() => newRequestList);
    },
    [activeRequests],
  );

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col max-h-max mb-24">
          <div>
            <>
              {(isActiveReqLoading || isActiveReqFetching || activeRequests.length > 0) && (
                <p className="heading1 mb-4">
                  Active requests
                  {activeReqData?.count && activeReqData?.count > 0
                    ? ` (${activeReqData.count})`
                    : ''}
                </p>
              )}
              {activeRequests &&
                activeRequests.length > 0 &&
                activeRequests.map((request, i) => (
                  <div key={`active-request-card-${request.id}-${i}`} className="mb-400">
                    <ComptReimbursementCard
                      request={request}
                      company={company}
                      onUpdateRequest={onUpdateRequest}
                    />
                  </div>
                ))}
            </>
            <div ref={loadMoreActiveRef}>
              {(isActiveReqLoading || isActiveReqFetching) && (
                <div className="flex flex-col w-full m-6">
                  <div className="mb-[9px]">
                    <ComptLoadingAnimation />
                  </div>
                  <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more requests...</p>
                </div>
              )}
            </div>
            <>
              {(isCompletedReqLoading || isCompletedReqFetching || completedRequests.length) && (
                <p className="heading1 mb-4">
                  Completed requests
                  {completedReqData?.count && completedReqData?.count > 0
                    ? ` (${completedReqData.count})`
                    : ''}
                </p>
              )}
              {completedRequests &&
                completedRequests.length > 0 &&
                completedRequests.map((request, i) => (
                  <div key={`completed-request-card-${request.id}-${i}`} className="mb-400">
                    <ComptReimbursementCard request={request} company={company} />
                  </div>
                ))}
            </>
            <div ref={loadMoreCompletedRef}>
              {(isCompletedReqLoading || isCompletedReqFetching) && (
                <div className="flex flex-col w-full m-6">
                  <div className="mb-[9px]">
                    <ComptLoadingAnimation />
                  </div>
                  <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more requests...</p>
                </div>
              )}
            </div>
            {!(isCompletedReqLoading || isCompletedReqFetching) &&
              !(isActiveReqLoading || isActiveReqFetching) &&
              !activeRequests?.length &&
              !completedRequests?.length && (
                <div className="flex flex-col items-center">
                  <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
                  <h4 className="text-center">Start a request on the right!</h4>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};

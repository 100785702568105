import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {ComptImage, ComptImageSize} from '@compt/common/compt-image/compt-image';
import {ComptLink} from '@compt/common/compt-link/compt-link';
import {NotificationPreferenceForm} from '@compt/pages/employee-onboarding-page/notification-preference';
import {PreferredNameForm} from '@compt/pages/employee-onboarding-page/preferred-name';
import {UserTimezoneForm} from '@compt/pages/employee-onboarding-page/timezone';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/query';

export const OnboardingBasePage = () => {
  const navigate = useNavigate();
  const session = useGetSessionQuery();
  const userData = session?.data;
  const timezones = userData?.timezones || [];
  const hasSlackToken = userData?.has_slack_token;
  const canEnableSlack = userData?.can_enable_slack;
  const userUid = userData?.uid;
  const baseUrl = userData?.base_url;
  const steps = userData?.onboarding_steps;
  const onboardingRequired = steps ? Object.values(steps).includes(false) : false;

  const companyQuery = useGetCompanyQuery(userData?.user_id ?? skipToken);
  const company = companyQuery?.data;
  const slackClientId = company?.slack_client_id;

  let component = null;

  if (!steps?.nickname) {
    component = <PreferredNameForm firstName={userData?.first_name} />;
  } else if (!steps?.notifications) {
    component = (
      <NotificationPreferenceForm
        hasSlackToken={hasSlackToken}
        canEnableSlack={canEnableSlack}
        userUid={userUid}
        baseUrl={baseUrl}
        slackClientId={slackClientId}
      />
    );
  } else if (!steps?.time_zone) {
    component = <UserTimezoneForm timezones={timezones} />;
  }

  useEffect(() => {
    if (!onboardingRequired) {
      navigate('/');
    }
  }, [steps]);

  return (
    <div className="max-w-sm">
      <ComptImage
        className="pb-8 flex justify-center"
        alt="logo-header"
        src="/static/img/logo-header-light-no-pad.png"
      />

      {component}

      <ComptImage
        className="mt-8 flex justify-center"
        alt="logo-header"
        size={ComptImageSize.SMALL}
        src="/static/img/logo-header-light-no-pad.png"
      />

      <p className="body3 mt-16 text-center">
        By activating your account you agree to Compt&apos;s{' '}
        <span className="flex justify-center">
          <ComptLink link="/terms" newTab>
            terms of use
          </ComptLink>
          &nbsp;and&nbsp;
          <ComptLink link="/privacy" newTab>
            privacy policy
          </ComptLink>
          .
        </span>
      </p>
    </div>
  );
};

import {GroupTypeOption} from '@compt/types/user-groups/user-groups';
import {createContext, useContext} from 'react';
import {FieldValues, UseFieldArrayReturn} from 'react-hook-form';

export const GroupTableContext = createContext({
  maxNumOfColumns: 3,
  groupTypeOptions: [] as GroupTypeOption[],
  columnFieldMethods: {} as UseFieldArrayReturn<FieldValues, 'selectedColumns', 'id'>,
  rowFieldMethods: {} as UseFieldArrayReturn<FieldValues, 'amountOverrides', 'id'>,
});

export const useGroupTableContext = () => useContext(GroupTableContext);

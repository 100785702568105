import React from 'react';
import {formatCurrency} from '@compt/utils/international-helpers';
import {useWatch} from 'react-hook-form';

type ComputedAmountSectionProps = {
  maxAvailable: string | undefined;
  currency: string | undefined;
  control: any;
};

export const ComputedAmountSection = (props: ComputedAmountSectionProps) => {
  const {maxAvailable, currency, control} = props;
  const watchedAmount = useWatch({control, name: 'amount'});

  if (!maxAvailable || !currency) {
    return <></>;
  }

  const balanceAsNum = parseFloat(maxAvailable);
  const watchedAmountAsNum = parseFloat(watchedAmount) || 0;

  const amountCovered = Math.min(balanceAsNum, watchedAmountAsNum);
  const amountRemaining = balanceAsNum - amountCovered;

  const amountValues = [
    {label: 'Max Available', amount: formatCurrency(balanceAsNum, currency)},
    {
      label: 'Amount Covered',
      amount: formatCurrency(amountCovered, currency),
    },
    {label: 'Amount Remaining', amount: formatCurrency(amountRemaining, currency)},
  ];

  return (
    <div className="my-400">
      {amountValues.map((amount, i) => (
        <AmountText key={`amount-${i}`} label={amount.label} amount={amount.amount} />
      ))}
      {watchedAmount > balanceAsNum && (
        <div className="flex items-center h-fit mt-6" data-testid="over-budget-feedback">
          <p className="body3 text-stroke-critical">
            This is over your pre-approved request amount. Please explain in your description below.
          </p>
        </div>
      )}
    </div>
  );
};

const AmountText = ({label, amount}: {label: string; amount: string | number | undefined}) => (
  <div className="flex justify-between mt-4">
    <p className="body1">{label}</p>
    <p className="label1 text-color-heading">{amount}</p>
  </div>
);

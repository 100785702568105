import React, {HTMLAttributes, SetStateAction, Dispatch, ReactElement} from 'react';
import {ComptButton, ComptButtonType, ComptButtonIcon} from '../compt-button/compt-button';
import {ComptSvgIcon, Icon} from '../compt-svg-icon/compt-svg-icon';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';

export enum ComptModalIcon {
  CHECK = 'modal-check-icon',
  SAVE = 'modal-save-icon',
  TRASH = 'modal-trash-icon',
}

export interface ComptModalProps extends HTMLAttributes<HTMLElement> {
  iconId?: ComptModalIcon | keyof typeof Icon;
  title: string;
  subtitle?: string;
  showFooter?: boolean;
  showCancel?: boolean;
  submitButtonText?: string;
  showXButton?: boolean;
  secondaryButtonComponent?: ReactElement;
  open: boolean;
  'data-testid'?: string;
  setOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  removeTitleLine?: boolean;
  iconOnTop?: boolean;
  preventAccidentalDismiss?: boolean;
}

const defaults: ComptModalProps = {
  title: '',
  showFooter: true,
  showCancel: true,
  submitButtonText: 'Submit',
  showXButton: true,
  open: false,
  setOpen: () => {},
  preventAccidentalDismiss: false,
};

export const ComptModal = (props: ComptModalProps = defaults) => {
  const renderIconAndCloseButton = () => {
    if (props.iconId) {
      if (!props.showXButton || !props.iconOnTop) {
        return (
          <ComptSvgIcon
            iconName={props.iconId}
            labelHidden={false}
            ariaLabel={props.iconId}
            className="pr-3"
          />
        );
      }

      return (
        <div className="flex justify-between w-full">
          <ComptSvgIcon
            iconName={props.iconId}
            labelHidden={false}
            ariaLabel={props.iconId}
            className="px-3 py-2.5 border-solid border-2 border-gray-200 rounded-lg items-center"
          />
          <ComptButton
            ariaLabel="Close modal button"
            buttonType={ComptButtonType.BORDERLESS}
            iconId={ComptButtonIcon.X}
            onClick={() => props.setOpen(false)}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <ComptModalShell
      open={props.open}
      setOpen={props.setOpen}
      preventAccidentalDismiss={props.preventAccidentalDismiss}
    >
      <div
        className={`compt-modal flex items-start ${props.iconOnTop ? 'flex-col' : ''}`}
        data-testid={props['data-testid']}
      >
        {renderIconAndCloseButton()}
        <div className={`w-full ${props.className}`}>
          <div className="flex justify-between items-center">
            <h2 className="compt-text--semi-bold">{props.title}</h2>
            {props.showXButton && !props.iconOnTop && (
              <ComptButton
                ariaLabel="Close modal button"
                buttonType={ComptButtonType.BORDERLESS}
                iconId={ComptButtonIcon.X}
                onClick={() => props.setOpen(false)}
              />
            )}
          </div>
          {props.subtitle && <p className="body3 text-color-body2">{props.subtitle}</p>}
          {!props.removeTitleLine && <hr className="mt-5 mb-6" />}
          {props.children}
          {props.showFooter && (
            <div className="grid grid-flow-col w-full gap-3 mt-8">
              {props.showCancel && (
                <ComptButton buttonType={ComptButtonType.OUTLINED}>Cancel</ComptButton>
              )}
              {props.secondaryButtonComponent && props.secondaryButtonComponent}
              <ComptButton>{props.submitButtonText}</ComptButton>
            </div>
          )}
        </div>
      </div>
    </ComptModalShell>
  );
};

import React, {LinkHTMLAttributes} from 'react';

export interface ComptLinkProps extends LinkHTMLAttributes<HTMLAnchorElement> {
  textClassName?: string;
  link: string;
  newTab?: boolean;
  'data-testid'?: string;
  download?: boolean;
}

const defaults: ComptLinkProps = {
  textClassName: 'label3 text-color-link',
  link: '',
  newTab: false,
  download: false,
};

const defaultClasses = ['compt-link', 'inline-flex', 'hover:underline', 'cursor-pointer'];

export const ComptLink = ({
  textClassName,
  link,
  newTab,
  children,
  className,
  onClick,
  'data-testid': testId,
  download,
}: ComptLinkProps = defaults) => (
  <a
    href={link}
    className={className}
    target={newTab ? '_blank' : ''}
    rel={newTab ? 'noreferrer' : ''}
    onClick={onClick}
    data-testid={testId}
    download={download}
  >
    <p className={`${textClassName} ${{defaultClasses}}`}>{children}</p>
  </a>
);

ComptLink.defaultProps = defaults;

import React from 'react';
import {AssignmentsTable} from './assignments-table.container';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {USER_ROLES} from '@compt/utils/user-roles-helper';

export const AssignmentsPage = () => {
  const session = useGetSessionQuery();
  const hasRequiredRole = session.data?.roles.includes(USER_ROLES.delegate);

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  return (
    <ComptPage title="Assignments" subtitle="File claims for team members" className="h-full">
      <AssignmentsTable />
    </ComptPage>
  );
};

import React from 'react';
import animationData from '../../../assets/animations/squiggle-load.json';
import Lottie from 'react-lottie';

interface ComptLoadingAnimationProps {
  size?: number;
}

export const ComptLoadingAnimation = ({size = 100}: ComptLoadingAnimationProps) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return <Lottie options={defaultOptions} height={size} width={size} />;
};

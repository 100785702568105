import React from 'react';
import {FieldValues, useForm} from 'react-hook-form';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptForm} from '@compt/common/forms/compt-form/compt-form';
import {TimeZone, UserSession} from '@compt/types/account';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {useUpdateUserOnboardingInfoMutation} from '@compt/app/services/api/accounts-slice';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export interface UserTimeZoneProps {
  timezones: TimeZone[];
}

export const UserTimezoneForm = (props: UserTimeZoneProps) => {
  const [updateUserOnboardingInfo, {isLoading: isUpdating}] = useUpdateUserOnboardingInfoMutation();
  const formMethods = useForm({mode: 'onChange'});
  const {handleSubmit, reset} = formMethods;

  const submitForm = (form: FieldValues) => {
    if (isUpdating) {
      return false;
    }

    const value = form?.user_timezone?.label;
    const submission = {user_timezone: value} as UserSession;

    updateUserOnboardingInfo(submission).then((data) => {
      reset();
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem updating your time zone');
        return;
      }
    });
  };

  const fieldSections = [
    {
      fields: [
        {
          fieldType: ComptDropDownField,
          secret: false,
          id: 'user_timezone',
          label: 'Time zone',
          placeholder: 'Select your time zone',
          options: props.timezones,
          getKey: (option: TimeZone) => option.id,
          getDisplayText: (option: TimeZone) => option.label,
          validation: {
            required: 'Time zone is required',
          },
          by: (a: TimeZone, b: TimeZone) => a?.id === b?.id,
          'data-testid': 'time-zone-field',
        },
      ],
    },
  ];

  return (
    <>
      <p className="body1 text-center">Time Zone Selection</p>
      <p className="body2 mt-3 mb-8 text-center">Please select your local time zone.</p>
      <ComptForm fieldSections={fieldSections} onSubmit={submitForm} formMethods={formMethods}>
        <ComptButton
          data-testid="timezone-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          disabled={isUpdating}
          onClick={handleSubmit(submitForm)}
        >
          All Done!
        </ComptButton>
      </ComptForm>
    </>
  );
};

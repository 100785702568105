import React from 'react';
import {formatCurrency} from '@compt/utils/international-helpers';
import {Allotment} from '@compt/types/allotments';

export interface GiveTeamRecognitionSummaryProps {
  allotment?: Allotment | null;
  recognitionAmount: number;
  recipientCount: number;
}

const SummaryRow = (label: string, amount?: number, currency?: string) => {
  let valueToShow = '-';
  if ((amount || amount === 0) && currency) {
    valueToShow = formatCurrency(amount, currency);
  }

  return (
    <div className="flex justify-between my-2">
      <p className="body1 text-color-heading">{label}</p>
      <p className="label1 text-gray-900">{valueToShow}</p>
    </div>
  );
};

export const GiveTeamRecognitionSummary = ({
  allotment,
  recognitionAmount,
  recipientCount,
}: GiveTeamRecognitionSummaryProps) => {
  const recognitionTotal = recognitionAmount * recipientCount;
  const remainingBalance = allotment?.balance_amount
    ? allotment.balance_amount - recognitionTotal
    : 0;

  return (
    <div className="flex flex-col">
      {SummaryRow('Max Available to Give', allotment?.balance_amount, allotment?.currency)}
      {SummaryRow("Total Amount You're Giving", recognitionTotal, allotment?.currency)}
      {SummaryRow('Amount Remaining', remainingBalance, allotment?.currency)}
    </div>
  );
};

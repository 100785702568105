import React, {useMemo, useState} from 'react';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {BusinessExpenseReportsTableController} from './business-expense-reports-table.controller';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {
  useGetBusinessExpenseReportListQuery,
  useUpdateBusinessExpenseReportMutation,
} from '@compt/app/services/api/business-expense-reports-slice';
import {MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE} from '@compt/constants';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {useDebounce} from '@uidotdev/usehooks';
import {useNavigate} from 'react-router-dom';
import {DeleteExpenseReportModal} from '@compt/pages/business-expenses/business-expense-page/components/delete-business-report-modal';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {BusinessReportSidePanel} from '@compt/pages/business-expenses/components/business-report-side-panel';
import {FieldValues} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';

export const BusinessExpenseReportsTable = () => {
  const controller = new BusinessExpenseReportsTableController();
  const {updateQueryParamsOnValuesChanged} = controller;

  const navigate = useNavigate();
  const session = useGetSessionQuery();
  const userId = session.data?.user_id;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const [reportsQueryValues, setReportsQueryValues] = useState<FormattedFilterObject | undefined>();
  const debouncedQueryValues = useDebounce(reportsQueryValues, 300);
  const businessExpenseReportListQuery = useGetBusinessExpenseReportListQuery(
    {companyId, params: debouncedQueryValues},
    {skip: !userId},
  );

  const [updateBusinessExpenseReport, {isLoading}] = useUpdateBusinessExpenseReportMutation();

  const [selectedReport, setSelectedReport] = useState<BusinessExpenseReport | null>(null);
  const [openSelectedReportSidePanel, setOpenSelectedReportSidePanel] = useState(false);

  const [reportToDelete, setReportToDelete] = useState<BusinessExpenseReport | null>(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onActionMenuClicked = (
    report: BusinessExpenseReport,
    action: 'VIEW' | 'EDIT' | 'DELETE',
  ) => {
    switch (action) {
      case 'VIEW':
        navigate(`/business-expenses/${report.id}`);

        break;
      case 'EDIT':
        if (selectedReport?.id !== report.id) {
          setSelectedReport(report);
          setOpenSelectedReportSidePanel(true);
        }
        break;
      case 'DELETE':
        setReportToDelete(report);
        setOpenDeleteModal(true);
        break;
    }
  };
  const columnDefinition = controller.getColumnDefinition(onActionMenuClicked);
  const filterConfiguration = controller.getFilterConfiguration();
  const initialFilterValues = BusinessExpenseReportsTableController.getInitialFilterValues();

  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No expense reports were found with the current filters.'
        : 'No expense reports have been added yet',
    [filtersApplied],
  );

  const noDataSubtitle = useMemo(
    () =>
      filtersApplied ? (
        <p />
      ) : (
        <p className="body1 text-center">
          You haven&apos;t created any expense reports yet. Click on &quot;New Expense Report&quot;
          to get started!
        </p>
      ),
    [filtersApplied],
  );

  const onSubmitEditReport = (form: FieldValues) => {
    if (!selectedReport) {
      return;
    }
    const submission = {
      ...selectedReport,
      ...form,
    };

    updateBusinessExpenseReport(submission).then((result) => {
      setOpenSelectedReportSidePanel(false);
      setSelectedReport(null); // If we keep the modal open, this will cause the title to change to 'create'
      if ('error' in result) {
        if ('data' in result.error) {
          triggerCustomToast(
            'error',
            'There was a problem updating your report',
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            result.error.data?.errors.join(' '),
          );
        } else {
          triggerCustomToast('error', 'There was a problem updating your report');
        }
        console.error('Error updating claim', result.error);
      } else {
        triggerCustomToast('success', 'Successfully updated report');
      }
    });
  };

  return (
    <ComptLoadingIndicator
      isLoading={companyQuery.isLoading || businessExpenseReportListQuery.isLoading}
      className="pt-72"
    >
      <BusinessReportSidePanel
        reportInfo={selectedReport}
        open={openSelectedReportSidePanel}
        setOpen={setOpenSelectedReportSidePanel}
        onSubmit={onSubmitEditReport}
        mutationLoading={isLoading}
      />
      <DeleteExpenseReportModal
        reportInfo={reportToDelete}
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
      />
      {companyQuery.data && businessExpenseReportListQuery.data && (
        <ComptTableContextProvider>
          <div className="mt-400 sm:mt-000">
            <ComptTable
              company={companyQuery.data}
              tableId="business-expense-reports-table"
              className="w-full"
              data={businessExpenseReportListQuery.data?.results ?? []}
              dataLoading={companyQuery.isFetching || businessExpenseReportListQuery.isFetching}
              noDataTitleText={noDataTitleText}
              noDataSubtitle={noDataSubtitle}
              allowShowHide={false}
              columnDefinition={columnDefinition}
              filterConfiguration={filterConfiguration}
              initialFilterValues={initialFilterValues}
              allowPagination={true}
              totalCount={businessExpenseReportListQuery.data?.count}
              itemsPerPage={MAX_BUSINESS_EXPENSE_REPORTS_PER_PAGE}
              onChangeQueryValues={(filterValues, pagination, ordering) =>
                updateQueryParamsOnValuesChanged(
                  companyId,
                  setReportsQueryValues,
                  setFiltersApplied,
                  pagination,
                  filterValues,
                  ordering,
                )
              }
              onRowClicked={(row) => {
                navigate(`/business-expenses/${row.id}`);
              }}
              stickyLastColumn
            />
          </div>
        </ComptTableContextProvider>
      )}
    </ComptLoadingIndicator>
  );
};

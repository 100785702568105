import React, {HTMLAttributes} from 'react';
import {PerkCategory, PerkCategoryCustomizations} from '@compt/types/perk-category';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {Allotment} from '@compt/types/allotments';
import {SupportedCountriesType} from '@compt/utils/international-helpers';

export interface ComptCategoryCardProps extends HTMLAttributes<HTMLElement> {
  userCountryCode?: SupportedCountriesType;
  allotment?: Allotment;
  perkCategory: PerkCategory;
  categoryCustomizations?: PerkCategoryCustomizations[] | undefined;
  onCategoryClick: () => void;
  'data-testid'?: string;
}

const defaultClasses = [
  'compt-category-card',
  'text-left',
  'flex',
  'justify-between',
  'w-full',
  'cursor-pointer',
];

const getClasses = (className?: string) => {
  const classes = [...defaultClasses, className];
  return classes.join(' ');
};

export const ComptCategoryCard = (props: ComptCategoryCardProps) => {
  /**
   * Returns whether the category is taxable based on the following conditions:
   *
   * If there's a perk category customization for the user's country, it returns the value in the perk category
   * customization.
   *
   * If there's not a perk category customization for the user's country and the user's country is US, it returns the
   * default taxability of the category.
   *
   * Lastly if there's no customization for the user's country AND the user's country is NOT US, it ALWAYS returns true.
   *
   * @type {boolean} the appropriate taxability of the perk category.
   */
  const getIsTaxable = () => {
    let isTaxable =
      !props.userCountryCode || props.userCountryCode === 'US' ? props.perkCategory.taxable : true;

    if (props.categoryCustomizations?.length) {
      props.categoryCustomizations.forEach((customization: PerkCategoryCustomizations) => {
        if (
          customization.country_code === props.userCountryCode &&
          customization.perk_category.id === props.perkCategory.id
        ) {
          isTaxable = customization.is_taxable;
        }
      });
    }

    return isTaxable;
  };

  return (
    <li key={props.perkCategory.id} className="list-none">
      <div
        className={getClasses(props.className)}
        onClick={props.onCategoryClick}
        data-testid={props['data-testid']}
      >
        <div
          className={`flex justify-between gap-x-400 w-full h-full rounded-xl
            hover:bg-gray-100 py-400 px-300 sm:px-000 pr-000 sm:pl-300 sm:pr-200`}
        >
          <div className="inline-flex items-start pr-450">
            <ComptSvgIcon
              iconName={props.perkCategory.internal_id}
              className="pr-200"
              isCategoryIcon
              svgProp={{width: '40px', height: '40px'}}
            />
            <div>
              <p className="heading4">{props.perkCategory.name}</p>
              <p className="body3 text-color-body1">{getIsTaxable() ? 'Taxable' : 'Non-taxable'}</p>
              <p className="body3 text-color-body1 pt-1">{props.perkCategory.description}</p>
            </div>
          </div>
          <ComptSvgIcon
            className="flex flex-col justify-center"
            iconName="chevron-right-icon"
            svgProp={{width: '20px', height: '20px'}}
          />
        </div>
      </div>
    </li>
  );
};

import {UserSession} from '@compt/types/account';

export enum ANALYTICS_TOOL_ID {
  POSTHOG = 'POSTHOG',
  GOOGLE_ANALYTICS = 'GOOGLE_ANALYTICS',
}

export enum EVENT_ACTION {
  CLICKED_CANCEL_EXPENSE = 'clicked_cancel_claim',
  SAVED_EDIT_EXPENSE = 'saved_edit_expense',
  CLICKED_EDIT_EXPENSE = 'clicked_edit_expense',
  CLICKED_DELETE_EXPENSE = 'clicked_delete_expense',
  CLICKED_VIEW_EXPENSE = 'clicked_view_expense',
  SAVED_STIPEND_EDITS = 'saved_stipend_edits',
  SAVED_NEW_CLAIM = 'saved_new_claim',
  SAVED_NEW_STIPEND = 'saved_new_stipend',
}

export enum EVENT_CATEGORY {
  CLAIM_PAGE = 'CLAIM_PAGE',
  MANAGE_STIPENDS = 'Manage stipends',
  EMPLOYEE_MANAGED_EXPENSES = 'Employee-managed expenses',
  TEAM_RECOGNITION = 'Team Recognition',
}

export enum EVENT_LABEL {
  INITIAL_EDIT_INTEREST = 'Initial edit interest',
  SAVED_THE_EDITED_STIPEND = 'Saved the edited stipend',
  CANCEL_CLAIM_SUBMISSION = 'Cancel claim submission',
  SAVED_PERK_CLAIM = 'Saved perk claim',
  SAVED_A_NEW_STIPEND = 'Saved a new stipend',
}

export interface HelpScoutMessagePathOptions {
  type: string;
  path: string;
  companyId: number;
}

export interface HelpScoutMessageClaimOptions {
  type: string;
  companyId: number;
  allotmentCycleId: number;
  categoryId: string | number;
}

export interface ParsedSetting {
  companyId: number;
  allotmentCycleId: number;
  categoryId: number;
  messageId: string;
  path: string;
  type: string;
}

export interface SendEventProps {
  action?: EVENT_ACTION | string;
  category?: EVENT_CATEGORY;
  label?: EVENT_LABEL | string;
  value?: number;
  exclude?: ANALYTICS_TOOL_ID | ANALYTICS_TOOL_ID[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: string | any;
}

export type SendEvent = (eventProps: SendEventProps) => void;

export interface AnalyticsTool {
  identify(userSession: UserSession): void;

  sendPageView(pathname: string, search: string): void;

  sendEvent(eventProps: SendEventProps): void;
}

const EVENT_CHANGED_CATEGORY = 'CHANGED_CATEGORY';
const EVENT_CHANGED_ALLOTMENT = 'CHANGED_ALLOTMENT';

export const EXPENSE_DETAIL_EVENT_CATEGORY = 'EXPENSE_DETAIL';

/**
 * Events from the claim page.
 * @type {{EVENT_CHANGED_ALLOTMENT: string, EVENT_CHANGED_CATEGORY: string, EVENT_SAVED_CLAIM: string, EVENT_CANCELLED_CLAIM: string}}
 */
export const EXPENSE_DETAIL_EVENT_ACTIONS = {
  EVENT_CHANGED_CATEGORY,
  EVENT_CHANGED_ALLOTMENT,
  EVENT_CLICKED_EDIT_EXPENSE: 'clicked_edit_expense',
  EVENT_CANCELLED_CLAIM: 'clicked_cancel_claim',
  EVENT_SAVED_EDIT_EXPENSE: 'saved_edit_expense',
  EVENT_CLICKED_DELETE_EXPENSE: 'clicked_delete_expense',
  EVENT_CONFIRMED_DELETE_EXPENSE: 'confirmed_delete_expense',
};

export const TEAM_RECOGNITION_ACTIONS_NAMES = {
  GAVE_TEAM_RECOGNITION: 'gave_team_recognition',
  GAVE_SHOUT_OUT: 'gave_shout_out',
};

import React, {useCallback} from 'react';

// Hooks and methods
import {useFormContext, UseFormReturn} from 'react-hook-form';
import {useGroupTableContext} from './compt-group-table.context';
import {getGroupOptions} from './compt-group-table.helpers';
import {getSupportedCountryInfo} from '@compt/utils/international-helpers';

// Components
import {GroupTableDropdown} from './compt-group-table.dropdown';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {AmountAndCurrencyField} from './group-table-amount-and-currency-field';

// Types
import {UserGroup} from '@compt/types/user-groups/user-groups';
import {GroupTableFieldValues} from './compt-group-table.types';

interface GroupTableRowProps {
  userGroups: UserGroup[];
  rowNum: number;
  errors: any;
}

export const GroupTableRow = (props: GroupTableRowProps) => {
  const {columnFieldMethods, rowFieldMethods} = useGroupTableContext();

  const formMethods: UseFormReturn<GroupTableFieldValues> = useFormContext();

  const numberOfRows = formMethods.watch('amountOverrides')?.length;

  // Duplication errors captured and triggered in `compt-group-table.tsx`
  // If message exists in the overrides specifically, it's a duplication error
  const duplicationError =
    !!formMethods.formState.errors.amountOverrides?.[props.rowNum]?.overrides?.message;

  const selectedColumns = formMethods.watch('selectedColumns');

  const columnKeyExists = useCallback(
    (columnIndex: number) => !!selectedColumns?.[columnIndex]?.key,
    [selectedColumns],
  );

  // Update options when a group type has changed
  const getGroupOptionsCallback = useCallback(getGroupOptions, [selectedColumns]);

  function onRowDelete(rowToDelete: number) {
    rowFieldMethods.remove(rowToDelete);
  }

  function onRowChange(onFieldChange: (event: any) => void, event: any) {
    onFieldChange(event);

    // Set currency to match any selected country groups
    if ('groupType' in event && event.groupType === 'country') {
      const associatedCurrency = getSupportedCountryInfo(event.key)?.currency;

      formMethods.setValue(`amountOverrides.${props.rowNum}.currency`, associatedCurrency);
    }
  }

  return (
    <div className="flex border-b min-h-[72px]">
      {columnFieldMethods.fields.map((col, index) => {
        const selectedColumn = formMethods.getValues('selectedColumns')?.[index];
        const formFieldName = `amountOverrides.${props.rowNum}.overrides.${col.id}`;
        const groupOptions = getGroupOptionsCallback(selectedColumn?.key, props.userGroups);

        return (
          <GroupTableDropdown
            id={`${props.rowNum}-${col.id}`}
            name={formFieldName}
            control={formMethods.control}
            key={col.id}
            data-testid={formFieldName}
            placeholder="Select a group"
            options={groupOptions}
            getKey={(option) => option.key}
            getDisplayText={(option) => option?.name}
            textClassName="body3 text-color-body1"
            disabled={!columnKeyExists(index) || groupOptions.length === 0}
            onDropdownChange={onRowChange}
            errors={props.errors?.overrides?.[col.id]}
            showDuplicationError={index === 0 && duplicationError}
          />
        );
      })}
      <AmountAndCurrencyField errors={props.errors} rowNum={props.rowNum} />
      <div className="flex items-center px-400 border-l">
        {numberOfRows > 1 ? (
          <div className="cursor-pointer" onClick={() => onRowDelete(props.rowNum)}>
            <ComptSvgIcon iconName="trash-icon" svgProp={{width: '24px', height: '24px'}} />
          </div>
        ) : (
          <div className="w-6" />
        )}
      </div>
    </div>
  );
};

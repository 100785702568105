import {
  FilterConfigOption,
  FilterValueTypes,
  SelectionChangeValues,
  SelectionOptionValues,
} from './compt-filter-bar.types';
import {isArrayOfStrings} from './compt-filter-bar.utils';

export class ComptSelectFilterController {
  checkIsSelectAll = (currentValues: FilterValueTypes, options?: FilterConfigOption[]) => {
    if (!Array.isArray(currentValues)) {
      return null;
    }

    if (isArrayOfStrings(currentValues)) {
      return options?.every(
        (option) => currentValues?.some((value: string) => value === option.id),
      );
    }
    return options?.every(
      (option) => currentValues?.some((value: SelectionOptionValues) => value.id === option.id),
    );
  };

  isChecked = (currentValues: FilterValueTypes, option: FilterConfigOption) => {
    if (!Array.isArray(currentValues)) {
      return false;
    }

    if (isArrayOfStrings(currentValues)) {
      return currentValues?.some((selection: string) => selection === option.id);
    }

    return currentValues?.some((selection: SelectionOptionValues) => selection.id === option.id);
  };

  handleSelectDeselectAll = (
    currentValues: FilterValueTypes,
    options: FilterConfigOption[] | undefined,
    handleChange: (changeValue: SelectionChangeValues) => void,
  ) => {
    const isSelectAll = this.checkIsSelectAll(currentValues, options);

    options?.forEach((option) => {
      const selectionOption = {id: option.id.toString(), name: option.name};
      handleChange({
        selection: selectionOption,
        checked: !isSelectAll,
      });
    });
  };
}

import React, {useContext} from 'react';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {ComptImage, ComptImageSize} from '@compt/common/compt-image/compt-image';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {ComptButtonIcon} from '@compt/common/compt-button/compt-button';
import {UserMenu} from '@compt/common/compt-top-bar/user-menu';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {SideNavContext} from '../compt-side-nav/side-nav-context';
import {useHandleMobileSideNav} from '@compt/utils/mobile-helpers';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {UserSession} from '@compt/types/account';
import {Company} from '@compt/types/company';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export type ComptTopBarLogoImageProps = {
  company?: Company;
};

export type ComptTopBarHomeButtonProps = {
  setSidebarOpen: (func: (previousState: boolean) => boolean) => void;
  company?: Company;
  userDataState?: UserSession;
};

const ComptTopBarLogoImage = (props: ComptTopBarLogoImageProps) => (
  <>
    <ComptImage
      className="pr-2 pb-2 -ml-2"
      alt="logo-header"
      src="/static/img/logo-blue.svg"
      size={ComptImageSize.SMALL}
    />
    {props.company?.logo_image ? (
      <ComptImage
        className="border-l border-gray-700 pl-2"
        alt="company-logo-image"
        src={props.company.logo_image}
        size={ComptImageSize.SMALL}
      />
    ) : (
      <p
        className={
          'flex items-center compt-text--bold compt-text--lg ' +
          'text-color-heading border-solid border-gray-700 border-l pl-2'
        }
      >
        {props.company?.name}
      </p>
    )}
  </>
);

export const ComptTopBarHomeButton = (props: ComptTopBarHomeButtonProps) => {
  const handleMobileSideNav = useHandleMobileSideNav(props.setSidebarOpen);
  const isAdmin = props.userDataState?.roles.includes(USER_ROLES.admin);
  const href = isAdmin ? '/dashboard' : '/#/app';

  return (
    <a className="flex" href={href} onClick={handleMobileSideNav}>
      <ComptTopBarLogoImage company={props.company} />
    </a>
  );
};

export const ComptTopBar = () => {
  const session = useGetSessionQuery();
  const userDataState = session.data;
  const company = useGetCompanyQuery(userDataState?.user_id ?? skipToken).data;
  const {setSidebarOpen, sidebarOpen} = useContext(SideNavContext);

  if (!userDataState) {
    return null;
  }

  return (
    <>
      <div className="relative">
        <div
          className="flex w-full border-b bg-white py-3 pl-4 pr-2 sm:p-6 sm:pl-1s h-[72px]
          justify-between z-10 items-center"
        >
          <div className="flex items-center">
            {/* Desktop only view */}
            <div className="flex sm:pr-6">
              <ComptTopBarHomeButton
                setSidebarOpen={setSidebarOpen}
                company={company}
                userDataState={userDataState}
              />
            </div>
          </div>
          {/* Desktop only view */}
          <div className="hidden sm:flex items-center">
            {!userDataState.is_hijacked && <UserMenu userDataState={userDataState} />}
          </div>
          {/* Mobile only view */}
          <div className={'sm:hidden z-10 fixed right-0 pt-2 pr-2'}>
            {!sidebarOpen && (
              <button
                className="mr-200"
                onClick={() => setSidebarOpen((previousState) => !previousState)}
              >
                <ComptSvgIcon iconName={ComptButtonIcon.MENU} />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

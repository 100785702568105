import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {AssignmentsTableController} from './assignments-table.controller';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {useLogInAsUserMutation} from '@compt/app/services/api/delegate-slice';
import {useGetAssignmentsQuery} from '@compt/app/services/api/accounts-slice';
import {UserAssignment} from '@compt/types/account';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';

export const AssignmentsTable = () => {
  const controller = new AssignmentsTableController();
  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const isDelegate = session.data?.roles.includes('is_delegate');
  const isHijacked = session.data?.is_hijacked;
  const navigate = useNavigate();

  useEffect(() => {
    if (!isDelegate || isHijacked) {
      navigate('/', {replace: true});
    }
  }, [isDelegate, isHijacked]);

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const getAssignmentsQuery = useGetAssignmentsQuery();

  const [loginAsUser, loginAsUserStatus] = useLogInAsUserMutation();
  const columnDefinition = controller.getColumnDefinition((user: UserAssignment) => {
    loginAsUser(user.user_id).then((result) => {
      if ('error' in result) {
        console.error('Error login in as user: ', result);
      } else {
        // We want a full page refresh when this happens so use native location instead of React Router.
        window.location.replace('/app/');
      }
    });
  });

  return (
    <ComptLoadingIndicator
      isLoading={
        companyQuery.isLoading ||
        !companyQuery.data ||
        getAssignmentsQuery.isLoading ||
        loginAsUserStatus.isLoading ||
        loginAsUserStatus.isSuccess
      }
    >
      {companyQuery.data && getAssignmentsQuery.data && (
        <ComptTableContextProvider>
          <ComptTable
            company={companyQuery.data}
            tableId="assignments-table"
            className="w-full"
            data={getAssignmentsQuery.data}
            dataLoading={companyQuery.isFetching}
            noDataTitleText="You have no assigned team members…"
            allowShowHide={false}
            columnDefinition={columnDefinition}
            allowPagination={true}
            totalCount={getAssignmentsQuery.data?.length}
          />
        </ComptTableContextProvider>
      )}
    </ComptLoadingIndicator>
  );
};

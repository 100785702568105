import React from 'react';
import {
  ComptFormField,
  DeprecatedComptFormFieldProps,
} from '@compt/common/forms/compt-form-field/compt-form-field';
import {FieldPathByValue, FieldValues} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {SupportsTestId} from '@compt/test-utils/test-helpers-types';
import {twMerge} from 'tailwind-merge';

interface CheckboxOption {
  name?: string;
  id: string;
  label: string;
  initialValue: boolean;
}

export interface ComptCheckboxGroupFieldProps extends DeprecatedComptFormFieldProps {
  // Options are for multiple inputs to be placed under one label.
  /**
   * @deprecated use a list of regular {@link ComptCheckboxField} instead of options.
   */
  options: CheckboxOption[];
  initialValue?: boolean | undefined;
  description?: string;
  className?: string;
  readOnly?: boolean;
}

interface SingleCheckBoxFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean>,
> extends ComptFormControlFieldBaseProps<boolean, TFieldValues, TName>,
    SupportsTestId {
  description?: string;
  className?: string;
  readOnly?: boolean;
}

/**
 * @deprecated use a list of {@link ComptCheckboxField}
 */
const CheckboxGroupField = (props: ComptCheckboxGroupFieldProps) => (
  <div className="max-w-lg space-y-6 mb-5">
    <div key={props.id} className="relative flex flex-col gap-x-3 items-start">
      {props.options.map((option) => (
        <div
          key={option.id}
          className="flex h-13 w-full items-center border border-solid border-gray-200
                         rounded-xl p-4 mt-2"
        >
          <input
            id={option.id}
            name={option.name || option.id}
            type="checkbox"
            defaultChecked={option.initialValue || false}
            data-testid={props['data-testid']}
            className="h-4 w-4 rounded border-gray-300 text-color-link
                           focus:ring-base-500"
            {...(props.register
              ? props.register(option.name || option.id || '', props.validation)
              : [])}
          />
          <div className="text-sm leading-6 pl-2">
            <label htmlFor="comments" className="font-medium text-gray-900">
              {option.label}
            </label>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const SingleCheckBoxField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean>,
>(
  props: SingleCheckBoxFieldProps<TFieldValues, TName>,
) => (
  <div className="max-w-lg space-y-6">
    <div className="relative flex gap-x-3">
      {/* Wrapping the input with the label allows for the entire label to be clickable. This offers a much better user experience. */}
      <label
        htmlFor={props.id}
        className={twMerge(
          `flex items-center text-sm font-medium leading-6 text-color-body1 cursor-pointer ${
            props.disabled ? 'cursor-not-allowed' : ''
          }`,
        )}
      >
        <input
          id={props.id}
          name={props.name}
          type="checkbox"
          disabled={props.disabled}
          data-testid={props['data-testid']}
          defaultChecked={props.initialValue || false}
          className={twMerge(`h-4 w-4 rounded border-gray-300 text-color-link checked:bg-base-500
            focus:ring-base-500 cursor-pointer mr-100 disabled:bg-gray-400 disabled:hover:bg-gray-400 ${
              props.disabled ? 'cursor-not-allowed' : ''
            }`)}
          {...(props.register ? props.register(props.name) : [])}
        />
        {props.label}
      </label>
    </div>
  </div>
);

type CheckboxProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean>,
> = SingleCheckBoxFieldProps<TFieldValues, TName> | ComptCheckboxGroupFieldProps;

export const ComptCheckboxField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, boolean>,
>(
  props: CheckboxProps<TFieldValues, TName>,
) => {
  const field = (
    <fieldset>
      {'options' in props ? <CheckboxGroupField {...props} /> : <SingleCheckBoxField {...props} />}
    </fieldset>
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      excludeLabelText={true}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      isCheckbox={true}
      className={props.className}
      readOnly={props.readOnly}
    />
  );
};

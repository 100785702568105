export class ComptPaginationController {
  /**
   * For a given page number, determines the number of neighbors to display on the pagination bar.
   *
   * @param currentPage - The current page number.
   * @param totalPages - The total number of pages.
   * @returns The number of neighboring pages to display.
   */
  getNumberOfNeighbors = (currentPage: number, totalPages: number) => {
    if (currentPage === 1 || currentPage === totalPages) {
      return 4;
    }
    if (currentPage === 2 || currentPage === totalPages - 1) {
      return 3;
    }
    return 2;
  };

  /**
   * Determines whether a given page is a neighbor of the current page within a specified range of neighbors.
   *
   * @param page - The page number to check.
   * @param currentPage - The current page number.
   * @param neighbors - The number of neighboring pages to consider on each side of the current page.
   * @returns `true` if the page is a neighbor within the specified range, otherwise `false`.
   */
  isNeighbor = (page: number, currentPage: number, neighbors: number) =>
    page >= currentPage - neighbors && page <= currentPage + neighbors;

  /**
   * Generates an array of page numbers or `...` to display in a pagination component
   * based on the current page, total pages, and the number of neighbors to show.
   *
   * @param currentPage - The current page number.
   * @param totalPages - The total number of pages.
   * @param neighbors - The number of neighboring pages to display on each side of the current page.
   * @returns An array of page numbers and '...' to show in the pagination component.
   */
  pagesToShow = (currentPage: number, totalPages: number, neighbors: number) =>
    Array.from({length: totalPages}, (_, i) => i + 1)
      .map((page) => {
        if (
          [1, 2, totalPages - 1, totalPages].includes(page) ||
          this.isNeighbor(page, currentPage, neighbors)
        ) {
          return page;
        }
        if (page === currentPage - neighbors - 1 || page === currentPage + neighbors + 1) {
          return '...';
        }
        return null;
      })
      .filter(Boolean);
}

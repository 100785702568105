import React, {Dispatch, SetStateAction} from 'react';
import {ComptModal} from '@compt/common/compt-modal/compt-modal';
import {ComptForm} from '@compt/common/forms/compt-form/compt-form';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {useUpdatePasswordMutation} from '@compt/app/services/api/accounts-slice';
import {FieldValues, useForm} from 'react-hook-form';
import {PasswordChange} from '@compt/types/account';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {SecurityHelpers} from '@compt/utils/security-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface ChangePasswordModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChangePasswordModal = (props: ChangePasswordModalProps) => {
  const {open, setOpen} = props;

  const [updatePassword, {isLoading: isUpdating}] = useUpdatePasswordMutation();

  const formMethods = useForm({mode: 'onChange'});
  const {watch, reset} = formMethods;

  const fieldSections = [
    {
      fields: [
        {
          fieldType: ComptTextField,
          secret: true,
          id: 'old_password',
          label: 'Old password',
          placeholder: 'Current Password',
          validation: {
            required: 'Old password is required',
          },
        },
        {
          fieldType: ComptTextField,
          secret: true,
          id: 'password',
          label: 'New password',
          placeholder: 'New Password',
          validation: {
            required: 'New password is required',
            validate: (value: string) => {
              if (!SecurityHelpers.passwordRegex.test(value)) {
                return (
                  'Have at least 8 characters and contain at least one digit (0-9), ' +
                  'letter (A-Z, a-z), and special character (e.g., #$%+?).'
                );
              }
            },
          },
        },
        {
          fieldType: ComptTextField,
          secret: true,
          id: 'confirm_password',
          label: 'Confirm new password',
          placeholder: 'Confirm New Password',
          validation: {
            required: 'Confirm new password is required',
            validate: (value: string) => {
              if (value !== watch('password')) {
                return 'New password and confirm password must match.';
              }
            },
          },
        },
      ],
    },
  ];

  const handleSubmitChangePassword = (form: FieldValues) => {
    const submission = {...form} as PasswordChange;
    updatePassword(submission).then((data) => {
      reset();
      if ('error' in data) {
        triggerCustomToast('error', 'There was a problem updating your password');
        return;
      }
      setOpen(false);
      triggerCustomToast('success', 'Successfully submitted password change');
    });
  };

  const handleCancelChangePassword = () => {
    reset();
    setOpen(false);
  };

  return (
    <ComptModal
      title="Change Password"
      subtitle="Review and edit account details"
      open={open}
      setOpen={() => {}}
    >
      <ComptForm
        fieldSections={fieldSections}
        onSubmit={handleSubmitChangePassword}
        formMethods={formMethods}
      >
        <ComptButton
          buttonType={ComptButtonType.OUTLINED}
          onClick={handleCancelChangePassword}
          disabled={isUpdating}
        >
          Cancel
        </ComptButton>
        <ComptButton
          data-testid="change-password-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          onClick={formMethods.handleSubmit(handleSubmitChangePassword)}
          disabled={isUpdating || !formMethods.formState.isValid}
        >
          Save
        </ComptButton>
      </ComptForm>
    </ComptModal>
  );
};

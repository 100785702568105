import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

export const ComptBreadcrumb = (props: {page: string}) => (
  <p className="label3 text-color-body2 ">{props.page}</p>
);

export const ComptBreadcrumbs = (props: {pages: string[]}) => (
  <div className="h-9 px-6 py-2 border-b border-gray-200 flex">
    {props.pages.map((page) => (
      <>
        <p
          className={`label3 ${
            page !== props.pages[props.pages.length - 1] ? 'text-color-body2' : 'text-color-link'
          }`}
        >
          {page}
        </p>{' '}
        {page !== props.pages[props.pages.length - 1] ? (
          <ComptSvgIcon
            className="flex flex-col justify-center px-3"
            iconName="chevron-right-icon-gray"
            svgProp={{width: '16px', height: '16px'}}
          />
        ) : (
          ''
        )}
      </>
    ))}
  </div>
);

import React, {HTMLAttributes} from 'react';

export enum ComptImageSize {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
  LARGE_ROUND = 'LARGE_ROUND',
  MEDIUM_ROUND = 'MEDIUM_ROUND',
  SMALL_ROUND = 'SMALL_ROUND',
  EXTRA_SMALL_ROUND = 'EXTRA_SMALL_ROUND',
}

export enum ComptImageSizeClass {
  LARGE = 'h-35',
  SMALL = 'h-8',
  LARGE_ROUND = 'rounded-full h-16 w-16',
  MEDIUM_ROUND = 'rounded-full h-14 w-14',
  SMALL_ROUND = 'rounded-full h-8 w-8',
  EXTRA_SMALL_ROUND = 'rounded-full h-4 w-4',
}

export interface ComptImageProps extends HTMLAttributes<HTMLElement> {
  src: string;
  alt?: string;
  size: ComptImageSize;
}

const defaults: ComptImageProps = {
  src: '',
  alt: '',
  size: ComptImageSize.LARGE,
};

const defaultClasses = ['compt-image'];

export const ComptImage = (props: ComptImageProps = defaults) => {
  const classes = [...defaultClasses, props.className].join(' ');
  return (
    <div className={`${classes} ${ComptImageSizeClass[props.size]}`}>
      <img className="h-full object-contain-scale-down" src={props.src} alt={props.alt} />
    </div>
  );
};

ComptImage.defaultProps = defaults;

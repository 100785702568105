import {ComptStyleType} from '@compt/utils/style-helpers';

export enum TeamRosterSyncAttemptStatus {
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  PROCESSED_SUCCESSFULLY = 'PROCESSED_SUCCESSFULLY',
  PROCESSED_WITH_ERRORS = 'PROCESSED_WITH_ERRORS',
}

interface StatusFormatItems {
  statusName: string;
  statusStyle: ComptStyleType;
}

export const syncAttemptStatusFormats: Record<TeamRosterSyncAttemptStatus, StatusFormatItems> = {
  UPLOADING: {statusName: 'Uploading', statusStyle: ComptStyleType.PRIMARY},
  UPLOADED: {statusName: 'Uploaded', statusStyle: ComptStyleType.PRIMARY},
  PROCESSING: {statusName: 'Processing', statusStyle: ComptStyleType.PRIMARY},
  FAILED: {statusName: 'Failed', statusStyle: ComptStyleType.DESTRUCTIVE},
  PROCESSED_SUCCESSFULLY: {
    statusName: 'Processed successfully',
    statusStyle: ComptStyleType.SUCCESS,
  },
  PROCESSED_WITH_ERRORS: {statusName: 'Processed with errors', statusStyle: ComptStyleType.WARNING},
};

export enum TeamRosterSyncResultStatus {
  CREATED = 'Created',
  UPDATED = 'Updated',
  FAILED = 'Failed',
  SKIPPED = 'Skipped',
  UNCHANGED = 'Unchanged',
}
export const syncResultStatusFormats: Record<string, StatusFormatItems> = {
  CREATED: {statusName: 'Created', statusStyle: ComptStyleType.PRIMARY},
  UPDATED: {statusName: 'Updated', statusStyle: ComptStyleType.SUCCESS},
  UNCHANGED: {statusName: 'Unchanged', statusStyle: ComptStyleType.GRAY},
  SKIPPED: {statusName: 'Skipped', statusStyle: ComptStyleType.WARNING},
  FAILED: {statusName: 'Failed', statusStyle: ComptStyleType.DESTRUCTIVE},
};

import {Allotment} from './allotments';
import {DateString} from './common/date-string';
import {ListResultsBase} from './common/list-results';
import {FormattedHistoryItem} from './formatted-history';
import {SupportingDocument} from './supporting-document';
import {ComptStyleType} from '@compt/utils/style-helpers';

export enum ExpenseStatus {
  Open = 1,
  Approved = 2,
  Rejected = 3,
  InReview = 4,
  Processed = 6,
}

interface StatusFormatItems {
  statusName: string;
  statusStyle: ComptStyleType;
}

export const EXPENSE_STATUS_INFO: Record<ExpenseStatus, StatusFormatItems> = {
  1: {
    statusName: 'Open',
    statusStyle: ComptStyleType.WARNING,
  },
  2: {
    statusName: 'Approved',
    statusStyle: ComptStyleType.SUCCESS,
  },
  3: {
    statusName: 'Rejected',
    statusStyle: ComptStyleType.DESTRUCTIVE,
  },
  4: {
    statusName: 'In review',
    statusStyle: ComptStyleType.SECONDARY,
  },
  6: {
    statusName: 'Processed',
    statusStyle: ComptStyleType.PRIMARY,
  },
};

interface StipendExpenseBase {
  status: ExpenseStatus;
  date_of_expense: DateString;
  amount_of_expense: string | number;
  perk_category: number;
  description: string;
}

export interface StipendExpense extends StipendExpenseBase {
  id: number;
  allotments: Allotment[];
  allotment_id: number;
  created_on: DateString;
  amount_claimed: string;
  amount_claimed_formatted?: string;
  formatted_history: FormattedHistoryItem[];
  receipt_image: string;
  user: number;
  vendor_name: string;
  client: string;
  closed_on: DateString | null;
  rejection_reason: string;
  currency: string;
  delegate_name: string;
  payroll_division: string;
  company: number;
  ocr_amount_warning: boolean;
  ocr_vendor_warning: boolean;
  is_taxable?: boolean;
  supporting_documents: SupportingDocument[];
}

export type StipendExpenseListResults = ListResultsBase<StipendExpense>;

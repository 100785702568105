import {produce} from 'immer';
import {
  ChangeValueTypes,
  FilterValueTypes,
  RangeChangeValues,
  RangeFilterValues,
  SelectionChangeValues,
  SelectionOptionValues,
} from './compt-filter-bar.types';

export const isArrayOfStrings = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === 'string');

/**
 * Type checks for Selection Filters.
 */
export const isSelectionValues = (value: FilterValueTypes): value is SelectionOptionValues[] => {
  if (!Array.isArray(value) || isArrayOfStrings(value)) {
    return false;
  }

  const hasSelectionProperties = value.every((item) => 'name' in item && 'id' in item);

  return hasSelectionProperties;
};

export const isSelectionChangeValue = (
  changeValue: ChangeValueTypes,
): changeValue is SelectionChangeValues => {
  if (typeof changeValue === 'string') {
    return false;
  }

  if (!('selection' in changeValue) || !('checked' in changeValue)) {
    return false;
  }

  return true;
};

/**
 * Type checks for Range Filters.
 */
export const isRangeValues = (value: FilterValueTypes): value is RangeFilterValues => {
  if (!value || Array.isArray(value) || isArrayOfStrings(value) || typeof value === 'string') {
    return false;
  }

  const hasRangeProperties = 'lowerBound' in value || 'upperBound' in value;

  return hasRangeProperties;
};

export const isRangeChangeValue = (
  changeValue: ChangeValueTypes,
): changeValue is RangeChangeValues => {
  if (typeof changeValue === 'string') {
    return false;
  }

  if (!('value' in changeValue) || !('isLower' in changeValue)) {
    return false;
  }

  return true;
};

/**
 * Handles value changes for Selection Option Values
 */
export const selectionOptionValuesChangeHandler = (
  draftOfStatus: any,
  changeValue: ChangeValueTypes,
) => {
  if (!isSelectionChangeValue(changeValue)) {
    throw new Error('Change value does not have "selection" or "checked" properties');
  }

  return produce<SelectionOptionValues[]>(draftOfStatus, (draft) => {
    if (changeValue.checked) {
      draft.push(changeValue.selection);
    } else {
      const index = draft.findIndex(
        (value: SelectionOptionValues) => value.id === changeValue.selection.id,
      );
      if (index >= 0) {
        draft.splice(index, 1);
      }
    }
  });
};

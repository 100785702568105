import React, {
  Dispatch,
  Fragment,
  JSX,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import {Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptBreadcrumbs} from '@compt/pages/employee-stipends/components/compt-breadcrumbs/compt-breadcrumbs';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';
import {ComptTooltip} from '../compt-tooltip/compt-tooltip.container';
import {ComptTooltipPlacement} from '../compt-tooltip/compt-tooltip.controller';

interface ComptSidePanelProps {
  open: boolean;
  children: ReactNode;
  className?: string;
  'data-testid'?: string;
}

export const ComptSidePanel = ({
  open,
  children,
  className,
  'data-testid': dataTestId,
}: ComptSidePanelProps) => {
  // When open, disable scrolling for content behind side panel
  useEffect(() => {
    if (open) {
      document.body.style.position = 'fixed';
      document.body.style.top = `-${window.scrollY}px`;
      document.body.style.width = '100%';
      return;
    }

    document.body.style.position = '';
    document.body.style.top = '';
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <div className="relative z-30">
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enterFrom="translate-x-full"
          enterTo="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leaveFrom="translate-x-0"
          leaveTo="translate-x-full"
        >
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div
                className="pointer-events-none fixed inset-y-0 
            right-0 flex sm:max-w-full pl-10 sm:pl-16"
              >
                <div
                  className={`pointer-events-auto w-screen max-w-2xl ${className}`}
                  data-testid={dataTestId}
                >
                  <div
                    className="flex h-full flex-col divide-y
                  divide-gray-300 bg-white shadow-xl"
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Transition.Child>
      </div>
    </Transition.Root>
  );
};

interface SidePanelHeaderProps {
  setOpen: Dispatch<SetStateAction<boolean>> | ((isOpen: boolean) => void);
  title: string;
  subtitle?: string;
  statusPill?: JSX.Element | boolean;
  headerIcon?: {
    id: string;
    labelHidden?: boolean;
    ariaLabel?: string;
  };
  breadCrumbs?: string[];
  formMethods?: any;
}

ComptSidePanel.Header = function SidePanelHeader({
  title,
  setOpen,
  subtitle,
  statusPill,
  headerIcon,
  breadCrumbs,
  formMethods,
}: SidePanelHeaderProps) {
  const {setReceiptUploading} = useContext(ReceiptUploadContext);

  const [isTruncated, setIsTruncated] = useState(false);
  useEffect(() => {
    if (title) {
      setIsTruncated(title.length >= 190);
    }
  }, [title]);

  const tooltipMessage = <p className="text-white">{title}</p>;

  const RenderTitle = () => {
    if (isTruncated) {
      return (
        <ComptTooltip message={tooltipMessage} placement={ComptTooltipPlacement.BOTTOM}>
          <h2 className="truncate w-[380px]">{title}</h2>
        </ComptTooltip>
      );
    } else {
      return <h2>{title}</h2>;
    }
  };

  const RenderTitleWithStatus = () => {
    if (isTruncated) {
      return (
        <div className="flex">
          <ComptTooltip message={tooltipMessage} placement={ComptTooltipPlacement.BOTTOM}>
            <h3 className="text-color-tertiary truncate w-[220px]">{title} /</h3>
          </ComptTooltip>
          {statusPill}
        </div>
      );
    } else {
      return (
        <div className="flex">
          <h3 className="text-color-tertiary">{title} /</h3>
          {statusPill}
        </div>
      );
    }
  };

  return (
    <>
      <div className="px-4 sm:px-6 py-6">
        <div className="flex items-start justify-between">
          <div className="flex items-center justify-start align-middle space-x-4">
            {headerIcon?.id && (
              <div className="border-gray-300 border shadow-sm flex items-center p-3 rounded-md">
                <ComptSvgIcon
                  iconName={headerIcon.id}
                  ariaLabel={headerIcon.ariaLabel}
                  svgProp={{width: '20px', height: '20px'}}
                />
              </div>
            )}
            <div className="flex flex-col">
              {statusPill ? <RenderTitleWithStatus /> : <RenderTitle />}
              {subtitle && <p className="body1 text-color-body2">{subtitle}</p>}
            </div>
          </div>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="relative rounded-md bg-white text-gray-400 hover:text-color-tertiary"
              onClick={() => {
                setOpen(false);
                formMethods?.reset();
                setReceiptUploading(() => false);
              }}
            >
              <span className="absolute -inset-2.5" />
              <span className="sr-only">Close panel</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      {breadCrumbs && <ComptBreadcrumbs pages={breadCrumbs} />}
    </>
  );
};

ComptSidePanel.Content = function SidePanelContent({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return <div className={`relative flex-1 overflow-y-auto ${className}`}>{children}</div>;
};

ComptSidePanel.Footer = function SidePanelFooter({children}: {children: ReactNode}) {
  return (
    <div className="flex flex-shrink-0 justify-start px-4 sm:pr-4 sm:pl-6 py-4">{children}</div>
  );
};

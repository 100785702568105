import DOMPurify from 'dompurify';

/**
 * Global configuration for DOMPurify
 */
export const setupDOMPurify = () => {
  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if ('target' in node && node instanceof HTMLElement) {
      node.setAttribute('target', '_blank');
      node.setAttribute('rel', 'noopener');
    }
  });
};

import React from 'react';
import {Allotment} from '@compt/types/allotments';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import DOMPurify from 'dompurify';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface FullAllotmentDescriptionModalProps {
  allotment: Allotment;
  setOpen: (open: boolean) => void;
  open: boolean;
}
export const FullAllotmentDescriptionModal = ({
  allotment,
  setOpen,
  open,
}: FullAllotmentDescriptionModalProps) => (
  <ComptModalShell
    open={open}
    setOpen={() => setOpen(false)}
    specifiedTopPadding="p-0"
    className="sm:max-w-screen-md sm:p-0"
  >
    <div className="flex justify-between pt-400 pb-300 px-400 border-b">
      <div className="flex items-center">
        <div className="border-gray-300 border shadow-sm flex items-center p-3 rounded-md mr-300">
          <ComptSvgIcon iconName="blue-star-icon" svgProp={{width: '20px', height: '20px'}} />
        </div>
        <h2>{allotment.cycle.name}</h2>
      </div>
      <ComptButton
        buttonType={ComptButtonType.BORDERLESS}
        iconId={ComptButtonIcon.X_STYLEABLE}
        iconClassName="stroke-gray-500"
        onClick={() => setOpen(false)}
      />
    </div>
    <div className="px-400 pt-100 pb-200">
      <p
        className="body3 inner-html text-color-body2"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(allotment.cycle.description),
        }}
      />
    </div>
    <div className="p-400 border-t">
      <ComptButton
        buttonType={ComptButtonType.SECONDARY}
        className="w-full"
        onClick={() => setOpen(false)}
      >
        Close
      </ComptButton>
    </div>
  </ComptModalShell>
);

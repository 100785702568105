import React, {useEffect} from 'react';

// Hooks and methods
import {useFormContext} from 'react-hook-form';
import {GroupTableFieldValues} from './compt-group-table.types';
import {useGroupTableContext} from './compt-group-table.context';

// Components
import {GroupTableDropdown} from './compt-group-table.dropdown';

// Types
import {GroupTypeOption, OptionType} from '@compt/types/user-groups/user-groups';
import {AmountAndCurrencyField} from './group-table-amount-and-currency-field';
import {ProgramFundingRule} from '@compt/types/learning-development/learning-development-program';

interface EveryoneGroupTableProps {
  showTable: boolean;
  amountHeader: string;
  existingOverrides: ProgramFundingRule[] | undefined;
}

export const EveryoneGroupTable = (props: EveryoneGroupTableProps) => {
  const {showTable, existingOverrides} = props;

  const {groupTypeOptions} = useGroupTableContext();

  const formMethods = useFormContext<GroupTableFieldValues>();
  const rowErrors = formMethods.formState.errors.amountOverrides?.[0];

  /**
   * Clear all fields and reset with 'Everyone' option when it is selected
   * Populate existing data for 'Everyone' option if it exists
   * Clear the existing row if a different option is selected
   */
  useEffect(() => {
    // If existing data exists for "Everyone" option, populate amount and currency
    if (
      showTable &&
      existingOverrides &&
      existingOverrides.length === 1 &&
      existingOverrides[0].user_grouping_rule === null
    ) {
      const [everyoneOverrideInfo] = existingOverrides;

      formMethods.reset({
        selectedColumns: [{type: OptionType.EVERYONE, key: 'everyone', header: 'Everyone'}],
        amountOverrides: [
          {
            amount: everyoneOverrideInfo.amount,
            currency: everyoneOverrideInfo.currency,
            overrides: {},
          },
        ],
      });
      return;
    }

    if (showTable) {
      formMethods.reset({
        selectedColumns: [{type: OptionType.EVERYONE, key: 'everyone', header: 'Everyone'}],
        amountOverrides: [{amount: '', currency: 'USD', overrides: {}}],
      });
      return;
    }

    formMethods.resetField('amountOverrides');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTable]);

  function getAvailableOptions(): GroupTypeOption[] {
    const availableOptions = groupTypeOptions.filter(
      (option) => option.type !== OptionType.DATE && option.type !== OptionType.EVERYONE,
    );
    return availableOptions;
  }

  if (!showTable) return null;

  return (
    <div className="border border-l-stroke-divider1 rounded-xl overflow-hidden">
      <div className="flex border-b h-14">
        <GroupTableDropdown
          name={'selectedColumns.0'}
          control={formMethods.control}
          data-testid={'group-type-select-col-everyone'}
          placeholder="Select a group"
          options={getAvailableOptions()}
          getKey={(option) => option.key}
          getDisplayText={(option) => option?.header}
          textClassName="label4"
        />
        <div className="w-full">
          <p className="label4 h-14 flex items-center pl-400">{props.amountHeader}</p>
        </div>
        <div className="flex items-center px-400 border-l">
          <div className="w-6"></div>
        </div>
      </div>
      <div className="flex border-b min-h-[72px]">
        <div className="flex w-full border-r items-center">
          <p className="body3 text-color-body1 ml-400">Everyone</p>
        </div>
        <AmountAndCurrencyField errors={rowErrors} rowNum={0} />
        <div className="flex items-center px-400 border-l">
          <div className="w-6" />
        </div>
      </div>
    </div>
  );
};

import {comptApiSlice} from './api-slice';
import {FAQ} from '@compt/types/faq';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEmployeeFaq: builder.query<FAQ[], void>({
      query: () => 'companies/faqs/',
    }),
  }),
});

export const {useGetEmployeeFaqQuery} = extendedComptApiSlice;

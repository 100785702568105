import {comptApiSlice} from '@compt/app/services/api/api-slice';
import {AdjustmentTaskResult, AdjustmentTaskResultError} from '@compt/types/common/async-results';

export type AsyncResult = AdjustmentTaskResult | AdjustmentTaskResultError | null;

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAsyncResults: builder.query<AsyncResult, string>({
      query: (taskId) => `/poll_async_results/${taskId}`,
    }),
  }),
});

export const {useGetAsyncResultsQuery} = extendedComptApiSlice;

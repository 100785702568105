import React from 'react';
import {FormattedHistoryItem, SegmentDiff, SegmentType} from '@compt/types/formatted-history';
import {DateTime} from 'luxon';
import {PerkCategory} from '@compt/types/perk-category';
import {ComptInitialsAvatar} from '@compt/common/compt-initials-avatar/compt-initials-avatar';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptStyleType} from '@compt/utils/style-helpers';
import {getPillType} from '@compt/utils/status-pills-helpers';

interface ClaimEditHistoryProps {
  formattedHistory: FormattedHistoryItem[];
  allPerkCategories: PerkCategory[];
  rejectionReason?: string;
}

export const ClaimEditHistory = (props: ClaimEditHistoryProps) => {
  if (props.formattedHistory.length === 0) {
    return null;
  }

  const reversedEditHistory = props.formattedHistory.slice().reverse();

  return (
    <div className="mt-8">
      <h2 className="mb-2">Expense activity</h2>
      <div className="h-28 overflow-y">
        {reversedEditHistory.map((item, i) => {
          const isLastItem = i === reversedEditHistory.length - 1;
          return (
            <EditHistoryItem
              key={i}
              historyItem={item}
              isLast={isLastItem}
              allPerkCategories={props.allPerkCategories}
              rejectionReason={props.rejectionReason}
            />
          );
        })}
      </div>
    </div>
  );
};

interface ClaimHistoryItem {
  historyItem: FormattedHistoryItem;
  isLast?: boolean;
  allPerkCategories: PerkCategory[];
  rejectionReason?: string;
}

const EditHistoryItem = (props: ClaimHistoryItem) => {
  const {historyItem} = props;

  function formatDate(givenDate: string) {
    const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return DateTime.fromISO(givenDate)
      .setZone(browserTimeZone)
      .toFormat("MMM d, yyyy 'at' h:mm a ZZZZ");
  }

  function getPerkCategory(perkCategoryId: string) {
    const perkIdAsNum = parseInt(perkCategoryId);
    return props.allPerkCategories?.find((category) => category.id === perkIdAsNum);
  }

  function getSegmentText(segmentType: SegmentType, segment_diff: SegmentDiff[]) {
    switch (segmentType) {
      case SegmentType.SUBMITTED:
        return <p className="body3 text-color-body1">Submitted expense</p>;

      case SegmentType.APPROVED:
        return (
          <p className="body3 text-color-body1">
            Expense updated to <ComptPill pillType={ComptStyleType.SUCCESS}>Approved</ComptPill>
          </p>
        );

      case SegmentType.AUTO_APPROVED:
        return (
          <p className="body3 text-color-body1">
            Expense updated to{' '}
            <ComptPill pillType={ComptStyleType.SUCCESS}>Auto-approved</ComptPill>
          </p>
        );

      case SegmentType.REJECTED:
        return (
          <>
            <p className="body3 text-color-body1 mb-100">
              Expense updated to{' '}
              <ComptPill pillType={ComptStyleType.DESTRUCTIVE}>Rejected</ComptPill>
            </p>
            <p className="body3 text-color-error">{props.rejectionReason}</p>
          </>
        );

      case SegmentType.PROCESSED:
        return (
          <p className="body3 text-color-body1">
            Expense updated to <ComptPill pillType={ComptStyleType.PRIMARY}>Processed</ComptPill>
          </p>
        );
    }

    if (
      segmentType !== SegmentType.UPDATED_BY_REVIEWER &&
      segmentType !== SegmentType.UPDATED_BY_USER &&
      segmentType !== SegmentType.UNDO_PAYROLL
    ) {
      return null;
    }

    return (
      <ul className="pl-5">
        {segment_diff.map((diff, i) => {
          const fieldOldValue =
            diff.field_name === 'perk_category'
              ? getPerkCategory(diff.field_old_value)?.name
              : diff.field_old_value;

          const fieldNewValue =
            diff.field_name === 'perk_category'
              ? getPerkCategory(diff.field_new_value)?.name
              : diff.field_new_value;

          if (diff.field_name === 'receipt_image') {
            return (
              <li key={i} className="body3 text-color-body1" style={{listStyleType: 'disc'}}>
                Updated receipt image
              </li>
            );
          }

          if (diff.field_name === 'status') {
            return (
              <li key={i} className="body3 text-color-body1" style={{listStyleType: 'disc'}}>
                Status changed from{' '}
                <ComptPill pillType={getPillType(fieldOldValue)}>{fieldOldValue}</ComptPill> to{' '}
                <ComptPill pillType={getPillType(fieldNewValue)}>{fieldNewValue}</ComptPill>
              </li>
            );
          }

          return (
            <li key={i} className="body3 text-color-body1" style={{listStyleType: 'disc'}}>
              {diff.field_verbose_name} changed from &quot;{fieldOldValue}&quot; to &quot;
              {fieldNewValue}&quot;
            </li>
          );
        })}
      </ul>
    );
  }

  // Hide history item if no changes were made on save
  if (
    (historyItem.segment_type === SegmentType.UPDATED_BY_REVIEWER ||
      historyItem.segment_type === SegmentType.UPDATED_BY_USER) &&
    historyItem.segment_diff.length === 0
  ) {
    return null;
  }

  return (
    <div
      className={`flex space-x-4 pt-4 pb-4 ${props.isLast ? 'pb-900 sm:pb-[200px]' : 'border-b'}`}
    >
      <ComptInitialsAvatar className="w-[48px] h-[48px] border-[2px] min-w-[48px]">
        <p className="heading3 text-brand-700">
          {historyItem.actor_first_name[0]}
          {historyItem.actor_last_name[0]}
        </p>
      </ComptInitialsAvatar>
      <div>
        <p className="body4 text-color-body2">
          <b className="label4 text-color-heading">
            {historyItem.actor_first_name} {historyItem.actor_last_name}{' '}
          </b>
          {formatDate(historyItem.as_of_date)}
        </p>
        {getSegmentText(historyItem.segment_type, historyItem.segment_diff)}
      </div>
    </div>
  );
};

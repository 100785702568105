/* eslint-disable react-hooks/exhaustive-deps */
import React, {ReactNode, useEffect, useRef} from 'react';

// Hooks and methods
import {FormProvider, useFieldArray} from 'react-hook-form';
import {GroupTableContext} from './compt-group-table.context';
import {
  findDuplicateRowIndices,
  getGroupOption,
  getGroupTypeOption,
} from './compt-group-table.helpers';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {GroupTableRow} from './compt-group-table.row';
import {EveryoneGroupTable} from './compt-everyone-group-table';

// Types
import {
  GroupType,
  GroupTypeOption,
  OptionType,
  UserGroup,
} from '@compt/types/user-groups/user-groups';
import {FormAmountOverride} from './compt-group-table.types';
import {ProgramFundingRule} from '@compt/types/learning-development/learning-development-program';

const DEFAULT_MAX_COLUMNS = 3;

export interface GroupTableProps {
  children: ReactNode;
  userGroups: UserGroup[];
  formMethods: any;
  groupTypeOptions: GroupTypeOption[];
  amountHeader: string;
  existingMultiGroupKeys?: GroupType[];
  existingAmountOverrides?: ProgramFundingRule[];
  maxNumOfColumns?: number;
  initializeEveryoneOption?: boolean;
}

export const ComptGroupTable = (props: GroupTableProps) => {
  const maxNumOfColumns = props.maxNumOfColumns ?? DEFAULT_MAX_COLUMNS;
  const {formMethods, existingMultiGroupKeys, existingAmountOverrides, groupTypeOptions} = props;
  const {
    formState: {errors},
    watch,
  } = formMethods;

  const columnFieldMethods = useFieldArray({
    name: 'selectedColumns',
    control: formMethods.control,
    shouldUnregister: true,
  });
  const {fields: columnFields, append: appendColumn} = columnFieldMethods;

  const rowFieldMethods = useFieldArray({
    name: 'amountOverrides',
    control: formMethods.control,
    shouldUnregister: true,
  });
  const {fields: rowFields, append: appendRow, remove: removeRows} = rowFieldMethods;

  const rowsInitiallyPopulated = useRef(false);

  const selectedColumns = watch('selectedColumns');

  const isGroupTypeSelected = selectedColumns?.some((column: GroupTypeOption) => !!column?.key);

  const isEveryoneOptionSelected = selectedColumns?.some(
    (column: GroupTypeOption) => column?.key === 'everyone',
  );

  // Clear all rows if no group types are defined
  useEffect(() => {
    if (!isGroupTypeSelected) {
      removeRows();
      return;
    }
  }, [isGroupTypeSelected, removeRows]);

  /**
   * Initializes column headers depending on existing data
   * and decides whether "Everyone" should be shown on first render
   */
  useEffect(() => {
    if (existingMultiGroupKeys && existingMultiGroupKeys.length > 0) {
      const selectedGroupOptions = existingMultiGroupKeys.map((groupType) =>
        getGroupTypeOption(groupType, props.groupTypeOptions),
      );
      formMethods.reset({selectedColumns: selectedGroupOptions});

      return;
    }

    if (props.initializeEveryoneOption) {
      appendColumn({key: 'everyone', header: 'Everyone', type: OptionType.EVERYONE});
      return;
    }

    appendColumn({key: null, header: null, type: null});
  }, [existingMultiGroupKeys]);

  /* 
    If amount overrides exist, add a row for each override. 
    Column fields need to be initialized with multi-group keys in order to assign existing data to generated column IDs.
    If the rows have already been populated, does not trigger again.
  */
  useEffect(() => {
    if (!existingAmountOverrides || existingAmountOverrides.length === 0) return;
    if (columnFields.length !== existingMultiGroupKeys?.length || rowsInitiallyPopulated.current)
      return;

    // Avoid generating rows if "Everyone" option is initially populated
    if (
      existingMultiGroupKeys?.length === 0 &&
      existingAmountOverrides.length === 1 &&
      existingAmountOverrides[0].user_grouping_rule === null
    ) {
      rowsInitiallyPopulated.current = true;
      return;
    }

    const columnIdMap: {[key: GroupType]: string} = {};
    columnFields.forEach((field: any) => (columnIdMap[field.key] = field.id));

    existingAmountOverrides.forEach((override) => {
      const userProperties = override.user_grouping_rule.user_properties;

      const existingRow: FormAmountOverride = {
        amount: override.amount,
        currency: override.currency,
        overrides: {},
      };

      Object.keys(userProperties).forEach((key) => {
        const columnId = columnIdMap[key];
        const selectedGroupOption = getGroupOption(userProperties[key], key, props.userGroups);
        if (!columnId || !selectedGroupOption) {
          return console.error('Column ID or group option does not exist for given value');
        }

        existingRow.overrides[columnId] = selectedGroupOption;
      });

      appendRow(existingRow);
    });

    rowsInitiallyPopulated.current = true;
  }, [existingAmountOverrides, columnFields]);

  // Watch and validate duplicate override combinations do not exist
  useEffect(() => {
    const subscription = watch(() => {
      const amountOverrides = formMethods.getValues('amountOverrides');
      if (!amountOverrides || amountOverrides.length === 0) return;

      const duplicatesSet = findDuplicateRowIndices(amountOverrides);

      amountOverrides.forEach((_: unknown, rowIndex: unknown) => {
        if (typeof rowIndex !== 'number') return;

        if (duplicatesSet.has(rowIndex)) {
          formMethods.setError(`amountOverrides.${rowIndex}.overrides`, {
            message: 'Overlapping rules',
          });
        } else {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Unreachable code error
          const amountOverridesErrors = formMethods.formState.errors.amountOverrides?.[rowIndex];
          const rowHasDuplicateError = amountOverridesErrors?.overrides?.message;
          rowHasDuplicateError && formMethods.clearErrors(`amountOverrides.${rowIndex}.overrides`);
        }
      });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  function addRow() {
    const newRow = {amount: null, currency: 'USD', overrides: {}};
    appendRow(newRow);
  }

  return (
    <GroupTableContext.Provider
      value={{maxNumOfColumns, columnFieldMethods, rowFieldMethods, groupTypeOptions}}
    >
      <FormProvider {...formMethods}>
        <EveryoneGroupTable
          showTable={isEveryoneOptionSelected}
          amountHeader={props.amountHeader}
          existingOverrides={props.existingAmountOverrides}
        />
        {!isEveryoneOptionSelected && (
          <div
            data-testid="compt-group-table"
            className="border-2 border-l-stroke-divider1 rounded-xl overflow-hidden"
          >
            <>{props.children}</>
            {rowFields.map((rowField, rowIndex) => (
              <GroupTableRow
                key={rowField.id}
                rowNum={rowIndex}
                userGroups={props.userGroups}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore: Unreachable code error
                errors={errors.amountOverrides?.[rowIndex]}
              />
            ))}
            {isGroupTypeSelected && (
              <div className="flex h-[72px] px-400 items-center">
                <button className="flex items-center h-10 pr-5" onClick={addRow}>
                  <ComptSvgIcon
                    iconName="plus-icon-blue"
                    svgProp={{width: '16px', height: '16px'}}
                  />
                  <p className="body3 text-color-body1 ml-100">Add group</p>
                </button>
              </div>
            )}
            {!isGroupTypeSelected && <div className="flex border-b h-[72px]" />}
          </div>
        )}
      </FormProvider>
    </GroupTableContext.Provider>
  );
};

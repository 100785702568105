import {useEffect, useRef, useState} from 'react';

export const useScrollOutsideContainer = () => {
  const [contentHover, setContentHover] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = (event: any) => {
      if (contentRef !== null) {
        if (contentHover === false) {
          contentRef.current?.scrollTo({
            top: (contentRef.current.scrollTop += event.deltaY),
            behavior: 'smooth',
          });
        }
      }
    };

    window.addEventListener('wheel', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [contentHover]);

  return {
    contentRef,
    setContentHover,
  };
};

import React from 'react';
import {ComptLoadingAnimation} from './compt-loading-animation';
import {twMerge} from 'tailwind-merge';

interface ComptLoadingProps {
  isLoading: boolean;
  dependentData?: boolean;
  noDataText?: React.ReactElement | null;
  children?: React.ReactNode;
  loadingText?: string;
  className?: string;
  textClassName?: string;
}
export const ComptLoadingIndicator = ({
  isLoading,
  dependentData = true,
  noDataText = null,
  children = null,
  loadingText,
  className,
  textClassName,
}: ComptLoadingProps): React.ReactElement | null =>
  isLoading ? (
    <div className={twMerge(`h-3/4 w-full flex flex-col justify-center items-center ${className}`)}>
      <ComptLoadingAnimation size={150} />
      <p className={twMerge(`label3 text-color-body1 -mt-8 ${textClassName}`)}>
        {' '}
        {loadingText || 'Loading...'}
      </p>
    </div>
  ) : !dependentData ? (
    noDataText
  ) : (
    <>{children}</>
  );

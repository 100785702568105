export enum EligibilityBreakdownOption {
  SHARED_BUDGET = 'SHARED_BUDGET',
  INDIVIDUAL_BUDGET = 'INDIVIDUAL_BUDGET',
  CASE_BY_CASE = 'CASE_BY_CASE',
}

export enum BudgetDistributionOption {
  UPFRONT = 'UPFRONT',
  UPFRONT_WITH_PRORATING = 'UPFRONT_WITH_PRORATING',
  MONTHLY_ACCRUAL = 'MONTHLY_ACCRUAL',
}

import React, {useEffect, useState} from 'react';

type CallableDefaultValue<T> = () => T;

export function getLocalStorageObjectNoDefault<T>(key: string): T | null {
  try {
    const localStorageValue = localStorage.getItem(key);
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    }
  } catch (e) {
    console.error('Error getting object from localStorage', e);
  }

  return null;
}

export function getLocalStorageObject<T>(
  key: string,
  defaultValue: T | CallableDefaultValue<T>,
): T {
  const getDefaultValue = (): T => {
    if (typeof defaultValue === 'function') {
      return (defaultValue as CallableDefaultValue<T>)();
    }

    return defaultValue;
  };

  return getLocalStorageObjectNoDefault(key) || getDefaultValue();
}

export const setLocalStorageObject = <T>(key: string, object: T | null) => {
  try {
    localStorage.setItem(key, JSON.stringify(object));
  } catch (e) {
    console.error('Error setting object to localStorage', e);
    throw e;
  }
};

export const generateLocalStorageKey = (domain: string, key: string) => `${domain}_${key}`;

/**
 * Cacheable state.
 *
 * Similar to useState but will save the state with the given key in local storage and load it.
 *
 * @param storageKey the key to save the state in local storage as
 * @param defaultState the default state
 * This is a helper for cases in which this is configurable by the consumer.
 * @param disableCache - if true, this will skip caching and behave just as useState.
 * @param disabledProperties - an array of the properties to be disable skip for the cached object.
 */
export function useCacheableObjectState<T>(
  storageKey: string,
  defaultState: T | CallableDefaultValue<T>,
  disableCache = false,
  disabledProperties?: string[],
): [T, React.Dispatch<React.SetStateAction<T>>] {
  let _defaultState = defaultState;

  if (!disableCache) {
    // If the cache is not disabled, get value from localStorage
    _defaultState = () => getLocalStorageObject<T>(storageKey, defaultState);
  }

  const [cacheableObject, setCacheableObject] = useState(_defaultState);

  useEffect(() => {
    if (!disableCache) {
      // Remove disabled properties from the cached object
      const filteredObject = {...cacheableObject} as any;
      disabledProperties?.forEach((prop: string) => {
        delete filteredObject[prop];
      });
      // Only save the filtered object to local storage if cache is not disabled.
      setLocalStorageObject(storageKey, filteredObject);
    }
  }, [storageKey, cacheableObject, disableCache, disabledProperties]);

  return [cacheableObject, setCacheableObject];
}

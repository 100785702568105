import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {BusinessExpenseStatus} from '@compt/types/business-expenses/business-expense-statuses';

/**
 * Given an expense, return the correct approval status to set it to depending on its current status.
 *
 * @param expense the business expense
 * @param isFinancialReviewer whether the user has the financial reviewer role
 */
export const getCorrectApprovalStatus = (
  expense: BusinessExpense,
  isFinancialReviewer = false,
): BusinessExpenseStatus => {
  if (isFinancialReviewer && expense.status === BusinessExpenseStatus.APPROVED_BY_MANAGER) {
    return BusinessExpenseStatus.APPROVED;
  }

  return BusinessExpenseStatus.APPROVED_BY_MANAGER;
};

import React from 'react';
import {ComptFormField} from '../compt-form-field/compt-form-field';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {FieldPathByValue, FieldValues} from 'react-hook-form';

export interface ComptRadioFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  options: {id: string; label: string; description?: string}[];
  'data-testid'?: string;
  readOnly?: boolean;
}

export const ComptRadioField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptRadioFieldProps<TFieldValues, TName>,
) => {
  const rhfField =
    props.register && props.validation
      ? props.register(props.name, props.validation)
      : props.register
      ? props.register(props.name)
      : null;

  const field = (
    <fieldset>
      <div className="max-w-lg mb-200">
        <div className="space-y-200 mt-3">
          {props.options &&
            props.options.map((option) => (
              <div key={option.id} className="flex items-start gap-x-3">
                <input
                  id={option.id}
                  name={props.name}
                  value={option.id}
                  type="radio"
                  data-testid={props['data-testid']}
                  className="h-4 w-4 text-color-link mt-1"
                  {...(rhfField || [])}
                  disabled={props.readOnly}
                />
                <div>
                  <label htmlFor="push-everything" className="label3 text-color-body1">
                    {option.label}
                  </label>
                  {option.description && (
                    <p className="body3 text-color-body2">{option.description}</p>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </fieldset>
  );

  return (
    <ComptFormField
      name={props.name}
      id={props.id}
      field={field}
      label={props.label}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly ?? false}
    />
  );
};

import React, {useMemo, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAdminBusinessExpenseReportListQuery} from '@compt/app/services/api/admin-business-expenses-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';

// Hooks and methods
import {useNavigate} from 'react-router-dom';
import {useDebounce} from '@uidotdev/usehooks';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {AdminBEReportsPageController} from './admin-business-expense-reports-page.controller';

// Components
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';

// Types
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE} from '@compt/constants';
import {USER_ROLES} from '@compt/utils/user-roles-helper';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';

const controller = AdminBEReportsPageController;

export const AdminBusinessExpenseReportsPage = () => {
  const {updateQueryParamsOnValuesChanged} = controller;
  const navigate = useNavigate();

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const hasRequiredRole = useHasRequiredRole([
    USER_ROLES.financeReviewer,
    USER_ROLES.employeeManager,
  ]);

  const [reportsQueryValues, setReportsQueryValues] = useState<FormattedFilterObject | undefined>();
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const debouncedQueryValues = useDebounce(reportsQueryValues, 300);

  const businessExpenseReportListQuery = useGetAdminBusinessExpenseReportListQuery(
    {companyId, params: debouncedQueryValues},
    {skip: !companyId},
  );

  const onActionClicked = (report: BusinessExpenseReport) => {
    navigate(`/review-business-expenses/${report.id}`);
  };

  const initialFilterValues = controller.getInitialFilterValues();
  const filterConfiguration = controller.getFilterConfiguration();
  const columnDefinition = controller.getColumnDefinition(onActionClicked);

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No expense reports were found with the current filters.'
        : 'No expense reports have been added yet',
    [filtersApplied],
  );

  if (!hasRequiredRole) {
    return <Error404Page />;
  }

  return (
    <ComptPage
      title="Review business expense reports"
      subtitle="Approve or reject business expense reports"
      className="h-dvh"
      includeBottomHR={false}
    >
      <ComptLoadingIndicator
        isLoading={companyQuery.isLoading || businessExpenseReportListQuery.isLoading}
        className="pt-72"
      >
        {companyQuery.data && businessExpenseReportListQuery.data && (
          <div className="mt-300 sm:mt-000">
            <ComptTableContextProvider>
              <ComptTable
                company={companyQuery.data}
                tableId="admin-business-expense-reports-table"
                className="w-full"
                data={businessExpenseReportListQuery.data?.results ?? []}
                dataLoading={companyQuery.isFetching || businessExpenseReportListQuery.isFetching}
                noDataTitleText={noDataTitleText}
                allowShowHide={false}
                columnDefinition={columnDefinition}
                filterConfiguration={filterConfiguration}
                initialFilterValues={initialFilterValues}
                allowPagination={true}
                totalCount={businessExpenseReportListQuery.data?.count}
                itemsPerPage={MAX_ADMIN_BUSINESS_EXPENSE_REPORTS_PER_PAGE}
                onRowClicked={(row) => {
                  navigate(`/review-business-expenses/${row.id}`);
                }}
                stickyLastColumn
                onChangeQueryValues={(filterValues, pagination, ordering) =>
                  updateQueryParamsOnValuesChanged(
                    companyId,
                    setReportsQueryValues,
                    setFiltersApplied,
                    pagination,
                    filterValues,
                    ordering,
                  )
                }
              />
            </ComptTableContextProvider>
          </div>
        )}
      </ComptLoadingIndicator>
    </ComptPage>
  );
};

import React from 'react';
import toast, {Toast} from 'react-hot-toast';
import DOMPurify from 'dompurify';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

type ComptToastType = 'success' | 'error' | 'warn';

export const triggerCustomToast = (
  type: ComptToastType,
  title: string,
  body?: string,
  innerHtml?: string,
  secondaryButtonText?: string,
  secondaryButtonCallback?: () => void,
  primaryButtonCallback?: () => void,
  duration?: number,
) => {
  const toastDuration = duration ?? (type === 'success' ? 3000 : 5000);

  return (
    <>
      {toast.custom(
        (t) => (
          <ComptToaster
            type={type}
            toaster={t}
            title={title}
            body={body}
            innerHtml={innerHtml}
            secondaryButtonText={secondaryButtonText}
            secondaryButtonCallback={() => {
              if (secondaryButtonCallback) {
                secondaryButtonCallback();
              }
            }}
            primaryButtonCallback={() => {
              if (primaryButtonCallback) {
                primaryButtonCallback();
              }
            }}
          />
        ),
        {duration: toastDuration},
      )}
    </>
  );
};

interface ComptToasterProps {
  type: ComptToastType;
  toaster: Toast;
  title: string;
  body?: string;
  innerHtml?: string;
  secondaryButtonText?: string;
  secondaryButtonCallback?: () => void;
  primaryButtonCallback?: () => void;
}

export const ComptToaster = (props: ComptToasterProps) => {
  const {
    type,
    toaster,
    title,
    body,
    innerHtml,
    secondaryButtonText,
    secondaryButtonCallback,
    primaryButtonCallback,
  } = props;
  const isMobileView = useIsMobileView();

  let icon;
  let iconBackgroundClass = 'bg-green-050';
  let borderClasses = 'border-green-300';

  switch (type) {
    case 'error':
      icon = (
        <ComptSvgIcon
          iconName="alert-circle-warning-icon"
          className="px-2.5"
          ariaLabel="alert-circle-warning"
        />
      );
      borderClasses = 'border-red-300';
      iconBackgroundClass = 'bg-red-050';
      break;
    case 'success':
      icon = (
        <ComptSvgIcon
          iconName="check-verified-icon"
          className="px-2.5"
          ariaLabel="check-verified"
        />
      );
      break;
    default:
      // Warn
      icon = (
        <ComptSvgIcon
          iconName="alert-triangle-warning-icon"
          className="px-2.5"
          ariaLabel="alert-triangle-warning"
        />
      );
      borderClasses = 'border-yellow-300';
      iconBackgroundClass = 'bg-yellow-050';
      break;
  }

  return (
    <div
      className={`flex ${isMobileView ? 'flex-col gap-y-4' : 'flex-row gap-x-5 max-w-lg'}
            border ${borderClasses} bg-gray-000 rounded-xl shadow-lg p-4 z-10`}
    >
      <div
        className={`flex justify-center items-center border ${borderClasses} rounded-lg
             ${iconBackgroundClass} h-10 ${isMobileView ? 'w-10' : ''}`}
      >
        {icon}
      </div>
      <div className="grid gap-y-4">
        <div>
          <p className="label2 text-color-heading mb-1">{title}</p>
          {body && <p className="body2">{body}</p>}
          {innerHtml && (
            <p
              className="body2 inner-html"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(innerHtml),
              }}
            />
          )}
        </div>
        <div className="flex gap-x-3">
          <button
            type="button"
            style={{pointerEvents: 'all'}}
            onClick={() => {
              if (typeof primaryButtonCallback === 'function') {
                primaryButtonCallback();
              }
              toast.remove(toaster.id);
            }}
            className="body1"
          >
            Dismiss
          </button>
          {secondaryButtonText && secondaryButtonCallback && (
            <button
              type="button"
              className="body1 text-color-link"
              style={{pointerEvents: 'all'}}
              onClick={() => {
                secondaryButtonCallback();
                toast.remove(toaster.id);
              }}
            >
              <p className="body1 text-color-link">{secondaryButtonText}</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {TeamRosterSyncResult} from '@compt/types/team-roster-syncs/team-roster-syncs';
import {TeamRosterSyncStatus} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/components/team-roster-sync-status';

interface SyncResultSidePanelProps {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  selectedSyncResult: TeamRosterSyncResult | null;
  setSelectedSyncResult: Dispatch<SetStateAction<TeamRosterSyncResult | null>>;
  syncResultListResults: TeamRosterSyncResult[];
}

export const SyncResultSidePanel = (props: SyncResultSidePanelProps) => {
  const {selectedSyncResult, syncResultListResults, setSelectedSyncResult} = props;

  const currentSelectedSyncResultIndex = syncResultListResults.findIndex(
    (syncResult) => syncResult.id === selectedSyncResult?.id,
  );

  const previousSyncResult = syncResultListResults[currentSelectedSyncResultIndex - 1];
  const nextSyncResult = syncResultListResults[currentSelectedSyncResultIndex + 1];

  const modalTitle = `Row ${selectedSyncResult?.instance_row_number}`;

  /**
   * Keep sync result side pane in sync with the list of sync results.
   */
  useEffect(() => {
    if (!selectedSyncResult) {
      return;
    }

    const selectedSyncResultInList = syncResultListResults.find(
      (syncResult) => syncResult.id === selectedSyncResult.id,
    );

    if (!selectedSyncResultInList) {
      setSelectedSyncResult(null);
    } else {
      setSelectedSyncResult(selectedSyncResultInList as TeamRosterSyncResult);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [syncResultListResults, setSelectedSyncResult]);

  function handlePreviousOrNextButton(syncResultToNavigate: TeamRosterSyncResult) {
    if (!syncResultToNavigate) return;

    setSelectedSyncResult(() => syncResultToNavigate);
  }

  const displayFields = [
    {label: 'First name', value: selectedSyncResult?.first_name},
    {label: 'Last name', value: selectedSyncResult?.last_name},
    {label: 'Email', value: selectedSyncResult?.email},
    {label: 'EID', value: selectedSyncResult?.eid},
    {label: 'Status', value: <TeamRosterSyncStatus status={selectedSyncResult?.status} />},
    {
      label: 'Errors',
      value: (
        <p className="body3 compt-text--medium text-color-error">{selectedSyncResult?.errors}</p>
      ),
    },
  ];
  return (
    <ComptSidePanel open={props.open} className="max-w-[480px]">
      <ComptSidePanel.Header
        title={modalTitle}
        setOpen={() => {
          props.setOpen(false);
        }}
      />
      <ComptSidePanel.Content>
        <div className="mt-3 px-6">
          <dl className="divide-y divide-gray-100">
            {displayFields.map((field) => (
              <div key={field.label} className="px-4 py-6 sm:grid sm:grid-cols-5 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{field.label}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-4 sm:mt-0">
                  {field.value}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex flex-col sm:flex-row sm:justify-between items-center w-full gap-2">
          <div className="flex space-x-3">
            <ComptButton
              buttonType={ComptButtonType.OUTLINED}
              disabled={!previousSyncResult}
              onClick={() => handlePreviousOrNextButton(previousSyncResult)}
            >
              Previous
            </ComptButton>
            <ComptButton
              buttonType={ComptButtonType.OUTLINED}
              disabled={!nextSyncResult}
              onClick={() => handlePreviousOrNextButton(nextSyncResult)}
            >
              Next
            </ComptButton>
          </div>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

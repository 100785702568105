import React from 'react';
import {ReviewingUser} from '@compt/types/learning-development/learning-development-program';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

export interface AssignedUsersProps {
  users: ReviewingUser[];
  onRemove?: (user: ReviewingUser) => void;
  readOnly: boolean;
}

export const AssignedUsers = ({users, onRemove, readOnly}: AssignedUsersProps) => (
  <ul className="flex flex-row gap-x-2">
    {users.map((recipient) => (
      <li key={recipient.email} className="flex items-center justify-between gap-x-6 py-5">
        <div className="flex min-w-0 gap-x-4">
          <div className="min-w-0 flex-auto">
            <ComptPill
              pillType="gray"
              pillSize={ComptPillSize.MEDIUM}
              className="gap-x-2 px-2 py-1"
            >
              <p className="body3 text-black">{recipient.full_name}</p>
              {onRemove && !readOnly && (
                <button type="button" onClick={() => onRemove(recipient)}>
                  <ComptSvgIcon iconName="x-icon" svgProp={{width: '16px', height: '16px'}} />
                </button>
              )}
            </ComptPill>
          </div>
        </div>
      </li>
    ))}
  </ul>
);

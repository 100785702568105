import {ComptStyleType} from '@compt/utils/style-helpers';
import {ExpenseStatus} from '@compt/types/stipend-expense';

export function getPillType(status: ExpenseStatus | string | undefined) {
  switch (status) {
    case ExpenseStatus.Approved:
    case 'Approved':
      return ComptStyleType.SUCCESS;

    case ExpenseStatus.Processed:
    case 'Processed':
      return ComptStyleType.PRIMARY;

    case ExpenseStatus.Rejected:
    case 'Rejected':
      return ComptStyleType.DESTRUCTIVE;

    case ExpenseStatus.Open:
    case 'Open':
      return ComptStyleType.WARNING;

    case ExpenseStatus.InReview:
    case 'In review':
      return ComptStyleType.SECONDARY;

    default:
      return ComptStyleType.GRAY;
  }
}

import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';

// Components
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const HowDoesItWorkModal = (props: ModalProps) => {
  const isMobileView = useIsMobileView();

  return (
    <ComptModalShell
      open={props.open}
      setOpen={() => props.setOpen(false)}
      specifiedTopPadding="p-0"
      className="sm:max-w-screen-md sm:p-0"
    >
      <div className="flex justify-between pt-400 pb-300 px-400 sm:border-b">
        <div className="flex items-center">
          <div
            className="border border-brand-500 bg-brand-50 rounded-lg shadow-sm flex
      items-center p-3 mr-300"
          >
            <ComptSvgIcon
              iconName="alert-circle-blue-icon"
              svgProp={{width: '20px', height: '20px'}}
            />
          </div>
          {!isMobileView && <h2>How does it work?</h2>}
        </div>
        <ComptButton
          buttonType={ComptButtonType.BORDERLESS}
          iconId={ComptButtonIcon.X_STYLEABLE}
          iconClassName="stroke-gray-500"
          onClick={() => props.setOpen(false)}
        />
      </div>
      {isMobileView && <h2 className="px-400">How does it work?</h2>}
      <div className="px-400 sm:pt-100 pb-200">
        <p className="body1 sm:mt-4 mb-4">
          You can submit a request to receive funds for something that would benefit your career
          growth!
        </p>
        <p className="body1 mb-4">
          Start by filling out a request for something that fits within program guidelines. Your
          request then enters the approval process. Once approved, you have the green light to make
          your purchase, complete your course/class/education and then submit a form for
          reimbursement. After your reimbursement is approved, it will be directly added in a future
          paycheck!
        </p>
      </div>
      <div className="p-400 border-t">
        <ComptButton
          buttonType={ComptButtonType.SECONDARY}
          className="w-full"
          onClick={() => props.setOpen(false)}
        >
          Done
        </ComptButton>
      </div>
    </ComptModalShell>
  );
};

export const S3_AV_STATUS_HEADER_KEY = 'x-amz-meta-av-status';

export enum AVScanStatus {
  SCANNING = 'SCANNING',
  CLEAN = 'CLEAN',
  INFECTED = 'INFECTED',
  NOT_STARTED = 'NOT_STARTED',
}

export const getFileExtension = (fileName?: string | null) => {
  const m = fileName ? fileName.match(/\.(?!.*\.)[a-zA-Z]*(?!\\)/) : '';
  if (m && m.length) {
    return m[0].replace(/\./g, '').toLowerCase();
  }
  return '';
};

export const stripImageNameFromS3URL = (url: string) => {
  // extract image name from url
  if (url.includes('receipts')) {
    return url.split('receipts')[1].slice(1).split('?')[0];
  }

  if (url.includes('supporting_documents')) {
    return url.split('supporting_documents')[1].slice(1).split('?')[0];
  }

  if (url.includes('learning_and_dev_receipts')) {
    return url.split('learning_and_dev_receipts')[1].slice(1).split('?')[0];
  }
  return url;
};

export async function getFileSize(url: string) {
  try {
    const response = await fetch(url, {
      headers: {'X-HTTP-Method-Override': 'HEAD'},
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentLength = response.headers.get('Content-Length');
    return contentLength ? formatBytes(parseInt(contentLength, 10)) : null;
  } catch (error) {
    console.error('Error fetching file size:', error);
    return null;
  }
}

export function formatBytes(bytes: number | string | null | undefined, decimals = 2) {
  if (!bytes) return null;

  if (bytes === 0) return '0 Bytes';

  if (typeof bytes === 'string') {
    bytes = parseInt(bytes);
  }

  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

import React, {Fragment} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {Controller, FieldValues, FieldPathByValue, PathValue} from 'react-hook-form';

// Components
import {Listbox, Transition} from '@headlessui/react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

// Types
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {ComptDropDownProps} from '../compt-dropdown/compt-dropdown';
import {ComptFormControlFieldSingleSelectBaseProps} from '@compt/types/form/compt-forms';

// Styles
import comptColors from '@compt/styles/compt-colors';

export interface ComptDropDownFieldProps<
  TOptionType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TOptionType>,
> extends Omit<ComptDropDownProps<TOptionType>, 'value' | 'onChange' | 'name' | 'initialValue'>,
    Omit<ComptFormControlFieldSingleSelectBaseProps<TOptionType, TFieldValues, TName>, 'value'> {
  getSecondaryText?: (item: TOptionType) => string;
  value?: PathValue<TFieldValues, TName>;
  onChange?: () => void;
  invalidOptions?: TOptionType[];
  readOnly?: boolean;
}

export const ComptDropDownField = <
  TOptionType,
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, TOptionType>,
>(
  props: ComptDropDownFieldProps<TOptionType, TFieldValues, TName>,
) => {
  const heightClass = props.heightClass || 'h-11';
  const textClass = props.textClass || 'text-color-body1';

  const comparator = (a: TOptionType, b: TOptionType) => {
    if (typeof props.by === 'function') {
      return props.by(a, b);
    }

    if (props.by && a && b) {
      return a[props.by] === b[props.by];
    }

    return a === b;
  };
  const DisplayText = (value?: any): string => {
    if (!value) return props.placeholder || '';

    const displayText = props.getDisplayText(value);

    return displayText ?? (props.placeholder || '');
  };

  const field = (
    <Controller<TFieldValues, TName>
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field: {onChange, value, ref}}) => (
        <Listbox value={value} onChange={onChange} disabled={props.disabled} by={comparator}>
          {({open}) => (
            <>
              <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900 py-0">
                {props.title}
              </Listbox.Label>
              <div className="relative" dir={props.alignRight ? 'rtl' : 'ltr'}>
                <Listbox.Button
                  placeholder={props.placeholder}
                  data-testid={props['data-testid']}
                  dir="ltr"
                  ref={ref}
                  className={`
                  compt-dropdown flex justify-between relative h-600 ${heightClass}
                  font-semibold cursor-default rounded-lg overflow-hidden w-0 min-w-full
                  bg-white px-200 py-100 text-left ${textClass} shadow-sm ring-1 ring-inset
                  sm:text-sm sm:leading-6 flex flex-row items-center focus:ring-2
                  ${
                    props.errors
                      ? 'ring-stroke-critical focus:ring-stroke-critical focus:ring-inset'
                      : 'ring-stroke-tertiary focus:ring-stroke-focus'
                  }
                ${props.additionalClasses}`}
                  //  Needed since tailwind bg utility classses aren't applying
                  style={{
                    backgroundColor: props.disabled
                      ? comptColors.gray['300']
                      : comptColors.gray['000'],
                  }}
                >
                  <span
                    className={`body1 truncate ${
                      props.placeholder && !value ? 'text-gray-400' : 'text-color-body2'
                    }`}
                  >
                    {DisplayText(value)}
                  </span>
                  {!props.disabled && (
                    <ComptSvgIcon
                      iconName="chevron-down-icon"
                      svgProp={{width: '24px', height: '24px'}}
                    />
                  )}
                </Listbox.Button>
                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    className={`
                    absolute z-10 mt-1 max-h-60 w-0 min-w-full rounded-md bg-white py-1
                    text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm
                    overflow-auto
                  `}
                    dir="ltr"
                  >
                    {props.options?.length &&
                      props.options.map((item, index) => (
                        <Listbox.Option
                          key={props.getKey(item)}
                          className={({active}) =>
                            twMerge(`
                          relative cursor-default select-none py-2 pl-3 pr-9 ${textClass}
                          ${active ? ' bg-gray-200 cursor-pointer' : ''}
                          `)
                          }
                          value={item}
                          data-testid={`listbox-option-${props.getDisplayText(item)}`}
                        >
                          {({selected}) => (
                            <>
                              <div className="flex flex-col items-baseline">
                                <span
                                  className={twMerge(
                                    `body3 block truncate compt-dropdown-item ${textClass}`,
                                  )}
                                >
                                  {props.getDisplayText(item)}
                                </span>
                                {props.getSecondaryText && (
                                  <span className="body3 truncate text-color-tertiary">
                                    {props.getSecondaryText(item)}
                                  </span>
                                )}
                              </div>
                              {selected ? (
                                <span
                                  className={'absolute inset-y-0 right-0 flex items-center pr-4'}
                                >
                                  <ComptSvgIcon
                                    iconName="check-icon"
                                    labelHidden={false}
                                    ariaLabel="check-icon"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    {props.invalidOptions &&
                      props.invalidOptions.map((item, index) => (
                        <Listbox.Option
                          key={props.getKey(item)}
                          className={({active}) =>
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          }
                          value={item}
                          data-testid={`listbox-option-${props.getDisplayText(item)}`}
                          disabled={true}
                        >
                          <div className="flex flex-row items-baseline">
                            <p
                              className={
                                'body3 block truncate compt-dropdown-item text-color-tertiary/50'
                              }
                            >
                              {props.getDisplayText(item)}
                            </p>
                            {props.getSecondaryText && (
                              <p className="body3 text-color-tertiary/50 pl-2">
                                {props.getSecondaryText(item)}
                              </p>
                            )}
                          </div>
                        </Listbox.Option>
                      ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};

import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {comptApiSlice} from '@compt/app/services/api/api-slice';
import {setupListeners} from '@reduxjs/toolkit/dist/query';
import {comptRootSlice} from '@compt/app/services/api/root-slice';

export const store = configureStore({
  reducer: {
    [comptApiSlice.reducerPath]: comptApiSlice.reducer,
    [comptRootSlice.reducerPath]: comptRootSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(comptApiSlice.middleware, comptRootSlice.middleware),
});

setupListeners(store.dispatch);

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

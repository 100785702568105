import React from 'react';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptStyleType} from '@compt/utils/style-helpers';
import {syncResultStatusFormats} from '@compt/types/team-roster-syncs/team-roster-syncs-status';

export interface TeamRosterSyncStatusProps {
  status: string | undefined;
}

export const TeamRosterSyncStatus = ({status}: TeamRosterSyncStatusProps) => {
  let style = ComptStyleType.PRIMARY;
  let name = '';

  if (status && status in syncResultStatusFormats) {
    const {statusName, statusStyle} = syncResultStatusFormats[status];
    style = statusStyle;
    name = statusName;
  }

  return (
    <ComptPill pillType={style} data-tag="allowRowEvents">
      {name || 'Unknown'}
    </ComptPill>
  );
};

export enum SegmentType {
  'SUBMITTED' = 'SUBMITTED',
  'UPDATED_BY_USER' = 'UPDATED_BY_USER',
  'UPDATED_BY_REVIEWER' = 'UPDATED_BY_REVIEWER',
  'APPROVED' = 'APPROVED',
  'AUTO_APPROVED' = 'AUTO_APPROVED',
  'UNDO_PAYROLL' = 'UNDO_PAYROLL',
  'REJECTED' = 'REJECTED',
  'PROCESSED' = 'PROCESSED',
}

export interface SegmentDiff {
  field_verbose_name: string;
  field_name: string;
  field_old_value: string;
  field_new_value: string;
}

export interface FormattedHistoryItem {
  actor_email: string;
  actor_first_name: string;
  actor_last_name: string;
  as_of_date: string;
  segment_diff: SegmentDiff[];
  segment_type: SegmentType;
}

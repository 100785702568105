import React from 'react';

// Hooks & methods
import {twMerge} from 'tailwind-merge';
import {ComptPaginationController} from './compt-pagination-bar.controller';

// Components
import {PaginationChangePage} from 'react-data-table-component/dist/src/DataTable/types';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export interface ComptPaginationBarProps {
  totalPages: number;
  itemsPerPage: number;
  onChangePage: PaginationChangePage;
  currentPage: number;
}

export const ComptPaginationBar = (props: ComptPaginationBarProps) => {
  const {totalPages, currentPage, onChangePage, itemsPerPage} = props;
  const {getNumberOfNeighbors, pagesToShow} = new ComptPaginationController();

  const neighbors = getNumberOfNeighbors(currentPage, totalPages);
  const pagesToShowList = pagesToShow(currentPage, totalPages, neighbors);

  const isPrevBtnDisabled = currentPage === 1 || totalPages === 1;
  const isNextBtnDisabled = currentPage === totalPages || totalPages === 1;

  return (
    <div className="flex items-center justify-between bg-white px-4 py-3 sm:px-6 border-t rounded-b-xl">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <button
          className={twMerge(
            `relative inline-flex items-center font-bold ${
              isPrevBtnDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-color-link'
            }`,
          )}
          onClick={() => onChangePage(currentPage - 1, itemsPerPage)}
          disabled={isPrevBtnDisabled}
        >
          <span className="sr-only">Previous</span>
          <ComptSvgIcon
            iconName={isPrevBtnDisabled ? 'arrow-left-gray' : 'arrow-left'}
            labelHidden={false}
            ariaLabel="arrow-left"
            className="h-5 w-5 mr-2"
          />
          Previous
        </button>
        <div>
          {pagesToShowList.map((page, idx) => {
            if (typeof page === 'number') {
              return (
                <button
                  key={idx}
                  className={`
                      relative inline-flex items-center px-4 py-2 text-sm
                      ${
                        page === currentPage
                          ? 'bg-surface-tint font-bold rounded-full'
                          : 'font-semibold text-color-body1'
                      }
                    `}
                  onClick={() => onChangePage(page, itemsPerPage)}
                >
                  {page}
                </button>
              );
            } else {
              return (
                <span
                  key={idx}
                  className={
                    'relative inline-flex items-center px-4 ' +
                    'py-2text-sm font-semibold text-color-body1'
                  }
                >
                  {page}
                </span>
              );
            }
          })}
        </div>

        <button
          className={twMerge(
            `relative inline-flex items-center font-bold ${
              isNextBtnDisabled ? 'text-gray-400 cursor-not-allowed' : 'text-color-link'
            }`,
          )}
          onClick={() => onChangePage(currentPage + 1, itemsPerPage)}
          disabled={isNextBtnDisabled}
        >
          <span className="sr-only">Next</span>
          Next
          <ComptSvgIcon
            iconName={isNextBtnDisabled ? 'arrow-right-gray' : 'arrow-right'}
            labelHidden={false}
            ariaLabel="arrow-right"
            className="h-5 w-5 ml-2"
          />
        </button>
      </div>
    </div>
  );
};

import React from 'react';

interface ReceiptPaginationProps {
  paginationLength: number;
  activeIndex: number;
  slideTo: (index: number) => void;
}

export const ComptReceiptPagination = (props: ReceiptPaginationProps) => (
  <>
    {[...Array(props.paginationLength)].map((_, index) => (
      <button
        className={`p-1.5 hover:bg-stroke-divider1 rounded-md ${
          props.activeIndex === index && 'bg-gray-200'
        }`}
        key={`pagination-${index}`}
        onClick={(e) => {
          e.preventDefault();
          props.slideTo(index);
        }}
      >
        <div className="w-2 h-2 rounded-full bg-stroke-primary" />
      </button>
    ))}
  </>
);

import {Control, useWatch} from 'react-hook-form';
import React from 'react';
import {GiveTeamRecognitionFormSchema} from '@compt/pages/team-recognition-page/components/give-team-recognition-form/give-team-recognition-form.controller';
import {GiveTeamRecognitionSummary} from '@compt/pages/team-recognition-page/components/give-team-recognition-form/give-team-recognition-summary';
import {Allotment} from '@compt/types/allotments';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

export interface GiveTeamRecognitionWrapperProps {
  control: Control<GiveTeamRecognitionFormSchema>;
  onSubmitClick?: () => void;
  disabled?: boolean;
  isNonMonetary?: boolean;
}

/**
 * Helper wrapper to isolate re-renders in the form. Without this, the entire form, including its inputs,
 * will be re-rendered on every single input change.
 * @param props
 * @constructor
 */
export const GiveTeamRecognitionSummaryWrapper = ({control}: GiveTeamRecognitionWrapperProps) => {
  const currentFormValuesWatch = useWatch<GiveTeamRecognitionFormSchema>({control});
  return (
    <GiveTeamRecognitionSummary
      allotment={currentFormValuesWatch.recognitionAllotment as Allotment | null | undefined}
      recipientCount={
        currentFormValuesWatch.recognitionRecipients
          ? currentFormValuesWatch.recognitionRecipients.length
          : 0
      }
      recognitionAmount={
        currentFormValuesWatch.recognitionAmountInput ||
        currentFormValuesWatch.recognitionAmountDropdown ||
        0
      }
    />
  );
};

export const GiveTeamRecognitionSubmitFormWrapper = ({
  control,
  disabled,
  isNonMonetary,
  onSubmitClick,
}: GiveTeamRecognitionWrapperProps) => {
  const currentFormValuesWatch = useWatch<GiveTeamRecognitionFormSchema>({control});
  const recognitionAmount =
    currentFormValuesWatch.recognitionAmountInput ||
    currentFormValuesWatch.recognitionAmountDropdown ||
    0;
  const recipientCount = currentFormValuesWatch.recognitionRecipients
    ? currentFormValuesWatch.recognitionRecipients.length
    : 0;
  const recognitionTotal = recognitionAmount * recipientCount;
  const currentBalance = currentFormValuesWatch.recognitionAllotment?.balance_amount || 0;
  const remainingBalance = currentBalance ? currentBalance - recognitionTotal : 0;

  return (
    <ComptButton
      data-testid="team-recognition-submit-button"
      buttonType={ComptButtonType.PRIMARY}
      className="min-w-fit ml-1"
      type="submit"
      onClick={onSubmitClick}
      disabled={
        disabled ||
        (!isNonMonetary &&
          (currentBalance === 0 || remainingBalance < 0 || recognitionAmount === 0))
      }
    >
      {isNonMonetary ? 'Give Shout Out' : 'Give recognition'}
    </ComptButton>
  );
};

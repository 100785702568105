/* eslint-disable max-len */
import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  ReviewingUserListResults,
  LearningDevelopmentProgram,
  LearningDevelopmentProgramListResults,
  LearningDevelopmentProgramPayload,
  ProgramFundingRule,
  ProgramFundingRulePayload,
  ProgramReviewingConfigurationPayload,
  ProgramReviewingConfiguration,
  ReviewingUserPayload,
} from '@compt/types/learning-development/learning-development-program';
import {
  PreApprovalRequest,
  PreApprovalRequestListResults,
} from '@compt/types/learning-development/pre-approval-request';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLearningDevelopmentProgram: builder.query<
      LearningDevelopmentProgram,
      {companyId?: number; programId?: number | string | null}
    >({
      query: ({companyId, programId}) =>
        `learning_and_development/${companyId}/admin/programs/${programId}`,
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM],
    }),
    getAllLearningDevelopmentPrograms: builder.query<
      LearningDevelopmentProgramListResults,
      {companyId?: number; filter: {search?: string; offset: number; limit: number}}
    >({
      query: ({companyId, filter}) => ({
        url: `learning_and_development/${companyId}/admin/programs/`,
        params: filter,
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST],
    }),
    createLearningDevelopmentProgram: builder.mutation<
      LearningDevelopmentProgram,
      {body: Partial<LearningDevelopmentProgramPayload>; companyId: number}
    >({
      query({body, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
      ],
    }),
    updateLearningDevelopmentProgram: builder.mutation<
      LearningDevelopmentProgram,
      Partial<LearningDevelopmentProgram>
    >({
      query(body) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.id}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    deleteLearningDevelopmentProgram: builder.mutation<
      void,
      {companyId: number; programId: number}
    >({
      query({companyId, programId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/`,
          method: 'DELETE',
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateProgramFundingRule: builder.mutation<
      ProgramFundingRule[],
      {body: ProgramFundingRulePayload[]; programId: number; companyId: number}
    >({
      query({body, programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/funding_rules/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM],
    }),
    getProgramAssignableUsers: builder.query<
      ReviewingUserListResults,
      {
        companyId: number;
        programId: number;
        filter: {search?: string; offset: number; limit: number};
      }
    >({
      query: ({companyId, programId, filter}) => ({
        url: `learning_and_development/${companyId}/admin/programs/${programId}/assignable_users`,
        params: filter,
        method: 'GET',
      }),
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_ASSIGNABLE_USERS_LIST],
    }),
    createLearningDevelopmentProgramReviewingConfiguration: builder.mutation<
      ProgramReviewingConfiguration,
      {body: Partial<ProgramReviewingConfigurationPayload>}
    >({
      query({body}) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.program?.id}/reviewing_configuration/`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM],
    }),
    updateLearningDevelopmentProgramReviewingConfiguration: builder.mutation<
      ProgramReviewingConfiguration,
      {body: Partial<ProgramReviewingConfiguration>; configurationId?: number}
    >({
      query({body, configurationId}) {
        return {
          url: `learning_and_development/${body.company?.id}/admin/programs/${body.program?.id}/reviewing_configuration/${configurationId}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramPrimaryApprovers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/primary_approvers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramSecondaryApprovers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/secondary_approvers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    updateLearningDevelopmentProgramReimbursementReviewers: builder.mutation<
      ProgramReviewingConfiguration,
      {
        body: Array<ReviewingUserPayload>;
        companyId: number;
        programId: number;
        configurationId: number;
      }
    >({
      query({body, companyId, programId, configurationId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reviewing_configuration/${configurationId}/reimbursement_reviewers/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
      ],
    }),
    createPreApprovalRequestConfiguration: builder.mutation<
      LearningDevelopmentProgram,
      {programId: number; companyId: number}
    >({
      query({programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/pre_approval_request_configurations`,
          method: 'GET',
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
      ],
    }),
    createReimbursementRequestConfiguration: builder.mutation<
      LearningDevelopmentProgram,
      {programId: number; companyId: number}
    >({
      query({programId, companyId}) {
        return {
          url: `learning_and_development/${companyId}/admin/programs/${programId}/reimbursement_request_configurations`,
          method: 'GET',
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PROGRAM,
      ],
    }),
    getAllAdminPreApprovalRequests: builder.query<
      PreApprovalRequestListResults,
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      query({companyId, params}) {
        return {
          url: `learning_and_development/${companyId}/review/pre_approval_requests/?include_reviewed=true`,
          params: {...params},
          method: 'GET',
        };
      },
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST],
    }),
    getAdminPreApprovalRequest: builder.query<
      PreApprovalRequest,
      {companyId: number | undefined; requestId: string | null}
    >({
      query({companyId, requestId}) {
        return {
          url: `learning_and_development/${companyId}/review/pre_approval_requests/${requestId}/`,
          method: 'GET',
        };
      },
      providesTags: [ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST],
    }),
    updateAdminPreApprovalRequest: builder.mutation<PreApprovalRequest, PreApprovalRequest>({
      query(body) {
        return {
          url: `learning_and_development/${body.pre_approval_configuration.company}/review/pre_approval_requests/${body.id}/`,
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: [
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST_LIST,
        ComptRTKTags.LEARNING_DEVELOPMENT_PRE_APPROVAL_REQUEST,
        ComptRTKTags.EMPLOYEE_LEARNING_DEVELOPMENT_PRE_APPROVAL_LIST,
      ],
    }),
  }),
});

export const {
  useGetLearningDevelopmentProgramQuery,
  useCreateLearningDevelopmentProgramMutation,
  useDeleteLearningDevelopmentProgramMutation,
  useUpdateLearningDevelopmentProgramMutation,
  useGetAllLearningDevelopmentProgramsQuery,
  useUpdateProgramFundingRuleMutation,
  useGetProgramAssignableUsersQuery,
  useCreateLearningDevelopmentProgramReviewingConfigurationMutation,
  useUpdateLearningDevelopmentProgramReviewingConfigurationMutation,
  useUpdateLearningDevelopmentProgramPrimaryApproversMutation,
  useUpdateLearningDevelopmentProgramSecondaryApproversMutation,
  useUpdateLearningDevelopmentProgramReimbursementReviewersMutation,
  useCreatePreApprovalRequestConfigurationMutation,
  useCreateReimbursementRequestConfigurationMutation,
  useGetAllAdminPreApprovalRequestsQuery,
  useGetAdminPreApprovalRequestQuery,
  useUpdateAdminPreApprovalRequestMutation,
} = extendedComptApiSlice;

import React from 'react';
import _ from 'lodash';
import {ComptFormField} from '../compt-form-field/compt-form-field';
import {Controller, ControllerRenderProps, FieldPathByValue, FieldValues} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';

export interface ComptNumberFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, number>,
> extends Omit<ComptFormControlFieldBaseProps<number, TFieldValues, TName>, 'initialValue'> {
  initialValue?: number | null;
  placeholder?: string;
  'data-testid'?: string;
  decimalPlaces?: number;
  stepValue?: string;
  minValue?: number;
  readOnly?: boolean;
}

export const ComptNumberField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, number>,
>(
  props: ComptNumberFieldProps<TFieldValues, TName>,
) => {
  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    field: ControllerRenderProps<TFieldValues, TName>,
  ) => {
    const {value} = e.target;

    const fixedValue = value
      ? parseFloat(value).toFixed(props.decimalPlaces ?? 2)
      : props.minValue || 0;
    field.onChange(fixedValue);
    field.onBlur();
  };

  const field = (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <div className="relative">
          <input
            value={field.value}
            ref={field.ref}
            type="number"
            step={props.stepValue || '0.01'}
            min={props.minValue || '0'}
            onWheel={(e) => e.currentTarget.blur()}
            onChange={(e) => field.onChange(e.target.value)}
            onBlur={(e) => handleOnBlur(e, field)}
            name={_.kebabCase(props.label)}
            id="number-field"
            placeholder={props.placeholder}
            aria-describedby="number-field"
            data-testid={props['data-testid']}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
            disabled={props.disabled}
            className={`
          block w-full rounded-md border-0 py-100 px-200 shadow-sm ring-1 ring-inset
          body1 text-color-body2
          placeholder:text-gray-400 sm:leading-6 focus:ring-2 disabled:bg-surface-disabled
          disabled:text-disabled-on-light
          ${
            props.errors
              ? 'ring-stroke-critical focus:ring-stroke-critical'
              : 'ring-stroke-tertiary focus:ring-stroke-focus'
          }
        `}
          />
        </div>
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};

import {comptRootSlice} from '@compt/app/services/api/root-slice';
import {SecurityHelpers} from '@compt/utils/security-helpers';

const extendedComptApiSlice = comptRootSlice.injectEndpoints({
  endpoints: (builder) => ({
    logInAsUser: builder.mutation<void, number>({
      query(userPk: number) {
        const formData = new URLSearchParams();
        formData.append('user_pk', `${userPk}`);
        formData.append('next', '/app');
        formData.append('csrfmiddlewaretoken', SecurityHelpers.getCSRFToken());
        return {
          url: 'hijack/acquire/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData,
          formData: true,
          responseHandler: (response) => response.text(),
        };
      },
    }),
    releaseUser: builder.mutation<void, void>({
      query() {
        const formData = new URLSearchParams();
        formData.append('next', '/app');
        formData.append('csrfmiddlewaretoken', SecurityHelpers.getCSRFToken());
        return {
          url: 'hijack/release/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: formData,
          formData: true,
          responseHandler: (response) => response.text(),
        };
      },
    }),
  }),
});

export const {useLogInAsUserMutation, useReleaseUserMutation} = extendedComptApiSlice;

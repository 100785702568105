import React, {useContext} from 'react';
import {SECTION_TYPE} from '../compt-layout/compt-layout.controller';
import {SideNavContext} from './side-nav-context';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {SideNavItemType} from '@compt/common/compt-side-nav/compt-side-nav.types';
import {ComptSideNavButton} from '@compt/common/compt-side-nav/compt-side-nav-button';
import comptColors from '@compt/styles/compt-colors';

export type ComptSecondarySideNavProps = {
  sectionItems: SideNavItemType[];
  sectionType: SECTION_TYPE;
};

export const ComptSecondarySideNav = ({sectionItems, sectionType}: ComptSecondarySideNavProps) => {
  const {
    sidebarOpen,
    setStipendAdminOpen,
    setExpenseAdminOpen,
    setInternalAdminOpen,
    setLearningAdminOpen,
  } = useContext(SideNavContext);
  const isMobileView = useIsMobileView();

  const isStipendAdmin = sectionType === SECTION_TYPE.STIPEND_ADMIN;
  const isExpenseAdmin = sectionType === SECTION_TYPE.EXPENSE_ADMIN;
  const isInternalAdmin = sectionType === SECTION_TYPE.INTERNAL_ADMIN;
  const isLearningAdmin = sectionType === SECTION_TYPE.LEARNING_ADMIN;

  return (
    <div
      onMouseLeave={() => {
        if (isStipendAdmin) {
          setStipendAdminOpen((prevState) => !prevState);
        }
        if (isExpenseAdmin) {
          setExpenseAdminOpen((prevState) => !prevState);
        }
        if (isInternalAdmin) {
          setInternalAdminOpen((prevState) => !prevState);
        }
        if (isLearningAdmin) {
          setLearningAdminOpen((prevState) => !prevState);
        }
      }}
    >
      <div
        className={`${!isMobileView && sidebarOpen ? 'pt-8' : 'pt-6'} pb-4 bg-white
        h-full border-r sm:w-[256px] absolute px-200`}
        // Add in-line styling to show within admin experience
        style={{
          borderRight: `1px solid ${comptColors.gray[200]}`,
        }}
      >
        <div className="sm:pb-6 grid gap-y-1">
          {sectionItems.map((navItem, index) => (
            <ComptSideNavButton
              key={index}
              iconId={navItem.iconId}
              pagePath={navItem.pagePath}
              isHref={navItem.isHref}
              excludeIcon={true}
              isSecondaryButton
            >
              {navItem.title}
            </ComptSideNavButton>
          ))}
        </div>
      </div>
    </div>
  );
};

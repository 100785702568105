import React from 'react';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {store} from './app/store';
import './index.scss';
import {RouterProvider} from 'react-router-dom';

import {COMPT_ROUTER} from './router';
import {ComptSideNavAdmin} from './common/compt-side-nav/compt-side-nav-admin';

const mountSideNavNode = document.getElementById('nds-app-only-side-nav');

if (mountSideNavNode) {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, import/no-named-as-default-member
  ReactDOM.createRoot(mountSideNavNode!).render(
    <Provider store={store}>
      <ComptSideNavAdmin />
    </Provider>,
  );
} else {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, import/no-named-as-default-member
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={COMPT_ROUTER} />
      </Provider>
    </React.StrictMode>,
  );
}

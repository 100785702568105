import {ComptRTKTags, comptApiSlice} from './api-slice';
import {
  BusinessExpenseReport,
  BusinessExpenseReportList,
  CreatedBusinessExpenseReport,
} from '@compt/types/business-expenses/business-expense-report';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBusinessExpenseReportList: builder.query<
      BusinessExpenseReportList,
      {companyId?: number; params?: Record<string, unknown> | null}
    >({
      providesTags: [ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST],
      query: ({companyId, params}) => ({
        url: `business_expenses/${companyId}/me/expense_reports`,
        params: {...params},
      }),
    }),
    getBusinessExpenseReport: builder.query<BusinessExpenseReport, string>({
      providesTags: (result, error, id) => [{type: ComptRTKTags.BUSINESS_EXPENSE_REPORT, id: id}],
      query: (reportId) => `/business_expenses/expense_reports/${reportId}`,
    }),
    createBusinessExpenseReport: builder.mutation<
      CreatedBusinessExpenseReport,
      Partial<BusinessExpenseReport>
    >({
      invalidatesTags: [ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST],
      query(body) {
        return {
          url: 'business_expenses/expense_reports/new',
          method: 'POST',
          body,
        };
      },
    }),
    updateBusinessExpenseReport: builder.mutation<BusinessExpenseReport, BusinessExpenseReport>({
      invalidatesTags: (result, error, args) => [
        {type: ComptRTKTags.BUSINESS_EXPENSE_REPORT, id: args.id},
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
        ComptRTKTags.BUSINESS_EXPENSE_LIST,
      ],
      query(data) {
        const {id, ...body} = data;
        return {
          url: `business_expenses/expense_reports/${id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteBusinessExpenseReport: builder.mutation<{success: boolean; id: number}, number>({
      invalidatesTags: (result, error, id) => [
        {type: ComptRTKTags.BUSINESS_EXPENSE_REPORT, id},
        ComptRTKTags.BUSINESS_EXPENSE_REPORT_LIST,
      ],
      query(id) {
        return {
          url: `business_expenses/expense_reports/${id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetBusinessExpenseReportListQuery,
  useGetBusinessExpenseReportQuery,
  useCreateBusinessExpenseReportMutation,
  useUpdateBusinessExpenseReportMutation,
  useDeleteBusinessExpenseReportMutation,
} = extendedComptApiSlice;

import {ComptRTKTags, comptApiSlice} from './api-slice';
import {TeamRosterSyncResultList} from '@compt/types/team-roster-syncs/team-roster-syncs';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamRosterSyncResultList: builder.query<
      TeamRosterSyncResultList,
      {syncAttemptId?: string | number; params?: Record<string, unknown>}
    >({
      providesTags: [ComptRTKTags.TEAM_ROSTER_SYNC_RESULT_LIST],
      query: ({syncAttemptId, params}) => ({
        url: `roster-sync-attempts/${syncAttemptId}/sync-results`,
        params: {...params},
      }),
    }),
  }),
});

export const {useGetTeamRosterSyncResultListQuery} = extendedComptApiSlice;

import React, {useState} from 'react';
import {
  BaseFilterTypeProps,
  FilterConfigOption,
  SelectionChangeValues,
} from './compt-filter-bar.types';
import {ComptSelectFilterController} from './compt-select-filter.controller';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export interface SelectFilterProps extends BaseFilterTypeProps {
  handleChange: (changeValue: SelectionChangeValues) => void;
  allowSelectAll?: boolean;
}
export const SelectFilter = ({
  label,
  currentValues,
  options,
  handleChange,
  allowSelectAll,
}: SelectFilterProps) => {
  const {checkIsSelectAll, handleSelectDeselectAll, isChecked} = new ComptSelectFilterController();
  const [isOpen, setIsOpen] = useState(false);

  if (!Array.isArray(currentValues)) {
    return null;
  }

  const toggleOption = (e: React.ChangeEvent<HTMLInputElement>, option: FilterConfigOption) => {
    const selectionOption = {id: option.id.toString(), name: option.name};

    handleChange({
      selection: selectionOption,
      checked: e.target.checked,
    });
  };

  return (
    <div className="relative z-5">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`px-4 py-2 cursor-pointer flex row-auto items-center justify-center gap-x-2 ${
          isOpen ? 'bg-base-50 rounded-full' : ''
        } `}
      >
        <ComptSvgIcon iconName="filter-lines-blue-icon" />
        <p className="body2 text-color-body1">{label}</p>
      </div>
      {allowSelectAll && (
        <div className="p-2">
          <button
            className="btn w-100"
            style={{display: 'inline-block'}}
            type="button"
            onClick={() => handleSelectDeselectAll(currentValues, options, handleChange)}
          >
            {checkIsSelectAll(currentValues, options) ? 'Deselect All' : 'Select All'}
          </button>
        </div>
      )}
      {isOpen && (
        <div className="absolute w-48 mt-1 right-0">
          <div className="relative border rounded-lg z-10">
            {options?.map((option, index) => (
              <label
                key={option.id}
                className={`flex items-center px-2 py-2 cursor-pointer hover:bg-gray-200 bg-white ${
                  index === 0 ? 'rounded-t-lg' : ''
                } ${index === options.length - 1 ? 'rounded-b-lg' : ''}`}
              >
                <input
                  type="checkbox"
                  id={`${option.id}`}
                  onChange={(e) => toggleOption(e, option)}
                  checked={isChecked(currentValues, option)}
                  className="border-gray-300 rounded text-stroke-primary focus:ring-stroke-primary"
                />
                <p className="body2 text-color-body1 pl-3">{option.name}</p>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {Allotment, AllotmentCycleFundingMode} from '@compt/types/allotments';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetAllotmentsQuery} from '@compt/app/services/api/allotments-slice';
import {TeamRecognitionActivityFeed} from '../team-recognition-activity-feed';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {NonMonetaryRecognitionCardContent} from '../recognition-card-content/non-monetary-recognition-card-content';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {CardExpirationIndicator} from '@compt/common/compt-card/expiration-indicator';
import {TeamRecognitionCardContent} from '../recognition-card-content/team-recognition-card-content';

interface MobileTeamRecognitionLayoutProps {
  handleSelectAllotment: (allotment: Allotment) => void;
  setAllotmentToDescribe: React.Dispatch<React.SetStateAction<Allotment | null>>;
  setShowRecognitionForm: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileTeamRecognitionLayout = (props: MobileTeamRecognitionLayoutProps) => {
  const isMobileView = useIsMobileView();

  const sessionQuery = useGetSessionQuery();
  const userId = sessionQuery.data?.user_id;
  const userCountryCode = sessionQuery.data?.country;

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const allotmentQuery = useGetAllotmentsQuery(userId ?? skipToken);
  const teamBonusAllotments = allotmentQuery.data?.team_bonus_allotments;

  if (!isMobileView) {
    return null;
  }

  return (
    <>
      <ComptPage
        title="Team Recognition"
        subtitle="Recognize your teammates"
        className="grow px-300 pb-600 pt-6 h-full overflow-auto"
        useDefaultMargins={false}
        includeBottomHR
      >
        <div className="flex flex-wrap mt-600">
          {teamBonusAllotments?.map((allotment: Allotment) => {
            const isNonMonetary =
              allotment.cycle.funding_mode === AllotmentCycleFundingMode.NoFunding;

            return (
              <div
                key={`${allotment.id}-card`}
                id={`allotment-${allotment.id}`}
                className="mb-400 sm:mr-8 w-full sm:max-w-max"
              >
                {isNonMonetary ? (
                  <ComptCard>
                    <div className="non-monetary-card">
                      <div className="p-300 border-b border-stroke-divider1">
                        <h2>{allotment.cycle.name}</h2>
                      </div>
                      <div className="px-300 pt-300 pb-400">
                        <NonMonetaryRecognitionCardContent
                          allotment={allotment}
                          userCountryCode={userCountryCode}
                          onFullDescriptionClicked={(allotment) =>
                            props.setAllotmentToDescribe(allotment)
                          }
                          onClick={() => props.handleSelectAllotment(allotment)}
                        />
                      </div>
                    </div>
                  </ComptCard>
                ) : (
                  <ComptCard>
                    <div className="p-300 border-b border-stroke-divider1">
                      <h2>{allotment.cycle.name}</h2>
                      <CardExpirationIndicator allotment={allotment} />
                    </div>
                    <div className="px-300 pt-300 pb-400">
                      <TeamRecognitionCardContent
                        allotment={allotment}
                        userCountryCode={userCountryCode}
                        onFullDescriptionClicked={(allotment) =>
                          props.setAllotmentToDescribe(allotment)
                        }
                        disableOnClickWhenNoBalance
                        onClick={() => props.handleSelectAllotment(allotment)}
                      />
                    </div>
                  </ComptCard>
                )}
              </div>
            );
          })}
        </div>
        <>
          {allotmentQuery?.data && sessionQuery?.data && companyQuery?.data && (
            <TeamRecognitionActivityFeed
              company={companyQuery.data}
              userSession={sessionQuery.data}
            />
          )}
        </>
        <div
          className={`fixed bottom-0 bg-white left-1/2 transform
        -translate-x-1/2 w-full p-300 border-t border-t-stroke-divider1`}
        >
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            iconId={ComptButtonIcon.MEGAPHONE_WHITE}
            onClick={() => props.setShowRecognitionForm(true)}
            data-testid="compt-give-recognition-button"
            className="w-full"
          >
            Give recognition
          </ComptButton>
        </div>
      </ComptPage>
    </>
  );
};

export enum ComptTooltipPlacement {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum ComptTooltipDuration {
  SHORT = 'SHORT',
  MEDIUM = 'MEDIUM',
  LONG = 'LONG',
}

export enum ComptTooltipStyle {
  DARK = 'DARK',
  LIGHT = 'LIGHT',
}

export interface ComptTooltipControllerProps {
  tooltipTriggerRef: React.RefObject<HTMLDivElement>;
  placement: ComptTooltipPlacement;
  windowSize: {
    width: number;
    height: number;
  };
  setAdjustedPlacement: React.Dispatch<React.SetStateAction<ComptTooltipPlacement>>;
}

export class ComptTooltipController {
  updatePlacement = ({
    tooltipTriggerRef,
    placement,
    windowSize,
    setAdjustedPlacement,
  }: ComptTooltipControllerProps) => {
    const element = tooltipTriggerRef.current;
    if (element) {
      /**
        getBoundingClientRect returns a DOMRect object providing information
        about the size of an element and its position relative to the viewport.
      */
      const {top, right, bottom, left, height, width} = element.getBoundingClientRect();

      let newPlacement = placement;

      switch (placement) {
        case ComptTooltipPlacement.TOP:
          if (top < height) newPlacement = ComptTooltipPlacement.BOTTOM;
          break;
        case ComptTooltipPlacement.RIGHT:
          if (windowSize.width - right < width) newPlacement = ComptTooltipPlacement.LEFT;
          break;
        case ComptTooltipPlacement.BOTTOM:
          if (windowSize.height - bottom < height) newPlacement = ComptTooltipPlacement.TOP;
          break;
        case ComptTooltipPlacement.LEFT:
          if (left < width) newPlacement = ComptTooltipPlacement.RIGHT;
          break;
      }
      setAdjustedPlacement(newPlacement);
    }
  };

  getWhitespaceClass = (tooltipRef: React.RefObject<HTMLDivElement>) => {
    const tooltip = tooltipRef.current;
    return tooltip?.clientWidth && tooltip?.clientWidth >= 320
      ? 'whitespace-normal w-80'
      : 'whitespace-nowrap';
  };

  getPlacementClass = (placement: ComptTooltipPlacement) => {
    switch (placement) {
      case ComptTooltipPlacement.TOP:
        return 'bottom-full mb-2 left-1/2 transform -translate-x-1/2';
      case ComptTooltipPlacement.RIGHT:
        return 'left-full ml-2 top-1/2 transform -translate-y-1/2';
      case ComptTooltipPlacement.BOTTOM:
        return 'top-full mt-2 left-1/2 transform -translate-x-1/2';
      case ComptTooltipPlacement.LEFT:
        return 'right-full mr-2 top-1/2 transform -translate-y-1/2';
      default:
        return '';
    }
  };

  getDurationClass = (placement: ComptTooltipDuration) => {
    switch (placement) {
      case ComptTooltipDuration.SHORT:
        return 'duration-100';
      case ComptTooltipDuration.MEDIUM:
        return 'duration-300';
      case ComptTooltipDuration.LONG:
        return 'duration-500';
      default:
        return '';
    }
  };

  getArrowClass = (placement: ComptTooltipPlacement) => {
    switch (placement) {
      case ComptTooltipPlacement.TOP:
        return 'left-1/2 -bottom-2 -translate-x-1/2 -translate-y-1/2';
      case ComptTooltipPlacement.BOTTOM:
        return 'left-1/2 -top-2 -translate-x-1/2 translate-y-1/2';
      case ComptTooltipPlacement.LEFT:
        return 'top-1/2 -right-2 -translate-y-1/2 -translate-x-1/2';
      case ComptTooltipPlacement.RIGHT:
        return 'top-1/2 -left-2 -translate-y-1/2 translate-x-1/2';
      default:
        return '';
    }
  };

  getStyleClass = (style: ComptTooltipStyle) => {
    switch (style) {
      case ComptTooltipStyle.DARK:
        return 'bg-gray-900';
      case ComptTooltipStyle.LIGHT:
        return 'bg-white';
      default:
        return '';
    }
  };
}

import React, {Dispatch, SetStateAction} from 'react';
import {ComptCategoryCard} from '@compt/common/compt-category-card/compt-category-card';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import {Allotment} from '@compt/types/allotments';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {PerkCategory, PerkCategoryCustomizations} from '@compt/types/perk-category';
import DOMPurify from 'dompurify';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';

interface MobileViewDetailsProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  allotment: Allotment;
  userCountryCode?: SupportedCountriesType;
  categoryCustomizations: PerkCategoryCustomizations[] | undefined;
  onCategoryClick: (category: PerkCategory, allotment: Allotment, callback?: () => void) => void;
}

export const MobileViewDetailsModal = (props: MobileViewDetailsProps) => {
  function closeModal() {
    props.setOpen(false);
  }

  const renderCategories = () => {
    const sortedCategories = [...props.allotment.perk_categories]?.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortedCategories?.length
      ? sortedCategories.map((category) => (
          <>
            <ComptCategoryCard
              key={category.id}
              userCountryCode={props.userCountryCode}
              allotment={props.allotment}
              perkCategory={category}
              categoryCustomizations={props.categoryCustomizations}
              onCategoryClick={() => {
                props.onCategoryClick(category, props.allotment, closeModal);
              }}
              data-testid={`compt-category-card-${category.id}`}
            />
            <hr className="border-gray-200 last:hidden" />
          </>
        ))
      : null;
  };

  return (
    <ComptModalShell open={props.open} setOpen={props.setOpen}>
      <div className="flex justify-between items-center mb-200">
        <ComptSvgIcon iconName="featured-icon" />
        <ComptButton
          ariaLabel="Close modal button"
          buttonType={ComptButtonType.BORDERLESS}
          iconId={ComptButtonIcon.X}
          onClick={() => props.setOpen(false)}
        />
      </div>
      <h2>{props.allotment.cycle.name}</h2>
      <p
        className="body3 text-color-body1 mt-050 inner-html"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.allotment.cycle.description),
        }}
      />
      <hr className="my-400 border-gray-300" />
      <h4>Submit a claim for:</h4>
      {renderCategories()}
    </ComptModalShell>
  );
};

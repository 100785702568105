import {AnalyticsTool, SendEventProps} from '@compt/utils/analytics/types';
import {UserSession} from '@compt/types/account';

export class GoogleAnalytics implements AnalyticsTool {
  private isAnalyticsEnabled = typeof window.gtag != 'undefined';

  identify(userSession: UserSession): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }
  }

  sendEvent({action, category, label, value}: SendEventProps): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }
    window.gtag('event', action, {
      event_category: category,
      event_label: label,
      value,
    });
  }

  sendPageView(pathname: string, search: string): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }
    window.gtag('event', 'page_view', {send_to: window.gtag_tag_id});
  }
}

import {PerkCategory, PerkCategoryCustomizations} from './perk-category';
import {AmountOverride} from './amount-override';
import {CompanyValue} from '@compt/types/company';

export enum AllotmentType {
  Cyclic = 1,
  Bonus = 2,
  Refund = 3,
  NewHire = 4,
  AutomatedBonus = 5,
}
export enum AllotmentCycleFundingMode {
  AllUpFront = 1,
  AccrualMonthly = 2,
  UpfrontWithProrating = 3,
  NoFunding = 4,
}

export interface AllotmentCycle {
  id: number;
  type: AllotmentType;
  sub_type: number;
  name: string;
  amount: string;
  description: string;
  months: number;
  perk_categories: PerkCategory[];
  funding_mode: AllotmentCycleFundingMode;
  start_date: string;
  termination_date: string | null;
  anniversary_date_key: string | null;
  amount_overrides: AmountOverride[];
  currency: string;
  fund_past_hires: boolean;
  multi_group_key: string;
  multi_group_keys: string[];
  amount_options?: string[];
  company_values: CompanyValue[];
}

export interface Allotment {
  id: number;
  type: AllotmentType;
  sub_type: number | null;
  balance: string;
  balance_amount: number;
  start_date: string;
  last_date: string;
  minutes_left: number;
  days: number;
  days_left: number;
  days_to_next_month: number | null;
  percent_days_left: number;
  amount_spent: number;
  perk_categories: PerkCategory[];
  cycle: AllotmentCycle;
  currency: string;
  amount_override: AmountOverride | null;
  earliest_receipt_date: string;
}

export interface StipendStatProperties {
  stat: number | Record<string, number>;
  title: string;
  help_text?: string;
}

export interface StipendsStats {
  [key: string]: StipendStatProperties;
}

export interface AccountAllotments {
  all_perk_categories: PerkCategory[];
  allotments: Allotment[];
  perk_category_customizations: PerkCategoryCustomizations[];
  team_bonus_allotments: Allotment[];
  today: Date;
  user_now: Date;
  stipends_stats: StipendsStats;
}

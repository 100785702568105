import {useEffect, useState} from 'react';

export const useDelay = (timeDelay = 100) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, timeDelay);

    return () => clearTimeout(timer);
  }, []);

  return {isVisible};
};

import React from 'react';

export enum ComptBannerType {
  WARNING = 'WARNING',
}

export interface ComptBannerProps {
  bannerType: ComptBannerType;
  message: React.ReactNode;
  renderAction?: React.ReactElement;
}

const getBannerTypeColor = (bannerType: ComptBannerType): string => {
  switch (bannerType) {
    case ComptBannerType.WARNING:
      return 'bg-yellow-400';
  }
};

export const ComptBanner = ({bannerType, message, renderAction}: ComptBannerProps) => (
  <div
    className={`flex flex-col sm:flex-row justify-between ${getBannerTypeColor(
      bannerType,
    )} px-[36px] py-2 sm:items-center`}
  >
    <div className="flex flex-1 justify-start">{message}</div>
    <div className="flex justify-start sm:justify-end">{renderAction}</div>
  </div>
);

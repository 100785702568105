import React from 'react';
import {ComptReceiptUploadController} from './compt-receipt-upload.controller';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptButtonIcon} from '@compt/common/compt-button/compt-button';

type UploadErrorComponentProps = {
  fileName: string | null;
  controller: ComptReceiptUploadController;
};
export const UploadErrorComponent = ({fileName, controller}: UploadErrorComponentProps) => (
  <>
    <div className="flex full mx-3 my-5">
      <ComptSvgIcon
        iconName="document-failed-icon"
        labelHidden={false}
        ariaLabel="document-failed-icon"
        className="rounded-full h-8 w-8"
      />
      <div className="flex w-full justify-between items-start">
        <div className="ml-4">
          <p className="body3 compt-text--medium text-color-error">
            Upload failed, please try again
          </p>
          <p className="body3 text-red-600">{fileName}</p>
        </div>
        <button className="flex" onClick={() => controller.deleteFile()}>
          <ComptSvgIcon
            iconName={ComptButtonIcon.RED_TRASH}
            labelHidden={false}
            ariaLabel="trash-icon"
          />
        </button>
      </div>
    </div>
  </>
);

import {createContext} from 'react';

export const SideNavContext = createContext({
  isAdminView: true,
  sidebarOpen: true,
  setSidebarOpen: (func: (previousState: boolean) => boolean) => {},
  stipendAdminOpen: true,
  setStipendAdminOpen: (func: (previousState: boolean) => boolean) => {},
  expenseAdminOpen: true,
  setExpenseAdminOpen: (func: (previousState: boolean) => boolean) => {},
  internalAdminOpen: false,
  setInternalAdminOpen: (func: (previousState: boolean) => boolean) => {},
  learningAdminOpen: false,
  setLearningAdminOpen: (func: (previousState: boolean) => boolean) => {},
});

import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {USER_ROLES} from './user-roles-helper';

export const useHasRequiredRole = (rolesToCheck: USER_ROLES[]) => {
  const sessionQuery = useGetSessionQuery();
  const userRoles = sessionQuery.data?.roles;

  const rolePermissions = rolesToCheck.map((requiredRole) => userRoles?.includes(requiredRole));

  return rolePermissions.includes(true);
};

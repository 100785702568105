import {UserSession} from '@compt/types/account';
import {Company} from '@compt/types/company';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {TeamRecognitionFilter} from '@compt/app/services/api/team-recognition-slice';
import {ACTIVITY_FEED_FILTER_OPTION_LABEL} from '@compt/constants';

export interface FilterValuesType {
  pagination?: {page: number};
  recipientDemographicFilterSelection: 'company' | 'country' | 'department';
  giverOrRecipientFilterSelection: 'received_by_user' | 'given_by_user' | 'all';
}

export interface SelectOption {
  label: string;
  id: string;
  class: string;
}

export const MAX_RESULTS_PER_PAGE = 10;

export const DEFAULT_FILTER_STATE: FilterValuesType = {
  pagination: {page: 1},
  recipientDemographicFilterSelection: 'company',
  giverOrRecipientFilterSelection: 'all',
};

export class TeamRecognitionActivityFeedController {
  private userSession: UserSession;
  private company: Company;
  private readonly internationalEnabled: boolean;
  /**
   * Whether the company has the feature to limit the feed functionality.
   *
   * @private
   * @see FeatureFlags.LIMIT_RECOGNITION_FUNCTIONALITY
   */
  private readonly limitedFilter: boolean;

  constructor(userSession: UserSession, company: Company) {
    this.userSession = userSession;
    this.company = company;
    this.internationalEnabled = featureEnabled(FeatureFlags.INTERNATIONAL);
    this.limitedFilter = featureEnabled(FeatureFlags.LIMIT_RECOGNITION_FUNCTIONALITY);
  }

  getFilterKey(): string {
    return TeamRecognitionActivityFeedController.getFilterKeyWithCompanyId(this.company.id);
  }

  static getFilterKeyWithCompanyId(companyId: number | string): string {
    return `recognition-filter-values--${companyId}`;
  }

  getGiverOrRecipientFilterOptions(): SelectOption[] {
    const recognitionFilters = [
      {id: 'received_by_user', label: 'Recognition Received', class: 'recognition-received'},
      {id: 'given_by_user', label: 'Recognition Given', class: 'recognition-given'},
    ];

    if (!this.limitedFilter) {
      // Add All Recognition if limitedFilter is not enabled
      recognitionFilters.unshift({id: 'all', label: 'All Recognition', class: 'all-recognition'});
    }

    return recognitionFilters;
  }

  getRecipientDemographicFilterOptions(): SelectOption[] {
    const feedOptions = [
      {id: 'company', label: ACTIVITY_FEED_FILTER_OPTION_LABEL.COMPANY_FEED, class: 'company'},
    ];

    if (this.internationalEnabled) {
      feedOptions.push({
        id: 'country',
        label: ACTIVITY_FEED_FILTER_OPTION_LABEL.COUNTRY,
        class: 'country',
      });
    }

    if (this.userSession.cost_center) {
      feedOptions.push({
        id: 'department',
        label: ACTIVITY_FEED_FILTER_OPTION_LABEL.DEPARTMENT,
        class: 'cost-center',
      });
    } else if (this.userSession.department_property) {
      feedOptions.push({
        id: 'department',
        label: ACTIVITY_FEED_FILTER_OPTION_LABEL.DEPARTMENT,
        class: 'department',
      });
    }

    return feedOptions;
  }

  getSelectedValues(filterValueKeys: FilterValuesType): SelectOption[] {
    const feedOptions = this.getRecipientDemographicFilterOptions();
    const recipientGiverFilterOptions = this.getGiverOrRecipientFilterOptions();
    const selectedValues = [recipientGiverFilterOptions[0], feedOptions[0]];

    for (const recipientOption of recipientGiverFilterOptions) {
      if (
        recipientOption.id === filterValueKeys.giverOrRecipientFilterSelection &&
        (recipientOption.id !== 'all' || !this.limitedFilter)
      ) {
        selectedValues[0] = recipientOption;
        break;
      }
    }

    for (const feedOption of feedOptions) {
      if (feedOption.id === filterValueKeys.recipientDemographicFilterSelection) {
        selectedValues[1] = feedOption;
        break;
      }
    }

    return selectedValues;
  }

  getFilterParams(filterValues: FilterValuesType): TeamRecognitionFilter {
    const params: {[key: string]: string | number} = {};

    const currentPage = filterValues.pagination?.page || 1;

    params['limit'] = MAX_RESULTS_PER_PAGE;
    params['offset'] = MAX_RESULTS_PER_PAGE * Math.max(currentPage - 1, 0);

    switch (filterValues.giverOrRecipientFilterSelection) {
      case 'received_by_user':
      case 'given_by_user':
        params['filter_by'] = filterValues.giverOrRecipientFilterSelection;
        break;
      case 'all':
        break;
    }

    // TODO (COMPT-5361): Replace allotment__user with receiving_user
    if (filterValues.recipientDemographicFilterSelection === 'country') {
      params['allotment__user__country__iexact'] = this.userSession.country;
      // params['receiving_user__country__iexact'] = this.userSession.country;
    } else if (filterValues.recipientDemographicFilterSelection === 'department') {
      if (this.userSession.cost_center) {
        params['allotment__user__cost_center_id'] = this.userSession.cost_center.id;
        // params['receiving_user__cost_center_id'] = this.userSession.cost_center.id;
      } else if (this.userSession.department_property) {
        params['allotment__user__properties__key'] = 'department';
        params['allotment__user__properties__value__iexact'] = this.userSession.department_property;
        // params['receiving_user__properties__key'] = 'department';
        // params['receiving_user__properties__value__iexact'] = this.userSession.department_property;
      }
    }

    return params;
  }
}

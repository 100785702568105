import {comptApiSlice, ComptRTKTags} from '@compt/app/services/api/api-slice';
import {
  TeamRecognitionListResults,
  GiveTeamRecognitionRequestBody,
  TeamRecognitionUser,
} from '@compt/types/team-recognition/team-recognition';
import {ListResultsBase} from '@compt/types/common/list-results';
import {PaginationFilter} from '@compt/types/common/pagination-filter';
import {AsyncTaskResponse} from '@compt/types/common/async-results';

export interface TeamRecognitionFilter {
  limit?: number;
  offset?: number;
  filter_by?: string;
  allotment__user__country__iexact?: string;
  allotment__user__cost_center_id?: number;
  allotment__user__properties__key?: string;
  allotment__user__properties__value__iexact?: string;
}

export interface TeamRecognitionRecipientFilter extends PaginationFilter {
  cycle_id: number;
}

export interface GiveTeamRecognitionMutationData {
  allotmentId: number;
  body: GiveTeamRecognitionRequestBody;
}

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTeamRecognition: builder.query<TeamRecognitionListResults, TeamRecognitionFilter>({
      providesTags: [ComptRTKTags.TEAM_RECOGNITION_LIST],
      query: (filter: TeamRecognitionFilter) => ({
        url: '/companies/team_bonuses/',
        params: filter,
        method: 'GET',
      }),
    }),
    giveTeamRecognition: builder.mutation<AsyncTaskResponse, GiveTeamRecognitionMutationData>({
      query: (requestBody: GiveTeamRecognitionMutationData) => ({
        url: `/allotments/${requestBody.allotmentId}`,
        method: 'POST',
        body: requestBody.body,
      }),
    }),
    getTeamRecognitionRecipients: builder.query<
      ListResultsBase<TeamRecognitionUser>,
      TeamRecognitionRecipientFilter
    >({
      query: (filter: TeamRecognitionRecipientFilter) => ({
        url: '/companies/team_bonus_recipients/',
        params: filter,
        method: 'GET',
      }),
    }),
    getTeamRecognitionCCRecipients: builder.query<
      ListResultsBase<TeamRecognitionUser>,
      PaginationFilter
    >({
      query: (filter: PaginationFilter) => ({
        url: '/companies/team_bonus_email_cc_recipients/',
        params: filter,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetTeamRecognitionQuery,
  useGiveTeamRecognitionMutation,
  useGetTeamRecognitionRecipientsQuery,
  useGetTeamRecognitionCCRecipientsQuery,
} = extendedComptApiSlice;

import {DeprecatedComptFormFieldProps} from '@compt/common/forms/compt-form-field/compt-form-field';
import React, {useContext, useEffect, useState} from 'react';
import {
  SaveClaimFormFieldValues,
  SaveClaimSidePanelController,
} from './compt-save-claim-side-panel.controller';
import {useForm} from 'react-hook-form';
import {Allotment} from '@compt/types/allotments';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {PerkCategory} from '@compt/types/perk-category';
import {ComputedAmountSection} from './computed-amount-section';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptReceiptFormCarousel} from '@compt/common/compt-receipt-carousel/compt-receipt-form-carousel';
import {useSearchParams} from 'react-router-dom';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {StipendFormContent} from './stipend-form-content';
import toast from 'react-hot-toast';
import {ReceiptUploadContext} from '@compt/common/forms/compt-receipt-upload/receipt-upload-context';

export interface ComptSaveClaimFormProps extends DeprecatedComptFormFieldProps {
  title: string;
  selectedCategory?: PerkCategory;
  selectedAllotment?: Allotment;
  open: boolean;
  allotments: Allotment[];
  /**
   * called by the modal when the user closes the modal by clicking X or close button
   */
  setOpen: (isOpen: boolean) => void;
  onSubmit: (payload: SaveClaimFormFieldValues) => void;
  mutationLoading: boolean;
  userId?: number;
  hasSlackToken?: boolean;
  hasMsTeams?: boolean;
  companyId?: number;
  userCountryCode?: SupportedCountriesType;
  'data-testid'?: string;
}

const controller = SaveClaimSidePanelController;

export const ComptSaveClaimSidePanel = (props: ComptSaveClaimFormProps) => {
  const {mutationLoading, selectedCategory, selectedAllotment, open, hasSlackToken, hasMsTeams} =
    props;
  const [helpScoutMessagesConfirmed, setHelpScoutMessagesConfirmed] = useState({});
  const [, setSearchParams] = useSearchParams();
  const {receiptUploading, setReceiptUploading} = useContext(ReceiptUploadContext);
  const formMethods = useForm<SaveClaimFormFieldValues>();

  const watchedAllotment = formMethods.watch('allotment');
  const watchedCategory = formMethods.watch('perk_category');

  // Remove any open toasts
  useEffect(() => {
    if (open) {
      toast.remove();
    }
  }, [open]);

  // "reset" method necessary to trigger default value changes in perk_category and allotment
  useEffect(() => {
    // Handles case for "Select a category"
    if (selectedCategory && open) {
      formMethods.reset({perk_category: selectedCategory});
    }

    // Handles case for selecting category from stipend card
    if (selectedAllotment && selectedAllotment && open) {
      formMethods.reset({perk_category: selectedCategory, allotment: selectedAllotment});
    }

    setSearchParams(
      {
        category_id: selectedCategory ? String(selectedCategory.id) : '',
        stipend_id: selectedAllotment ? String(selectedAllotment.cycle.id) : '',
      },
      {replace: true},
    );
  }, [selectedCategory, selectedAllotment, formMethods, open]);

  useEffect(() => {
    if (watchedAllotment && watchedCategory) {
      // Remove the selected allotment if it does not have the category.
      if (!controller.getCategoryFromAllotmentById(watchedAllotment, watchedCategory.id)) {
        formMethods.resetField('allotment', {defaultValue: null});
        setSearchParams({category_id: String(watchedCategory.id), stipend_id: ''}, {replace: true});
      } else {
        // Check for HelpScout Messages
        const keyId = `${watchedCategory.id}-${watchedAllotment.cycle.id}`;
        setSearchParams(
          {
            category_id: String(watchedCategory.id),
            stipend_id: String(watchedAllotment.cycle.id),
          },
          {replace: true},
        );

        if (!Object.keys(helpScoutMessagesConfirmed).includes(keyId)) {
          const showMessage = controller.showRelevantForcedMessages(
            watchedCategory.id,
            watchedAllotment.cycle.id,
            props.companyId,
          );

          setHelpScoutMessagesConfirmed({...helpScoutMessagesConfirmed, [keyId]: showMessage});
        }
      }
    }
  }, [formMethods, watchedCategory, watchedAllotment]);

  return (
    <ComptSidePanel open={props.open} className="max-w-[920px]" data-testid={props['data-testid']}>
      <ComptSidePanel.Header
        title={props.title}
        setOpen={() => controller.handleClose(props.setOpen)}
        headerIcon={{id: 'file-icon-blue'}}
        breadCrumbs={['Select a category', 'Enter claim details']}
      />
      <ComptSidePanel.Content>
        <div className="h-full flex flex-col">
          <form className="grow" onSubmit={formMethods.handleSubmit(props.onSubmit)}>
            <fieldset className="flex h-full grow" disabled={formMethods.formState.isSubmitting}>
              <div className="flex flex-col w-full md:flex-row">
                <div className="flex justify-center bg-[#FAF6F1]">
                  <div
                    className={`
                      flex flex-col w-full sm:w-[480px] items-end p-6
                    `}
                  >
                    <ComptReceiptFormCarousel
                      formMethods={formMethods}
                      userId={props.userId}
                      initialSupportingDocs={[]}
                    />
                  </div>
                </div>
                <div className="w-full grow-[2] py-6 px-6">
                  <StipendFormContent
                    formMethods={formMethods}
                    allotments={props.allotments}
                    editMode
                  />
                  <ComputedAmountSection
                    selectedAllotmentBalance={watchedAllotment?.balance}
                    selectedAllotmentCurrency={watchedAllotment?.currency}
                    control={formMethods.control}
                  />
                  {hasSlackToken && (
                    <ComptTextField
                      name="slack_message"
                      label="Share a message on Slack"
                      control={formMethods.control}
                      register={formMethods.register}
                      validation={{required: false}}
                      errors={formMethods.formState.errors.slack_message}
                      placeholder={"e.g. 'I bought a...'"}
                    />
                  )}
                  {hasMsTeams && (
                    <ComptTextField
                      name="ms_teams_message"
                      label="Share a message on Microsoft Teams"
                      control={formMethods.control}
                      register={formMethods.register}
                      validation={{required: false}}
                      errors={formMethods.formState.errors.ms_teams_message}
                      placeholder={"e.g. 'I bought a...'"}
                    />
                  )}
                  <p className="mb-400 body3 text-color-body1">
                    By submitting this claim, I certify that all this information is accurate and
                    complies with my company&apos;s perk policy. I agree that the contents of my
                    claim, including the receipt(s), are subject to review by my company&apos;s
                    designated reviewers.
                  </p>
                </div>
              </div>
            </fieldset>
          </form>
        </div>
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="grid grid-flow-col gap-3 sm:justify-start w-full bg-white">
          <ComptButton
            onClick={formMethods.handleSubmit((payload) =>
              controller.handleSubmit(payload, props.onSubmit),
            )}
            disabled={mutationLoading || receiptUploading || !formMethods.formState.isValid}
            onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
              e.key === 'Enter' && e.preventDefault();
            }}
          >
            Submit
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            onClick={() => {
              controller.handleClose(props.setOpen);
              setReceiptUploading(() => false);
            }}
            disabled={mutationLoading}
          >
            Cancel
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

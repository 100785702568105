import {DateString} from '../common/date-string';
import {LeanExpense, ProgramSummary} from '@compt/types/learning-development/common-types';

interface User {
  full_name: string;
  email: string;
}

interface ReimbursementRequestData {
  Amount: number;
  ['Vendor name']: string;
  ['Upload document(s)']: string;
  ['Receipt date']: string;
  Category: number;
  [field_name: string]: string | number;
}

export enum ReimbursementStatus {
  'DRAFT' = 'DRAFT',
  'SUBMITTED' = 'SUBMITTED',
  'APPROVED' = 'APPROVED',
  'REJECTED' = 'REJECTED',
  'REIMBURSED' = 'REIMBURSED',
  'PENDING_APPROVAL' = 'PENDING_APPROVAL',
}

export interface ReimbursementRequest {
  id: number;
  user: User;
  company_id: number;
  program_id: number;
  category_id: number;
  program: ProgramSummary;
  requested_on: DateString;
  status: ReimbursementStatus;
  request_data: ReimbursementRequestData;
  related_budget_eligibility: string | null;
  reviewed_by: DateString | null;
  reviewed_on: DateString | null;
  approved_on: DateString | null;
  submitted_on: DateString | null;
  related_reimbursement_request: string | null;
  is_tax_category_set: boolean;
  expense: LeanExpense;
}

export interface EmployeeReimbursementRequestPayload {
  program_id: number;
  pre_approval_request_id: number;
  status: ReimbursementStatus;
  request_data: ReimbursementRequestData;
}

import {CustomNotification} from '@compt/types/custom-notifications';

export function checkCustomNotificationIsUnique(
  existingNotifications: CustomNotification[],
  notificationSubmission: Partial<CustomNotification>,
  selectedNotification?: CustomNotification,
) {
  function isNotificationDuplicate(notification: CustomNotification) {
    return (
      notification.notification_type === notificationSubmission.notification_type &&
      notification.notification_channel === notificationSubmission.notification_channel
    );
  }

  function isDifferentNotification() {
    return selectedNotification && selectedNotification.id !== notificationSubmission.id;
  }

  if (selectedNotification) {
    return !existingNotifications.find(
      (notification) => isNotificationDuplicate(notification) && isDifferentNotification(),
    );
  }

  return !existingNotifications.find(isNotificationDuplicate);
}

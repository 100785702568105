import {comptApiSlice} from './api-slice';
import {Company} from '@compt/types/company';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompany: builder.query<Company, number>({
      query: (userId) => `users/${userId}/company`,
    }),
  }),
});

export const {useGetCompanyQuery} = extendedComptApiSlice;

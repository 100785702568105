import React, {createContext, Dispatch, ReactNode, SetStateAction, useContext} from 'react';
import {ComptProgressStep, StepStatus} from '../compt-progress-stepper/compt-progress-stepper';

interface StepStatusMap {
  [key: string]: StepStatus;
}

export const FormStepperContext = createContext({
  stepStatusMap: {},
  setStepStatusMap: (func: (previousState: StepStatusMap) => StepStatusMap) => {},
  progressSteps: [] as ComptProgressStep[],
  stepStatusBase: {},
});

export const useFormStepperContext = () => useContext(FormStepperContext);

interface FormStepperProps {
  stepStatusMap: StepStatusMap;
  setStepStatusMap: Dispatch<SetStateAction<StepStatusMap>>;
  progressSteps: ComptProgressStep[];
  stepStatusBase: StepStatusMap;
  children: ReactNode;
}

export const FormStepper = (props: FormStepperProps) => (
  <FormStepperContext.Provider
    value={{
      stepStatusMap: props.stepStatusMap,
      setStepStatusMap: props.setStepStatusMap,
      progressSteps: props.progressSteps,
      stepStatusBase: props.stepStatusBase,
    }}
  >
    {props.children}
  </FormStepperContext.Provider>
);

interface FormStepProps {
  stepStatus: StepStatus;
  children: ReactNode;
}

export const FormStep = (props: FormStepProps) => {
  const {stepStatus} = props;

  if (stepStatus === StepStatus.CURRENT) {
    return <>{props.children}</>;
  }

  return null;
};

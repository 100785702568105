import React from 'react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

interface RejectionReasonProps {
  rejectionReason: string;
}
export const ComptRejectionReason = ({rejectionReason}: RejectionReasonProps) => (
  <>
    <div className="flex items-center gap-x-1 mb-1">
      <ComptSvgIcon iconName="warning-icon" />
      <label
        data-testid={'rejection-reason-label'}
        className="flex items-center body2 text-color-body1"
      >
        Rejection reason
      </label>
    </div>
    <div className="bg-surface-secondary-tint p-200 mb-400 rounded-md">
      <p className="body1">{rejectionReason}</p>
    </div>
  </>
);

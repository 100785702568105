import React, {Dispatch, SetStateAction} from 'react';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {StipendExpense} from '@compt/types/stipend-expense';
import {useDeleteStipendExpenseMutation} from '@compt/app/services/api/stipend-expenses-slice';
import {
  ComptConfirmationModal,
  ModalType,
} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {useSearchParams} from 'react-router-dom';

interface DeleteStipendExpenseModalProps {
  expenseToDelete: StipendExpense | null;
  setExpenseToDelete: Dispatch<SetStateAction<StipendExpense | null>>;
}

export const DeleteStipendExpenseModal = (props: DeleteStipendExpenseModalProps) => {
  const [, setSearchParams] = useSearchParams();
  const {expenseToDelete, setExpenseToDelete} = props;
  const [deleteStipendExpense, {isLoading: isDeleting}] = useDeleteStipendExpenseMutation();

  const handleDeleteExpense = () => {
    if (expenseToDelete) {
      deleteStipendExpense(expenseToDelete).then(() => {
        setExpenseToDelete(null);
        triggerCustomToast('success', 'Successfully deleted expense');
        setSearchParams({});
      });
    }
  };

  return (
    <ComptConfirmationModal
      title="Delete Stipend Claim"
      content="Are you sure you want to delete this stipend claim? This action cannot be undone."
      open={!!expenseToDelete}
      setOpen={() => setExpenseToDelete(null)}
      modalType={ModalType.DESTRUCTIVE}
    >
      <div className="flex flex-row justify-end space-x-2">
        <ComptButton
          buttonType={ComptButtonType.OUTLINED}
          disabled={isDeleting}
          onClick={() => setExpenseToDelete(null)}
        >
          Cancel
        </ComptButton>
        <ComptButton
          buttonType={ComptButtonType.DESTRUCTIVE}
          disabled={isDeleting}
          onClick={handleDeleteExpense}
        >
          Delete
        </ComptButton>
      </div>
    </ComptConfirmationModal>
  );
};

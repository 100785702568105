import {comptApiSlice, ComptRTKTags} from '@compt/app/services/api/api-slice';
import {DEFAULT_OFFSET, DEFAULT_PAGE_LIMIT} from '@compt/constants';
import {VendorDiscoveryResults} from '@compt/types/vendor-discovery/vendor-discovery';

export const vendorDiscoveryApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVendorDiscovery: builder.query<VendorDiscoveryResults, {limit?: number; offset?: number}>({
      providesTags: [ComptRTKTags.VENDOR_DISCOVERY_LIST],
      query: ({limit = DEFAULT_PAGE_LIMIT, offset = DEFAULT_OFFSET}) => ({
        url: 'companies/vendor-discovery/',
        method: 'GET',
        params: {limit, offset},
      }),
    }),
  }),
});

export const {useGetVendorDiscoveryQuery} = vendorDiscoveryApiSlice;

const comptColors = {
  'stroke-critical': '#B3271E',
  'surface-tint': '#FAF6F1',
  'surface-disabled': '#D4D8D3',
  'modal-black': '#0C111D',
  'offer-orange': '#FFC36A',
  gray: {
    '000': '#FFFFFF',
    '050': '#FAFAFA',
    25: '#fcfcfd',
    50: '#f9fafb',
    100: '#EEF2ED',
    200: '#E5E9E4',
    300: '#D4D8D3',
    400: '#B1B4AF',
    500: '#91948F',
    600: '#717270',
    700: '#555854',
    800: '#373A36',
    900: '#171A16',
  },
  base: {
    white: '#FFFFFF',
    50: '#D6F3FF',
    100: '#B5DCE9',
    200: '#95C1D2',
    300: '#73A6B9',
    400: '#5892A7',
    500: '#3B7E95',
    600: '#2F7084',
    700: '#205B6E',
    800: '#114859',
    900: '#003341',
  },
  brand: {
    50: '#ECF5F7',
    100: '#D3E9EE',
    200: '#C0DFE6',
    300: '#ABD4DE',
    400: '#94C9D7',
    500: '#7DBFCE',
    600: '#64B3C5',
    700: '#4EA9BE',
    800: '#319EB5',
    900: '#0093AD',
  },
  green: {
    '050': '#EEF9E9',
    100: '#D5EFC8',
    200: '#B8E4A5',
    300: '#9BD97F',
    400: '#84D162',
    500: '#6DC846',
    600: '#5EB83E',
    700: '#4AA434',
    800: '#358F2A',
    900: '#006D17',
  },
  yellow: {
    '050': '#FEF6EE',
    100: '#F7DBB0',
    200: '#F5D3A0',
    300: '#F4CB8F',
    400: '#F2C581',
    500: '#E8B26A',
    600: '#E3A252',
    700: '#E1933E',
    800: '#D57B35',
    900: '#CE6E30',
  },
  red: {
    '050': '#FFFBF9',
    100: '#FDEDEE',
    200: '#FADEDC',
    300: '#F2B8B6',
    400: '#EC928E',
    500: '#E46962',
    600: '#DC362E',
    700: '#B3271E',
    800: '#8B1D18',
    900: '#60150F',
  },
};

// eslint-disable-next-line import/no-default-export
export default comptColors;

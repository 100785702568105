import React, {Dispatch, SetStateAction} from 'react';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {BusinessExpense} from '@compt/types/business-expenses/business-expense';
import {useDeleteBusinessExpenseMutation} from '@compt/app/services/api/business-expenses-slice';
import {
  ComptConfirmationModal,
  ModalType,
} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface DeleteExpenseModalProps {
  expenseToDelete: BusinessExpense | null;
  setExpenseToDelete: Dispatch<SetStateAction<BusinessExpense | null>>;
  setOpenExpenseSidePanel: Dispatch<SetStateAction<boolean>>;
}

export const DeleteBusinessExpenseModal = (props: DeleteExpenseModalProps) => {
  const {expenseToDelete, setExpenseToDelete, setOpenExpenseSidePanel} = props;
  const [deleteBusinessExpense, {isLoading: isDeleting}] = useDeleteBusinessExpenseMutation();

  const handleDeleteExpense = () => {
    if (expenseToDelete) {
      deleteBusinessExpense(expenseToDelete.id).then(() => {
        setExpenseToDelete(null);
        setOpenExpenseSidePanel(false);
        triggerCustomToast('success', 'Successfully deleted expense');
      });
    }
  };

  return (
    <ComptConfirmationModal
      title="Delete Business Expense"
      content="Are you sure you want to delete this business expense? This action cannot be undone."
      open={!!expenseToDelete}
      setOpen={() => setExpenseToDelete(null)}
      modalType={ModalType.DESTRUCTIVE}
    >
      <div className="flex flex-row space-x-2 justify-end">
        <ComptButton
          buttonType={ComptButtonType.OUTLINED}
          disabled={isDeleting}
          onClick={() => setExpenseToDelete(null)}
        >
          Cancel
        </ComptButton>
        <ComptButton
          buttonType={ComptButtonType.DESTRUCTIVE}
          disabled={isDeleting}
          onClick={handleDeleteExpense}
        >
          Delete
        </ComptButton>
      </div>
    </ComptConfirmationModal>
  );
};

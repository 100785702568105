import React from 'react';
import {ReactHookFormErrors} from '@compt/types/form/react-hook-form-helper-types';

export interface ComptFormErrorsProps {
  errors?: ReactHookFormErrors;
  label?: string;
  /**
   * React test library test id to be specified. This prop is called differently not to conflict with a
   * data-testid that might be configured at the top level of this component.
   */
  errorTestId?: string;
}

/**
 * Displays form errors.
 */
export const ComptFormErrors = ({errors, label, errorTestId}: ComptFormErrorsProps) => (
  <>
    {errors && (
      <div className="flex items-center h-fit" data-testid={errorTestId}>
        <p className="body3 text-stroke-critical">
          {errors && (errors.message?.toString() || `Invalid ${label}`)}
        </p>
      </div>
    )}
  </>
);

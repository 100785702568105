import React from 'react';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

type CardExpirationTextProps = {
  expirationDate: string | Date;
  cycleTypeDescription: string;
  accrualDescription?: string | null;
};

export const CardExpirationText = ({
  expirationDate,
  cycleTypeDescription,
  accrualDescription,
}: CardExpirationTextProps) => {
  const isMobileView = useIsMobileView();

  return (
    <>
      {isMobileView ? (
        <div className="pt-400">
          <p className="heading5 text-color-tertiary">
            {cycleTypeDescription} {accrualDescription ? accrualDescription : ''}
          </p>
          <p className="heading5 text-color-tertiary">
            {expirationDate
              ? `Expires ${formattedDate(expirationDate, DATE_FORMAT_OPTION['month dd yyyy'])}`
              : 'No Expiration'}
          </p>
        </div>
      ) : (
        <div className="flex pt-400 flex-row flex-wrap">
          <p className="heading5 text-color-tertiary pr-050 whitespace-nowrap">
            {cycleTypeDescription} {accrualDescription ? accrualDescription : ''} •{' '}
            {expirationDate
              ? `Expires ${formattedDate(expirationDate, DATE_FORMAT_OPTION['month dd yyyy'])}`
              : 'No Expiration'}
          </p>
        </div>
      )}
    </>
  );
};

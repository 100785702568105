import React from 'react';
import {Allotment} from '@compt/types/allotments';
import {PerkCategory} from '@compt/types/perk-category';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {EVENT_ACTION, EVENT_CATEGORY, EVENT_LABEL} from '@compt/utils/analytics/types';

export class ComptSelectCategorySidePanelController {
  getAvailableCategoryCards = (
    allotments: Allotment[],
    selectedAllotment: Allotment | undefined,
    onClick: (category: PerkCategory) => void,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    if (selectedAllotment) {
      return selectedAllotment.perk_categories;
    }
    const allAvailableCategories = allotments?.flatMap((allotment) => allotment.perk_categories);
    const uniqueAvailableCategories = allAvailableCategories.filter(
      (category, index) =>
        index === allAvailableCategories.findIndex((other) => category.id === other.id),
    );

    return uniqueAvailableCategories;
  };

  handleClose = (setOpen: (isOpen: boolean) => void) => {
    Analytics.sendEvent({
      action: EVENT_ACTION.CLICKED_CANCEL_EXPENSE,
      category: EVENT_CATEGORY.CLAIM_PAGE,
      label: EVENT_LABEL.CANCEL_CLAIM_SUBMISSION,
    });
    setOpen(false);
  };
}

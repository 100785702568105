import React, {Dispatch, SetStateAction} from 'react';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {useDeleteBusinessExpenseReportMutation} from '@compt/app/services/api/business-expense-reports-slice';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {useNavigate} from 'react-router-dom';
import {
  ComptConfirmationModal,
  ModalType,
} from '@compt/common/compt-confirmation-modal/compt-confirmation.modal';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface DeleteExpenseReportModalProps {
  reportInfo: BusinessExpenseReport | null;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const DeleteExpenseReportModal = (props: DeleteExpenseReportModalProps) => {
  const {open, setOpen, reportInfo} = props;

  const navigate = useNavigate();

  const [deleteBusinessExpenseReport, {isLoading: isDeleting}] =
    useDeleteBusinessExpenseReportMutation();

  const handleDeleteExpenseReport = () => {
    if (reportInfo?.id) {
      deleteBusinessExpenseReport(reportInfo.id).then(() => {
        setOpen(false);
        navigate('/business-expenses');
        triggerCustomToast('success', 'Successfully deleted report');
      });
    }
  };

  return (
    <ComptConfirmationModal
      title="Delete Expense Report"
      content="Are you sure you want to delete the expense report and all expenses within the report?
      This action cannot be undone."
      modalType={ModalType.DESTRUCTIVE}
      open={open}
      setOpen={() => setOpen(false)}
    >
      <div className="flex flex-row space-x-2 justify-end">
        <ComptButton
          buttonType={ComptButtonType.OUTLINED}
          disabled={isDeleting}
          onClick={() => setOpen(false)}
        >
          Cancel
        </ComptButton>
        <ComptButton
          buttonType={ComptButtonType.DESTRUCTIVE}
          disabled={isDeleting}
          onClick={handleDeleteExpenseReport}
        >
          Delete
        </ComptButton>
      </div>
    </ComptConfirmationModal>
  );
};

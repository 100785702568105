import React from 'react';

// Types
import {DateString} from '@compt/types/common/date-string';

// Components
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {getUTCDateFromString} from '@compt/utils/date-helpers';

interface ProgramDateProps {
  label: string;
  date: DateString | undefined;
  className?: string;
}

export const ProgramDate = (props: ProgramDateProps) => {
  function getFormattedDate(date: DateString) {
    const dateToFormat = getUTCDateFromString(date);

    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    };

    if (typeof dateToFormat === 'string') return '';

    return dateToFormat.toLocaleDateString('en-US', options).replace(',', '');
  }

  return (
    <div className={props.className}>
      <div className="flex gap-x-2 items-center mb-1">
        <ComptSvgIcon iconName="calendar" />
        <p className="label3 text-color-heading">{props.label}</p>
      </div>
      {!props.date && <p className="body1 text-color-tertiary italic">Not set</p>}
      {props.date && <p className="body1">{getFormattedDate(props.date)}</p>}
    </div>
  );
};

import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useCreateCustomNotificationMutation} from '@compt/app/services/api/custom-notifications-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {checkCustomNotificationIsUnique} from '../helpers/custom-notifications.helper';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';

// Types
import {CustomNotification, CustomNotificationFieldValues} from '@compt/types/custom-notifications';
import {CustomNotificationsForm} from './custom-notifications-form';
import {Company} from '@compt/types/company';

interface CreateCustomNotificationsSidePanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  company: Company;
  existingNotifications: CustomNotification[];
}

export const CreateCustomNotificationsSidePanel = (
  props: CreateCustomNotificationsSidePanelProps,
) => {
  const [createNotification, {isLoading: isSubmitting}] = useCreateCustomNotificationMutation();

  const hasSlack = props.company.has_slack;

  const formMethods = useForm<CustomNotificationFieldValues>();

  function onSubmit(form: CustomNotificationFieldValues) {
    if (!props.company.id || !props.existingNotifications) {
      triggerCustomToast('error', 'There was a problem saving your custom notification.');
      return;
    }

    const submission: Partial<CustomNotification> = {
      ...form,
      company: props.company.id,
      notification_channel: form.notification_channel.id,
      notification_type: form.notification_type.type,
    };

    if (!checkCustomNotificationIsUnique(props.existingNotifications, submission)) {
      formMethods.setError('notification_type', {
        message: `You already have a notification with that type for the selected channel. 
          Please update the type or channel to be unique.`,
      });
      return;
    }

    createNotification(submission).then((result) => {
      if ('error' in result) {
        triggerCustomToast('error', 'There was a problem saving your custom notification.');
        return;
      }

      triggerCustomToast('success', 'Successfully created new custom notification');
      formMethods.reset({});
      formMethods.reset({enabled: false});
      props.setOpen(false);
    });
  }

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header
        title="Add custom notification"
        setOpen={() => {
          props.setOpen(false);
          formMethods.reset();
        }}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <CustomNotificationsForm formMethods={formMethods} hasSlack={hasSlack} />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex flex-col">
          <ComptCheckboxField
            name="enabled"
            value={false}
            label="Enable custom notification"
            register={formMethods.register}
            className="mb-200"
          />
          <div className="flex space-x-200">
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              type="submit"
              className="w-full mr-1"
              disabled={!formMethods.formState.isValid || isSubmitting}
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              Save
            </ComptButton>
            <ComptButton
              buttonType={ComptButtonType.OUTLINED}
              type="reset"
              onClick={() => {
                props.setOpen(false);
                formMethods.reset({});
                formMethods.reset({enabled: false});
              }}
              className="w-full mr-1"
            >
              Cancel
            </ComptButton>
          </div>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

import React, {useEffect} from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';
import {getTomorrowDateString, getUTCDateFromString} from '@compt/utils/date-helpers';

// Types
import {DEFAULT_CHAR_FIELD_MAX_LENGTH} from '@compt/constants';
import {
  RequestFieldType,
  RequestType,
} from '@compt/types/learning-development/learning-development-program';
import {SupportedCountriesType} from '@compt/utils/international-helpers';

// Components
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComputedAmountSection} from '@compt/pages/learning-page/components/request-form-side-panel/computed-amount-section';
import {EligibilityBreakdownOption} from '@compt/types/learning-development/eligibility-breakdown.types';
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {ProgramSummary} from '@compt/types/learning-development/common-types';

interface RequestFormField {
  id: number;
  field_name: string;
  sequence: number;
  field_type: RequestFieldType | string;
}

interface RequestFormContentProps {
  formMethods: UseFormReturn<any>;
  requestFields: RequestFormField[];
  requestType: RequestType;
  isPreview?: boolean;
  userCountryCode?: SupportedCountriesType;
  program: ProgramSummary;
  preApprovalRequest?: EmployeePreApprovalRequest;
}

export const RequestFormContent = (props: RequestFormContentProps) => {
  const {formMethods} = props;

  const watchedVendor = formMethods.watch('vendor');
  const watchedDescription = formMethods.watch('description');

  const renderComputedAmountSection = () => {
    if (
      props.isPreview ||
      (props.requestType === RequestType.PRE_APPROVAL &&
        props.program.eligibility !== EligibilityBreakdownOption.CASE_BY_CASE)
    ) {
      // TODO: Get values from Program/override in COMPT-5535
      return (
        <ComputedAmountSection maxAvailable={'500'} currency="USD" control={formMethods.control} />
      );
    }
    if (props.requestType === RequestType.REIMBURSEMENT) {
      if (!props.preApprovalRequest) {
        throw new Error('Must pass valid existing pre-approval request.');
      }
      const maxAvailableAmount: number = props.preApprovalRequest.request_data.Amount;
      if (!maxAvailableAmount) {
        // eslint-disable-next-line no-console
        console.warn('Did not get a valid max amount. Got: ', maxAvailableAmount);
        return null;
      }
      return (
        <ComputedAmountSection
          maxAvailable={`${maxAvailableAmount}`}
          currency="USD"
          control={formMethods.control}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (!props.preApprovalRequest?.request_data) return;
    formMethods.reset({
      vendor: props.preApprovalRequest?.request_data['Vendor name'],
      description: props.preApprovalRequest?.request_data['Description and reason'],
      amount: props.preApprovalRequest?.request_data.Amount,
      receipt_key: props.preApprovalRequest?.request_data['Upload document(s)'],
      expected_completion_date:
        props.preApprovalRequest?.request_data['Expected completion date'] ||
        getUTCDateFromString(getTomorrowDateString()),
    });
  }, [props.preApprovalRequest?.request_data, formMethods]);

  return (
    <>
      {/*  TODO: Handle custom request fields in COMPT-5697 */}
      <ComptTextField
        name="vendor"
        label="Vendor"
        control={formMethods.control}
        register={formMethods.register}
        validation={{
          required: 'Vendor is required',
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Vendor has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        errors={formMethods.formState.errors.vendor}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedVendor}
      />
      <ComptCurrencyField
        name="amount"
        label="Amount"
        control={formMethods.control}
        register={formMethods.register}
        errors={formMethods.formState.errors.amount}
        validation={{
          required: 'Amount is required',
          min: 0.01,
        }}
        placeholder="0.00"
        defaultCurrencyCode={props.userCountryCode}
        // TODO: get currency from Program/override in COMPT-5535 (data structure from COMPT-5533).
        givenCurrency={props.userCountryCode}
      />
      {renderComputedAmountSection()}
      <ComptTextAreaField
        name="description"
        label="Description and reason"
        placeholder="Course to improve my understanding of AI"
        validation={{
          required: 'Description and reason is required',
          maxLength: {
            value: DEFAULT_CHAR_FIELD_MAX_LENGTH,
            message: `Description has max ${DEFAULT_CHAR_FIELD_MAX_LENGTH} characters`,
          },
        }}
        register={formMethods.register}
        errors={formMethods.formState.errors.description}
        maxLength={DEFAULT_CHAR_FIELD_MAX_LENGTH}
        watchedValue={watchedDescription}
      />
      <ComptDatePickerField
        name="expected_completion_date"
        label={`${
          props.requestType === RequestType.PRE_APPROVAL
            ? 'Expected completion date'
            : 'Completion date'
        }`}
        control={formMethods.control}
        register={formMethods.register}
        validation={{required: 'Expected completion date is required'}}
        allowFutureDates
        errors={formMethods.formState.errors.expected_completion_date}
        startDate={
          props.requestType === RequestType.PRE_APPROVAL
            ? getUTCDateFromString(getTomorrowDateString()) || null
            : getUTCDateFromString(props.preApprovalRequest?.allotment?.start_date) || null
        }
      />
    </>
  );
};

import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import {isArray} from 'lodash';
import {DATE_FORMAT_OPTION, formattedDatetime} from '@compt/utils/date-helpers';
import {syncAttemptStatusFormats} from '@compt/types/team-roster-syncs/team-roster-syncs-status';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';

// Components
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';

// Types
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {TeamRosterSyncAttempt} from '@compt/types/team-roster-syncs/team-roster-syncs';

export class TeamRosterSyncAttemptTableController {
  getFilterConfiguration() {
    const statusOptions = Object.entries(syncAttemptStatusFormats)
      .map((status) => {
        const [name, formats] = status;
        const {statusName} = formats;
        return {id: name, name: statusName};
      })
      .filter(Boolean);

    return {
      status: {
        filterType: SelectFilter,
        label: 'Status',
        options: statusOptions,
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };
  }

  static getInitialFilterValues() {
    return {
      textSearch: [''],
      status: [],
    };
  }

  updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setSyncAttemptsQueryValues: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) {
      return;
    }

    const formattedFilter = formatFilterValuesOptions(filterValues, pagination, ordering);

    setSyncAttemptsQueryValues(formattedFilter);
    if (
      Array.isArray(filterValues?.status) &&
      filterValues?.status.length &&
      filterValues.status.length > 0
    ) {
      setFiltersApplied(true);
    } else if (
      filterValues?.textSearch &&
      filterValues?.textSearch?.length > 0 &&
      filterValues?.textSearch[0].length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }

  getColumnDefinition(onActionClicked: (syncAttempt: TeamRosterSyncAttempt) => void) {
    return {
      sync_attempt_id: {
        id: 'sync_attempt_id',
        name: 'Sync attempt ID',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p data-tag="allowRowEvents">{syncAttempt.id}</p>
        ),
        grow: 0.4,
        order: 1,
      },
      requesting_user: {
        id: 'requesting_user',
        name: 'Requesting user',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p data-tag="allowRowEvents">
            {syncAttempt.requesting_user ? syncAttempt.requesting_user.email : 'System'}
          </p>
        ),
        grow: 0.5,
        order: 2,
      },
      sync_source: {
        id: 'sync_source',
        name: 'Sync source',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p data-tag="allowRowEvents">{syncAttempt.sync_source}</p>
        ),
        grow: 0.4,
        order: 3,
      },
      status: {
        id: 'status',
        name: 'Status',
        selector: (syncAttempt: TeamRosterSyncAttempt) => {
          const statusFormat = syncAttemptStatusFormats[syncAttempt.status];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        grow: 0.5,
        order: 4,
      },
      errors: {
        id: 'errors',
        name: 'Attempt errors',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p
            data-tag="allowRowEvents"
            className="body3 compt-text--medium text-color-error"
            title={`${
              (syncAttempt.errors && syncAttempt.errors.length > 30 && syncAttempt.errors) || ''
            }`}
          >
            {syncAttempt.errors}
          </p>
        ),
        grow: 0.75,
        order: 5,
      },
      date_uploaded: {
        id: 'date_uploaded',
        name: 'Date uploaded',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p data-tag="allowRowEvents">
            {syncAttempt.upload_datetime
              ? formattedDatetime(
                  syncAttempt.upload_datetime,
                  DATE_FORMAT_OPTION['month dd yyyy hh:mm:ss'],
                )
              : ''}
          </p>
        ),
        grow: 0.5,
        order: 6,
      },
      date_processed: {
        id: 'date_processed',
        name: 'Date processed',
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p data-tag="allowRowEvents">
            {syncAttempt.processed_datetime
              ? formattedDatetime(
                  syncAttempt.processed_datetime,
                  DATE_FORMAT_OPTION['month dd yyyy hh:mm:ss'],
                )
              : ''}
          </p>
        ),
        grow: 0.5,
        order: 7,
      },
      view: {
        id: 'view',
        ignoreRowClick: true,
        selector: (syncAttempt: TeamRosterSyncAttempt) => (
          <p
            className="text-color-link cursor-pointer"
            onClick={() => onActionClicked(syncAttempt)}
          >
            View
          </p>
        ),
        grow: 0,
        order: 8,
      },
    };
  }
}

const formatFilterValuesOptions = (
  filterValues?: FilterValues,
  pagination?: Pagination | null,
  ordering?: string | null,
) => {
  const formattedFilter: FormattedFilterObject = {};

  if (
    isArray(filterValues?.status) &&
    filterValues?.status.length &&
    filterValues.status.length > 0
  ) {
    formattedFilter['status__in'] = filterValues?.status.map((status) => status.id).join(',');
  }

  if (filterValues?.textSearch && filterValues?.textSearch?.length > 0) {
    [formattedFilter['search']] = filterValues.textSearch;
  }

  formattedFilter.limit = MAX_ITEMS_PER_PAGE;

  if (pagination) {
    formattedFilter.offset = MAX_ITEMS_PER_PAGE * Math.max(pagination.page - 1, 0);
    formattedFilter.page = pagination.page;
  } else {
    formattedFilter.page = 1;
    formattedFilter.offset = 0;
  }

  if (ordering) {
    formattedFilter.ordering = ordering;
  }

  return formattedFilter;
};

import React, {ReactNode} from 'react';

// Hooks and methods
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';

// Components
import {ComptProgressStepper} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {twMerge} from 'tailwind-merge';

interface LearningDevelopmentStepperProps {
  children: ReactNode;
  isFormDirty?: boolean;
  readOnly?: boolean;
  className?: string;
}

export const LearningDevelopmentStepper = (props: LearningDevelopmentStepperProps) => {
  const {progressSteps} = useFormStepperContext();

  return (
    <div className={twMerge(`flex px-600 gap-x-24 ${props.className}`)}>
      <ComptProgressStepper
        steps={progressSteps}
        readOnly={props.readOnly}
        isFormDirty={props.isFormDirty}
      />
      <div className="flex-col w-2/3 max-w-2xl">{props.children}</div>
    </div>
  );
};

import React, {HTMLAttributes} from 'react';

export interface ComptProgressBarProps extends HTMLAttributes<HTMLElement> {
  percentage: number;
  className?: string;
}

export const ComptProgressBar = (props: ComptProgressBarProps) => {
  const {percentage, className} = props;

  let completionPercentage = '';

  // Tailwind requires full class names. String interpolation will not work (i.e., `w-[${percentage}%]`).
  if (percentage > 0 && percentage <= 5) {
    completionPercentage = 'w-[5%]';
  } else if (percentage > 5 && percentage <= 10) {
    completionPercentage = 'w-[10%]';
  } else if (percentage > 10 && percentage <= 20) {
    completionPercentage = 'w-[20%]';
  } else if (percentage > 20 && percentage <= 30) {
    completionPercentage = 'w-[30%]';
  } else if (percentage > 30 && percentage <= 40) {
    completionPercentage = 'w-[40%]';
  } else if (percentage > 40 && percentage <= 50) {
    completionPercentage = 'w-[50%]';
  } else if (percentage > 50 && percentage <= 60) {
    completionPercentage = 'w-[60%]';
  } else if (percentage > 60 && percentage <= 70) {
    completionPercentage = 'w-[70%]';
  } else if (percentage > 70 && percentage <= 80) {
    completionPercentage = 'w-[80%]';
  } else if (percentage > 80 && percentage <= 90) {
    completionPercentage = 'w-[90%]';
  } else if (percentage > 90 && percentage <= 95) {
    completionPercentage = 'w-[95%]';
  } else if (percentage > 95 && percentage < 100) {
    completionPercentage = 'w-[99%]';
  } else if (percentage === 100) {
    completionPercentage = 'w-[100%]';
  }

  return (
    <div className={`compt-progress-bar ${className}`}>
      <div className="bg-gray-200 rounded-xl">
        <div className="relative h-6 flex items-center justify-center">
          <div
            className={`absolute top-0 bottom-0 left-0
            rounded-xl ${completionPercentage}`}
            style={{backgroundColor: '#5EB83E'}}
          ></div>
        </div>
      </div>
    </div>
  );
};

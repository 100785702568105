import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {FileUploadState} from '@compt/types/file-upload';
import {FeatureFlags, featureEnabled} from '@compt/utils/feature-flags-helper';
import {formatBytes} from '@compt/utils/image-helpers';

export interface UploadedFileComponentProps {
  controller: any;
  fileUploadState: FileUploadState;
}

export const UploadedFileComponent = (props: UploadedFileComponentProps) => {
  const {fileUploadState, controller} = props;

  return (
    <div className="flex full mx-4 my-5">
      <ComptSvgIcon
        iconName="document-icon"
        labelHidden={false}
        ariaLabel="document-icon"
        className="rounded-full h-8 w-8"
      />
      <div className="flex flex-col w-full ml-2">
        <div className="flex w-full">
          <div className="flex justify-between w-full">
            <div className="flex flex-col">
              <p className="body3 compt-text--medium text-color-body1">
                {fileUploadState.fileName ??
                  `Uploading${
                    featureEnabled(FeatureFlags.ADP_INTEGRATION) ? ' and scanning' : ''
                  }...`}
              </p>
              <p className="body3 text-color-body2 text-left">
                {formatBytes(fileUploadState.fileSize) ?? ''}
              </p>
            </div>
            <button
              className="flex"
              onClick={() => {
                controller.deleteFile();
              }}
            >
              <ComptSvgIcon iconName="trash-icon" labelHidden={false} ariaLabel="trash-icon" />
            </button>
          </div>
        </div>
        {fileUploadState.isUploading && (
          <UploadInProgressComponent uploadProgress={fileUploadState.uploadProgress} />
        )}
      </div>
    </div>
  );
};

type UploadInProgressComponentProps = {
  uploadProgress: number;
};

const UploadInProgressComponent = (props: UploadInProgressComponentProps) => (
  <div className="flex flex-row w-full items-center justify-center mb-5">
    <div className="relative h-2 bg-gray-200 rounded w-full">
      <div className="absolute h-full w-full left-0 bg-gray-200 rounded">
        <div
          className="absolute h-full left-0 bg-base-600 rounded"
          style={{width: `${props.uploadProgress}%`}}
        ></div>
      </div>
    </div>
    <div className="ml-5">
      <p className="body3 compt-text--medium text-color-body1 text-right">
        {props.uploadProgress}%
      </p>
    </div>
  </div>
);

import {Allotment} from '@compt/types/allotments';

import {CompanyValue} from '@compt/types/company';
import {
  GiveTeamRecognitionRequestBody,
  TeamRecognitionUser,
} from '@compt/types/team-recognition/team-recognition';
import {FieldValues, UseFormResetField} from 'react-hook-form';
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';
import {ComptRTKTags} from '@compt/app/services/api/api-slice';
import {GiveTeamRecognitionMutationData} from '@compt/app/services/api/team-recognition-slice';
import {AsyncTaskResponse} from '@compt/types/common/async-results';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

export type GiveRecognitionMutation = MutationTrigger<
  MutationDefinition<
    GiveTeamRecognitionMutationData,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      NonNullable<unknown>,
      FetchBaseQueryMeta
    >,
    ComptRTKTags,
    AsyncTaskResponse,
    'api'
  >
>;

export interface GiveTeamRecognitionFormSchema extends FieldValues {
  recognitionAllotment: Allotment | null;
  recognitionAmountInput?: number;
  recognitionDescription: string;
  recognitionAmountDropdown?: number | null;
  recognitionCompanyValue?: CompanyValue;
  recognitionRecipients: TeamRecognitionUser[];
  recognitionCCRecipients: TeamRecognitionUser[];
  shareRecognition: boolean;
}

export const convertFormDataToRequestBody = (
  formData: GiveTeamRecognitionFormSchema,
): GiveTeamRecognitionRequestBody => ({
  amount: formData.recognitionAmountInput || formData.recognitionAmountDropdown || 0,
  cc_emails: formData.recognitionCCRecipients
    ? formData.recognitionCCRecipients.map((recipient) => recipient.email)
    : [],
  note: formData.recognitionDescription,
  selected_company_value_id: formData.recognitionCompanyValue?.id,
  share_recognition: formData.shareRecognition,
  user_emails: formData.recognitionRecipients.map((recipient) => recipient.email),
});

export class GiveTeamRecognitionFormController {
  /**
   * Returns the given allotment's cycle's company values if there is at least one.
   * @param allotment
   */
  static getSelectedAllotmentCompanyValues(allotment: Allotment | undefined | null) {
    if (allotment && allotment.cycle.company_values && allotment.cycle.company_values.length > 0) {
      return allotment.cycle.company_values;
    }

    return null;
  }

  static resetForm(
    resetField: UseFormResetField<GiveTeamRecognitionFormSchema>,
    resetAllotment = false,
    selectedAllotment?: Allotment | null,
  ) {
    // Reset all fields but the dropdown
    const valuesToReset: (keyof GiveTeamRecognitionFormSchema)[] = [
      'recognitionRecipients',
      'recognitionAmountInput',
      'recognitionAmountDropdown',
      'recognitionDescription',
      'recognitionCCRecipients',
    ];

    if (resetAllotment) {
      valuesToReset.push('recognitionAllotment');
    }

    if (selectedAllotment && this.getSelectedAllotmentCompanyValues(selectedAllotment)) {
      valuesToReset.push('recognitionCompanyValue');
    }

    valuesToReset.forEach((toReset) => {
      if ((toReset as string) === 'recognitionCCRecipients') {
        resetField(toReset as string, {defaultValue: []});
      } else if ((toReset as string) === 'recognitionAmountInput') {
        resetField(toReset as string, {defaultValue: 0});
      } else if ((toReset as string) === 'recognitionAmountDropdown') {
        resetField(toReset as string, {defaultValue: null});
      } else {
        resetField(toReset as string);
      }
    });
  }

  static resetAmountInputs(
    resetField: UseFormResetField<GiveTeamRecognitionFormSchema>,
    inputType: string,
    previousValue?: number,
  ) {
    if (inputType === 'recognitionAmountInput') {
      resetField('recognitionAmountInput', {defaultValue: previousValue});
    } else {
      resetField('recognitionAmountInput', {defaultValue: 0});
    }
    resetField('recognitionAmountDropdown', {defaultValue: null});
  }

  static submitRecognition(
    formData: GiveTeamRecognitionFormSchema,
    mutation: GiveRecognitionMutation,
    onSuccess: (taskId: string) => void,
    onError?: (error: unknown) => void,
  ) {
    if (formData.recognitionAllotment === null) {
      throw Error('Allotment is required.');
    }
    const allotmentId = formData.recognitionAllotment.id ? +formData.recognitionAllotment.id : -1;
    if (allotmentId === -1) {
      throw Error(`Invalid allotment id: ${allotmentId}`);
    }

    mutation({
      allotmentId,
      body: convertFormDataToRequestBody(formData),
    }).then((result) => {
      if ('error' in result) {
        triggerCustomToast('error', 'There was a problem submitting recognition');
        console.error('Error submitting team recognition', result.error);
        if (onError) {
          onError(result.error);
        }
      } else {
        onSuccess(result.data.task_id);
      }
    });
  }

  static getSelectedAllotment(allotmentId: string | null, teamAllotments?: Allotment[]) {
    if (teamAllotments && teamAllotments.length > 0 && allotmentId) {
      const allotment = teamAllotments.find((allotment) => allotment.id === +allotmentId);
      return allotment;
    }
  }
}

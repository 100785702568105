import {useEffect, useState} from 'react';

const MOBILE_DEFAULT_WIDTH = 640;

export const useIsMobileView = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= MOBILE_DEFAULT_WIDTH;
  return isMobile;
};

/**
 * This function is used to close sidebar upon page selection on mobile.
 * You must pass in setSidebarOpen to avoid dependency cycles within this file.
 */
export const useHandleMobileSideNav = (
  setSidebarOpen: (func: (previousState: boolean) => boolean) => void,
) => {
  const isMobileView = useIsMobileView();

  function handleMobileSideNav() {
    if (!isMobileView) {
      return;
    }

    setSidebarOpen((prevState) => !prevState);
  }

  return handleMobileSideNav;
};

import React, {useEffect, useState} from 'react';
import {useSwiper} from 'swiper/react';

interface ComptCarouselNavProps {
  errors: boolean;
  numberOfSlides: number;
}

/** 
  Handles slide navigation based on errors, and slides added  
  Needs to be used as a child within the ComptSwiper component
*/
export const ComptCarouselNav = (props: ComptCarouselNavProps) => {
  const swiper = useSwiper();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    setInitialized(true);
  }, []);

  // Navigate back to receipt slide when error occurs
  useEffect(() => {
    if (swiper.activeIndex !== 0 && props.errors && initialized) {
      swiper?.slideTo(0);
    }
  }, [swiper.activeIndex, swiper, props.errors, initialized]);

  // Navigate to the newly added slide after pressing "Add Support Documents"
  useEffect(() => {
    if (props.numberOfSlides > 0 && initialized) {
      if (swiper && !swiper.destroyed) {
        swiper?.slideTo(props.numberOfSlides);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper, props.numberOfSlides]);
  return <div></div>;
};

import React, {HTMLAttributes, Fragment, SetStateAction, Dispatch, ReactElement} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {ComptButtonIcon} from '../compt-button/compt-button';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export enum ModalType {
  'PRIMARY',
  'DESTRUCTIVE',
}

export interface ComptConfirmationModalProps extends HTMLAttributes<HTMLElement> {
  title?: string;
  content?: string;
  secondaryButtonComponent?: ReactElement;
  open: boolean;
  setOpen: Dispatch<SetStateAction<unknown>>;
  modalType: ModalType;
}

const defaults: ComptConfirmationModalProps = {
  title: '',
  content: '',
  open: false,
  setOpen: () => {},
  modalType: ModalType.PRIMARY,
};

export const ComptConfirmationModal = (props: ComptConfirmationModalProps = defaults) => {
  const iconWithColor =
    props.modalType === ModalType.PRIMARY ? 'yellow-folder-icon' : 'red-circle-trash-icon';

  return (
    <>
      <Transition.Root show={props.open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={props.setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 backdrop-blur-sm bg-opacity-75 bg-white transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className={`
              flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0
            `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`
                  relative transform bg-white
                  overflow-hidden rounded-xl
                  backdrop:blur-sm px-4 pb-4 pt-5
                  text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 border-2
                `}
                >
                  <div className="compt-modal flex">
                    <div className="w-full">
                      <div className="flex justify-between mb-8 ">
                        <div className="pr-4">
                          <ComptSvgIcon iconName={iconWithColor} />
                        </div>
                        <div className="flex flex-col space-y-2">
                          <p className="body1">{props.title}</p>
                          <p className="body3 text-color-body2">{props.content}</p>
                        </div>
                        <button className="flex pl-2 h-0" onClick={() => props.setOpen(false)}>
                          <ComptSvgIcon iconName={ComptButtonIcon.X} />
                        </button>
                      </div>
                      {props.children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {COMPT_APP_ROOT_PATH, KEEP_UNUSED_DATA_TIME} from '@compt/utils/consts';
import {SecurityHelpers} from '@compt/utils/security-helpers';

const currentEnv = import.meta.env.MODE;

/**
 * Used for endpoints that start in the website root /.
 */
export const comptRootSlice = createApi({
  reducerPath: 'compt-root',
  keepUnusedDataFor: currentEnv === 'test' ? 0 : KEEP_UNUSED_DATA_TIME,
  baseQuery: fetchBaseQuery({
    baseUrl: COMPT_APP_ROOT_PATH,
    prepareHeaders: (headers) => {
      headers.set('X-CSRFToken', SecurityHelpers.getCSRFToken());
    },
  }),
  endpoints: (builder) => ({}),
});

import React from 'react';
import {Controller, FieldPathByValue, FieldValues} from 'react-hook-form';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {ComptDatePicker} from '../compt-date-picker/compt-date-picker';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';

export interface ComptDatePickerFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  startDate?: Date | null;
  endDate?: Date | null;
  allowFutureDates?: boolean;
  'data-testid'?: string;
  readOnly?: boolean;
}

export const ComptDatePickerField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptDatePickerFieldProps<TFieldValues, TName>,
) => {
  const field = (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.validation}
      render={({field}) => (
        <ComptDatePicker
          id={props.id}
          allowFutureDates={props.allowFutureDates}
          placeholder={props.placeholder}
          invalid={!!props.errors}
          value={field.value}
          onChange={field.onChange}
          data-testid={props['data-testid']}
          disabled={props.disabled}
          startDate={props.startDate}
          endDate={props.endDate}
          initialValue={props.initialValue}
          inputRef={field.ref}
        />
      )}
    />
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};

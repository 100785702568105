import React, {Dispatch, SetStateAction, useEffect} from 'react';
import {ComptSidePanel} from '../compt-side-panel/compt-side-panel';
import {ComptTextField} from '../forms/compt-text-field/compt-text-field';
import {FieldValues, useForm} from 'react-hook-form';
import {ComptButton, ComptButtonType} from '../compt-button/compt-button';
import {useUpdatePasswordMutation} from '@compt/app/services/api/accounts-slice';
import {PasswordChange} from '@compt/types/account';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface ChangePasswordFormFieldValues extends FieldValues {
  old_password: string;
  password: string;
  confirm_password: string;
}

interface ChangePasswordFormProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChangePasswordForm = (props: ChangePasswordFormProps) => {
  const {open} = props;
  const formMethods = useForm<ChangePasswordFormFieldValues>({
    mode: 'onSubmit',
  });
  const {watch, formState} = formMethods;

  useEffect(() => {
    if (!open) {
      formMethods.reset({old_password: '', password: '', confirm_password: ''});
    }
  }, [open, formMethods]);

  const [updatePassword, {isLoading: isUpdating}] = useUpdatePasswordMutation();

  function onSubmit(form: ChangePasswordFormFieldValues) {
    const submission = {...form} as PasswordChange;
    updatePassword(submission).then((data) => {
      if ('error' in data && 'data' in data.error) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorData = data.error.data as any;
        triggerCustomToast('error', 'There was a problem updating your password');
        if ('old_password' in errorData) {
          formMethods.setError('old_password', {message: errorData.old_password});
        }
        if ('password' in errorData) {
          formMethods.setError('password', {
            message:
              'Password must contain at least 8 characters and must contain characters from 3 of the ' +
              'following groups: uppercase letters, lowercase letters, numbers, and punctuation ' +
              'characters.',
          });
        }
        return;
      }
      formMethods.reset({old_password: '', password: '', confirm_password: ''});
      props.setOpen(false);
      triggerCustomToast('success', 'Successfully submitted password change');
    });
  }

  return (
    <ComptSidePanel open={props.open}>
      <ComptSidePanel.Header
        title="Change password"
        setOpen={props.setOpen}
        headerIcon={{id: 'password-lock-icon'}}
      />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <ComptTextField
          name="old_password"
          label="Old password"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'Old password is required'}}
          errors={formState.errors.old_password}
          secret
        />
        <ComptTextField
          name="password"
          label="New password"
          subLabel={`Passwords must be at least 8 characters and contain at least one digit (0-9),
          letter (A-Z, a-z), and special character (e.g., #$%+?).`}
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'New password is required'}}
          errors={formState.errors.password}
          secret
        />
        <ComptTextField
          name="confirm_password"
          label="Confirm new password"
          control={formMethods.control}
          register={formMethods.register}
          validation={{
            required: true,
            validate: (value: string) => {
              if (value !== watch('password')) {
                return 'New password and confirmed password must match';
              }
            },
          }}
          errors={formState.errors.confirm_password}
          secret
        />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <ComptButton
          data-testid="change-password-submit-button"
          buttonType={ComptButtonType.PRIMARY}
          className="min-w-fit"
          disabled={!formMethods.formState.isValid || isUpdating}
          onClick={formMethods.handleSubmit((form) => onSubmit(form))}
          onKeyDown={(e: React.KeyboardEvent<HTMLButtonElement>) => {
            e.key === 'Enter' && e.preventDefault();
          }}
        >
          Save changes
        </ComptButton>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

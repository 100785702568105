import React from 'react';
import {BusinessExpenseReport} from '@compt/types/business-expenses/business-expense-report';
import {
  SupportedCountriesType,
  formatCurrencyFromCountryCode,
} from '@compt/utils/international-helpers';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {useIsMobileView} from '@compt/utils/mobile-helpers';

type TotalAmountProps = {
  reportInfo: BusinessExpenseReport;
  userCountryCode: SupportedCountriesType;
  expenseListExists: boolean;
  forReview?: boolean;
};

type ReportTextProps = {
  label: string;
  text: string;
};

const ReportText = (props: ReportTextProps) => {
  const {label, text} = props;
  return (
    <div className="flex flex-row mt-2 text-center">
      <p className="heading2 compt-text--semi-bold mr-1">{label}</p>
      <p className="heading2 compt-text--regular">{text}</p>
    </div>
  );
};

export const ReportFooterText = (props: TotalAmountProps) => {
  const {reportInfo, expenseListExists, forReview} = props;
  const isMobileView = useIsMobileView();

  if (!expenseListExists) {
    return null;
  }

  return (
    <div className={`mt-6 ${isMobileView && 'pb-900'}`}>
      <ReportText label="Total:" text={formatCurrencyFromCountryCode(reportInfo.total, 'US')} />
      {!!reportInfo.submitted_date && !forReview && (
        <ReportText
          label="Submitted On:"
          text={formattedDate(reportInfo.submitted_date, DATE_FORMAT_OPTION['month dd yyyy'])}
        />
      )}
      {!!reportInfo.processed_date && (
        <ReportText
          label="Payroll Processed Date:"
          text={formattedDate(reportInfo.processed_date, DATE_FORMAT_OPTION['month dd yyyy'])}
        />
      )}
    </div>
  );
};

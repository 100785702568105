import {FieldValues} from 'react-hook-form';

export interface CustomNotification {
  id: number;
  company: number;
  enabled: boolean;
  notification_type: CustomNotificationTypeKey;
  notification_channel: NotificationChannel;
  subject_line: string;
  custom_message: string;
}

export enum NotificationChannel {
  EMAIL = 'EMAIL',
  SLACK = 'SLACK',
}

export type CustomNotificationTypeKey = keyof typeof CUSTOM_NOTIFICATION_TYPES;
export type CustomNotificationTypeItem = {
  type: CustomNotificationTypeKey;
  emailOnly: boolean;
  displayName: string;
  description: string;
};

export const CUSTOM_NOTIFICATION_TYPES: {
  [key: string]: CustomNotificationTypeItem;
} = {
  'accounts/claim_account': {
    type: 'accounts/claim_account',
    emailOnly: true,
    displayName: 'Employee invitation',
    description: 'When an employee gets invited',
  },
  'expenses/stipend_beginning': {
    type: 'expenses/stipend_beginning',
    emailOnly: false,
    displayName: 'New stipend',
    description: 'When an employee receives a new stipend or the balance refreshes',
  },
  'expenses/running_out': {
    type: 'expenses/running_out',
    emailOnly: false,
    displayName: 'Stipend reminder: 6 days out',
    description: '6 days before a stipend is about to expire',
  },
  'expenses/one_day_out': {
    type: 'expenses/one_day_out',
    emailOnly: false,
    displayName: 'Stipend reminder: Last day',
    description: 'On the morning of the stipend expiration date',
  },
  'expenses/annual_and_quarterly_reminders': {
    type: 'expenses/annual_and_quarterly_reminders',
    emailOnly: false,
    displayName: 'Stipend reminder: Mid-quarter',
    description: 'Feb 15, May 15, Aug 15, and Nov 15 quarterly and annual stipend reminders',
  },
  'expenses/long_reminders': {
    type: 'expenses/long_reminders',
    emailOnly: false,
    displayName: 'Stipend reminder: 3.5 months out',
    description: 'Sep 15 semiannual and annual stipend reminder',
  },
  'expenses/semiannual_reminders': {
    type: 'expenses/semiannual_reminders',
    emailOnly: false,
    displayName: 'Stipend reminder: 2.5 months Out',
    description: 'April 15 and Oct 15 semiannual stipend reminders',
  },
};

export const NOTIFICATION_CHANNEL_OPTIONS = {
  [NotificationChannel.EMAIL]: {
    id: NotificationChannel.EMAIL,
    name: 'Email',
  },
  [NotificationChannel.SLACK]: {
    id: NotificationChannel.SLACK,
    name: 'Slack',
  },
};

export interface CustomNotificationFieldValues extends FieldValues {
  notification_channel: {id: NotificationChannel; name: string};
  notification_type: CustomNotificationTypeItem;
  subject_line: string;
  custom_message: string;
  enabled: boolean;
}

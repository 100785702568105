import React, {Dispatch, Fragment, SetStateAction} from 'react';
import {Menu, Transition} from '@headlessui/react';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {Link} from 'react-router-dom';

interface ReportPageActionMenuProps {
  isMobile: boolean;
  openReportActionMenu: boolean;
  setOpenReportActionMenu: Dispatch<SetStateAction<boolean>>;
  setOpenEditSidePanel: Dispatch<SetStateAction<boolean>>;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
}

export const ReportPageActionMenu = (props: ReportPageActionMenuProps) => (
  <div>
    <Menu as="div" className="relative inline-block text-left w-full">
      <Menu.Button className="w-full">
        <ComptButton
          buttonType={
            props.openReportActionMenu || props.isMobile
              ? ComptButtonType.SECONDARY
              : ComptButtonType.BORDERLESS
          }
          onClick={() => {
            props.setOpenReportActionMenu(!props.openReportActionMenu);
          }}
          className={`${
            props.openReportActionMenu ? 'bg-surface-secondary-tint' : ''
          } pl-4 pr-3 py-2 w-full`}
          textClassName="w-full"
        >
          <div className="flex flex-row gap-2 w-full">
            <p className="body2 text-color-body1 flex-grow">Options</p>
            <ComptSvgIcon
              iconName="chevron-down-icon"
              className={`${
                props.openReportActionMenu && 'rotate-180'
              } hover:bg-surface-secondary-tint rounded-full
              flex justify-center items-center`}
              labelHidden={false}
              ariaLabel={props.openReportActionMenu ? 'chevron-up' : 'chevron-down'}
              svgProp={{width: '18px', height: '18px'}}
            />
          </div>
        </ComptButton>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={
            'right-0 z-10 mt-2 w-full min-w-max origin-top-right ' +
            'rounded-md bg-gray-25 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ' +
            `${props.isMobile ? 'float' : 'absolute'}`
          }
        >
          <Menu.Item>
            <div className="hover:bg-gray-200">
              <ComptButton
                buttonType={ComptButtonType.BORDERLESS}
                onClick={() => {
                  props.setOpenEditSidePanel(true);
                  props.setOpenReportActionMenu(false);
                }}
                className="justify-start"
              >
                <p className="body2 text-color-body1">Edit</p>
              </ComptButton>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="hover:bg-gray-200">
              <Link to="/business-expenses">
                <ComptButton
                  buttonType={ComptButtonType.BORDERLESS}
                  onClick={() => {
                    props.setOpenReportActionMenu(false);
                  }}
                >
                  <p className="body2 text-color-body1">Save as draft</p>
                </ComptButton>
              </Link>
            </div>
          </Menu.Item>
          <Menu.Item>
            <div className="hover:bg-gray-200">
              <ComptButton
                buttonType={ComptButtonType.BORDERLESS}
                onClick={() => {
                  props.setOpenDeleteModal(true);
                  props.setOpenReportActionMenu(false);
                }}
                className="justify-start"
              >
                <p className="body2 text-color-error">Delete</p>
              </ComptButton>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  </div>
);

import {UserSession} from '@compt/types/account';
import {AnalyticsTool, SendEvent, SendEventProps} from '@compt/utils/analytics/types';
import {GoogleAnalytics} from '@compt/utils/analytics/vendors/google-analytics';
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {PostHog} from '@compt/utils/analytics/vendors/post-hog';

export class Analytics {
  static helpScout = new HelpScout();
  static googleAnalytics = new GoogleAnalytics();
  static postHog = new PostHog();
  static implementedAnalytics: AnalyticsTool[] = [
    Analytics.helpScout,
    Analytics.googleAnalytics,
    Analytics.postHog,
  ];
  /**
   * Whether to log debug messages for each event.
   */
  static debugLogging = false;

  /**
   * Use this method for all analytics calls, since it wraps our various analytics tools in one invocation.
   */
  static sendEvent: SendEvent = (event: SendEventProps) => {
    try {
      if (Analytics.debugLogging) {
        // eslint-disable-next-line no-console
        console.debug('Analytics.sendEvent', {event});
      }

      this.implementedAnalytics.forEach((analytic: AnalyticsTool) => {
        analytic.sendEvent(event);
      });
    } catch (error) {
      console.error('Analytics.sendEvent', error);
    }
  };

  static triggerPageView = (pathname: string, search: string) => {
    if (Analytics.debugLogging) {
      // eslint-disable-next-line no-console
      console.debug('Analytics.triggerPageView', {pathname, search});
    }

    this.implementedAnalytics.forEach((analytic: AnalyticsTool) => {
      analytic.sendPageView(pathname, search);
    });
  };

  static identify = (user: UserSession) => {
    if (Analytics.debugLogging) {
      // eslint-disable-next-line no-console
      console.debug('Analytics.identify', user);
    }

    this.implementedAnalytics.forEach((analytic: AnalyticsTool) => {
      analytic.identify(user);
    });
  };
}

import React, {ReactElement, createContext, useState} from 'react';

export type ErrorRows = {[key: number]: string[]};

export type BusinessExpensePageContextProps = {
  errorRows: ErrorRows | null;
  setErrorRows: (func: (previousState: ErrorRows | null) => ErrorRows) => void;
};

export const BusinessExpensePageContext = createContext<BusinessExpensePageContextProps>({
  errorRows: null,
  setErrorRows: (func: (previousState: ErrorRows | null) => ErrorRows) => {},
});

export const BusinessExpensePageContextProvider = ({children}: {children: ReactElement}) => {
  const [errorRows, setErrorRows] = useState<ErrorRows | null>(null);

  return (
    <BusinessExpensePageContext.Provider
      value={{
        errorRows,
        setErrorRows,
      }}
    >
      {children}
    </BusinessExpensePageContext.Provider>
  );
};

import React from 'react';
import {formatCurrency} from '@compt/utils/international-helpers';
import {useWatch} from 'react-hook-form';

type ComputedAmountSectionProps = {
  selectedAllotmentBalance: string | undefined;
  selectedAllotmentCurrency: string | undefined;
  control: any;
};

export const ComputedAmountSection = (props: ComputedAmountSectionProps) => {
  const {selectedAllotmentBalance, selectedAllotmentCurrency, control} = props;
  const watchedAmount = useWatch({control, name: 'amount_of_expense'});

  if (!selectedAllotmentBalance || !selectedAllotmentCurrency) {
    return <></>;
  }

  const balanceAsNum = parseFloat(selectedAllotmentBalance);
  const watchedAmountAsNum = parseFloat(watchedAmount) || 0;

  const amountCovered = Math.min(balanceAsNum, watchedAmountAsNum);
  const amountRemaining = balanceAsNum - amountCovered;

  const amountValues = [
    {label: 'Max Available', amount: formatCurrency(balanceAsNum, selectedAllotmentCurrency)},
    {
      label: 'Amount Covered',
      amount: formatCurrency(amountCovered, selectedAllotmentCurrency),
    },
    {label: 'Amount Remaining', amount: formatCurrency(amountRemaining, selectedAllotmentCurrency)},
  ];

  return (
    <div className="my-400">
      {amountValues.map((amount, i) => (
        <AmountText key={`amount-${i}`} label={amount.label} amount={amount.amount} />
      ))}
    </div>
  );
};

const AmountText = ({label, amount}: {label: string; amount: string | number | undefined}) => (
  <div className="flex justify-between mt-4">
    <p className="body1">{label}</p>
    <p className="label1 text-color-heading">{amount}</p>
  </div>
);

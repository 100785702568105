import React, {useEffect, useState} from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';

// Components

// Types
import {ApproverReviewerFieldValues} from './approver-reviewer.types';
import {ComptSearchField} from '@compt/common/forms/compt-search-field/compt-search-field';
import {ReactHookFormErrors} from '@compt/types/form/react-hook-form-helper-types';
import {useGetProgramAssignableUsersQuery} from '@compt/app/services/api/learning-development-slice';
import {AssignedUsers} from '@compt/pages/admin-pages/admin-learning-development/components/approver-reviewer/assigned-users';
import {AnchoredComboBoxOption} from '@compt/common/forms/compt-search-field/compt-search';

const DEFAULT_USER_SEARCH_PAGE_SIZE = 15;

interface ApproverReviewerFieldProps {
  formMethods: UseFormReturn<ApproverReviewerFieldValues>;
  companyId: number;
  programId: number;
  readOnly: boolean;
}

export const employeeManagerOption: AnchoredComboBoxOption = {
  id: 0,
  key: 'manager-as-approver',
  value: {full_name: "Employee's Manager", email: 'employee-manager'},
  label: "Employee's Manager",
};

export const ApproverReviewerFields = (props: ApproverReviewerFieldProps) => {
  const [assignableUserSearchQuery, setAssignableUserSearchQuery] = useState('');
  const assignableUsersQuery = useGetProgramAssignableUsersQuery({
    companyId: props.companyId,
    programId: props.programId,
    filter: {
      search: assignableUserSearchQuery,
      offset: 0,
      limit: DEFAULT_USER_SEARCH_PAGE_SIZE,
    },
  });
  const {watch, setError, clearErrors} = props.formMethods;
  const watchedPrimaryApprovers = watch('primary_approvers');
  const watchedSecondaryApprovers = watch('secondary_approvers');

  // Check that primary and secondary approvers do not overlap
  useEffect(() => {
    if (!watchedPrimaryApprovers || !watchedSecondaryApprovers) {
      return;
    }
    const primaryApproverIds = new Set(watchedPrimaryApprovers.map((user) => user.id));

    for (const secondaryApprover of watchedSecondaryApprovers) {
      if (
        primaryApproverIds.has(secondaryApprover.id) &&
        secondaryApprover.id !== employeeManagerOption.id
      ) {
        setError('secondary_approvers', {
          type: 'custom',
          message: 'The same person cannot be selected for both primary and secondary approver.',
        });
        return;
      }
    }
    clearErrors(['secondary_approvers']);
  }, [watchedPrimaryApprovers, watchedSecondaryApprovers, setError, clearErrors]);

  return (
    <>
      <div>
        <ComptSearchField
          id="primary-approver-search-field"
          name="primary_approvers"
          value={[]}
          by="email"
          errors={props.formMethods.formState.errors.primary_approvers as ReactHookFormErrors}
          loading={assignableUsersQuery.isLoading}
          options={assignableUsersQuery.data?.results || []}
          register={props.formMethods.register}
          control={props.formMethods.control}
          onQueryChanged={setAssignableUserSearchQuery}
          getKey={(option) => option.email}
          getDisplayText={(option) => option.full_name}
          label="Select the primary approver(s) for this program"
          subLabel="This approver will be the first reviewer for requests for funds."
          required={true}
          getSecondaryText={(option) => option.email}
          renderSelectionDisplay={(selection, onRemove) => (
            <AssignedUsers
              users={selection}
              onRemove={(user) => onRemove(user)}
              readOnly={props.readOnly}
            />
          )}
          validation={{required: 'At least one primary approver is required'}}
          placeholder="Search for a person"
          anchoredOption={employeeManagerOption}
          disabled={props.readOnly}
          readOnly={props.readOnly}
        />
      </div>
      <div>
        <ComptSearchField
          id="secondary-approver-search-field"
          name="secondary_approvers"
          value={[]}
          by="email"
          errors={props.formMethods.formState.errors.secondary_approvers as ReactHookFormErrors}
          loading={assignableUsersQuery.isLoading}
          options={assignableUsersQuery.data?.results || []}
          register={props.formMethods.register}
          control={props.formMethods.control}
          onQueryChanged={setAssignableUserSearchQuery}
          getKey={(option) => option.email}
          getDisplayText={(option) => option.full_name}
          label="Select the secondary approver(s) for this program"
          subLabel="This approver will review requests after the primary approver has approved them."
          required={false}
          getSecondaryText={(option) => option.email}
          renderSelectionDisplay={(selection, onRemove) => (
            <AssignedUsers
              users={selection}
              onRemove={(user) => onRemove(user)}
              readOnly={props.readOnly}
            />
          )}
          placeholder="Search for a person"
          anchoredOption={employeeManagerOption}
          disabled={props.readOnly}
          readOnly={props.readOnly}
        />
      </div>
      <div>
        <ComptSearchField
          id="reimbursement-reviewer-search-field"
          name="reimbursement_reviewers"
          value={[]}
          by="email"
          errors={props.formMethods.formState.errors.reimbursement_reviewers as ReactHookFormErrors}
          loading={assignableUsersQuery.isLoading}
          options={assignableUsersQuery.data?.results || []}
          register={props.formMethods.register}
          control={props.formMethods.control}
          onQueryChanged={setAssignableUserSearchQuery}
          getKey={(option) => option.email}
          getDisplayText={(option) => option.full_name}
          label="Select the reimbursement reviewer(s) for this program"
          subLabel={
            'This user will review the final reimbursement claim before an employee is reimbursed.'
          }
          required={true}
          getSecondaryText={(option) => option.email}
          renderSelectionDisplay={(selection, onRemove) => (
            <AssignedUsers
              users={selection}
              onRemove={(user) => onRemove(user)}
              readOnly={props.readOnly}
            />
          )}
          validation={{required: 'At least one reimbursement reviewer is required'}}
          placeholder="Search for a person"
          anchoredOption={employeeManagerOption}
          disabled={props.readOnly}
          readOnly={props.readOnly}
        />
      </div>
    </>
  );
};

import React, {Dispatch, SetStateAction, useEffect} from 'react';

// RTK queries
import {
  useDeleteCustomNotificationMutation,
  useUpdateCustomNotificationMutation,
} from '@compt/app/services/api/custom-notifications-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {checkCustomNotificationIsUnique} from '../helpers/custom-notifications.helper';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {CustomNotificationsForm} from './custom-notifications-form';

// Types
import {
  CUSTOM_NOTIFICATION_TYPES,
  CustomNotification,
  CustomNotificationFieldValues,
  NOTIFICATION_CHANNEL_OPTIONS,
} from '@compt/types/custom-notifications';
import {Company} from '@compt/types/company';

interface ExistingCustomNotificationsSidePanelProps {
  selectedNotification: CustomNotification | null;
  setSelectedNotification: Dispatch<SetStateAction<CustomNotification | null>>;
  company: Company;
  existingNotifications: CustomNotification[];
}

export const ExistingCustomNotificationsSidePanel = (
  props: ExistingCustomNotificationsSidePanelProps,
) => {
  const {selectedNotification} = props;
  const hasSlack = props.company.has_slack;

  const isMobileView = useIsMobileView();

  const [updateNotification, {isLoading: isSubmitting}] = useUpdateCustomNotificationMutation();
  const [deleteNotification, {isLoading: isDeleting}] = useDeleteCustomNotificationMutation();

  const formMethods = useForm<CustomNotificationFieldValues>();

  useEffect(() => {
    if (!selectedNotification) {
      formMethods.reset();
      return;
    }

    formMethods.reset({
      id: selectedNotification.id,
      company: selectedNotification.company,
      enabled: selectedNotification.enabled,
      notification_type: CUSTOM_NOTIFICATION_TYPES[selectedNotification.notification_type],
      custom_message: selectedNotification.custom_message,
      subject_line: selectedNotification.subject_line,
      notification_channel: NOTIFICATION_CHANNEL_OPTIONS[selectedNotification.notification_channel],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNotification]);

  function onSubmit(form: CustomNotificationFieldValues) {
    if (!props.company.id || !props.existingNotifications || !selectedNotification) {
      triggerCustomToast('error', 'There was a problem saving your custom notification.');
      return;
    }

    const submission: CustomNotification = {
      ...form,
      id: selectedNotification.id,
      company: props.company.id,
      notification_channel: form.notification_channel.id,
      notification_type: form.notification_type.type,
    };

    if (
      !checkCustomNotificationIsUnique(
        props.existingNotifications,
        submission,
        selectedNotification,
      )
    ) {
      formMethods.setError('notification_type', {
        message: `You already have a notification with that type for the selected channel. 
          Please update the type or channel to be unique.`,
      });
      return;
    }

    updateNotification(submission).then((result) => {
      if ('error' in result) {
        triggerCustomToast('error', 'There was a problem saving your custom notification.');
        return;
      }

      triggerCustomToast('success', 'Successfully updated custom notification');
      props.setSelectedNotification(null);
    });
  }

  function onDelete(selectedNotification: CustomNotification | null) {
    if (!selectedNotification) {
      triggerCustomToast('error', 'There was a problem deleting your custom notification.');
      return;
    }

    deleteNotification(selectedNotification).then((result) => {
      if ('error' in result) {
        triggerCustomToast('error', 'There was a problem deleting your custom notification.');
        return;
      }

      triggerCustomToast('success', 'Successfully deleted custom notification');
      props.setSelectedNotification(null);
    });
  }

  return (
    <ComptSidePanel open={!!props.selectedNotification}>
      <ComptSidePanel.Header
        title="View custom notification"
        setOpen={() => {
          props.setSelectedNotification(null);
          formMethods.reset();
        }}
        headerIcon={{id: 'file-icon-blue'}}
      />
      <ComptSidePanel.Content className="px-4 py-6 sm:px-6">
        <CustomNotificationsForm
          formMethods={formMethods}
          hasSlack={hasSlack}
          selectedNotification={selectedNotification}
        />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex flex-col">
          <ComptCheckboxField
            name="enabled"
            value={false}
            label="Enable custom notification"
            register={formMethods.register}
            className="mb-200"
          />
          <div className="flex space-x-200">
            <ComptButton
              buttonType={ComptButtonType.PRIMARY}
              className="w-full mr-1"
              disabled={!formMethods.formState.isValid || isSubmitting || isDeleting}
              onClick={formMethods.handleSubmit(onSubmit)}
            >
              Save
            </ComptButton>
            {!isMobileView && (
              <ComptButton
                buttonType={ComptButtonType.DESTRUCTIVE}
                disabled={isSubmitting || isDeleting}
                onClick={() => {
                  onDelete(selectedNotification);
                }}
                className="w-full mr-1"
              >
                Delete
              </ComptButton>
            )}
            <ComptButton
              buttonType={ComptButtonType.OUTLINED}
              disabled={isSubmitting || isDeleting}
              onClick={() => {
                props.setSelectedNotification(null);
                formMethods.reset();
              }}
              className="w-full mr-1"
            >
              Cancel
            </ComptButton>
          </div>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

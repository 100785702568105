import React, {useState, useRef, useLayoutEffect, ReactNode} from 'react';
import {
  ComptTooltipController,
  ComptTooltipPlacement,
  ComptTooltipDuration,
  ComptTooltipStyle,
} from './compt-tooltip.controller';

export interface ComptTooltipProps {
  children: ReactNode;
  message: ReactNode;
  placement?: ComptTooltipPlacement;
  duration?: ComptTooltipDuration;
  style?: ComptTooltipStyle;
}

export const ComptTooltip: React.FC<ComptTooltipProps> = ({
  children,
  message,
  placement = ComptTooltipPlacement.TOP,
  duration = ComptTooltipDuration.SHORT,
  style = ComptTooltipStyle.DARK,
}) => {
  const [adjustedPlacement, setAdjustedPlacement] = useState(placement);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const tooltipTriggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const controller = new ComptTooltipController();

  useLayoutEffect(() => {
    const handleViewportChange = () => {
      setWindowSize({width: window.innerWidth, height: window.innerHeight});
    };

    window.addEventListener('resize', handleViewportChange);
    window.addEventListener('scroll', handleViewportChange);
    controller.updatePlacement({tooltipTriggerRef, placement, windowSize, setAdjustedPlacement});

    return () => {
      window.removeEventListener('resize', handleViewportChange);
      window.removeEventListener('scroll', handleViewportChange);
    };
  }, [windowSize, placement]);

  return (
    <div ref={tooltipTriggerRef} className="relative inline-block tooltip-trigger has-tooltip">
      <div
        ref={tooltipRef}
        className={`
          tooltip compt-tooltip rounded shadow-lg p-2 absolute z-10
          rounded-lg text-black border-gray-300 transition-visibility
          ${controller.getWhitespaceClass(tooltipRef)}
          ${controller.getStyleClass(style)}
          ${controller.getDurationClass(duration)}
          ${isTooltipVisible ? 'opacity-100 visible' : 'opacity-0 invisible'}
          ${controller.getPlacementClass(adjustedPlacement)}
        `}
      >
        {/**
         * Note that the style of this message component could clash with the styles set by the tooltip's getStyleClass
         * Make sure to update the message component to best fit the configured tooltip style.
         **/}
        {message}
        <div
          className={`
            tooltip-arrow tooltip w-2 h-2 rounded-sm absolute rotate-45
            ${controller.getStyleClass(style)}
            ${controller.getArrowClass(adjustedPlacement)}
          `}
        ></div>
      </div>
      <div
        onMouseEnter={() => setIsTooltipVisible(true)}
        onMouseLeave={() => setIsTooltipVisible(false)}
      >
        {children}
      </div>
    </div>
  );
};

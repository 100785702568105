import React from 'react';

export type ComptNoPermissionsPageProps = {
  heading: string;
  description: string;
};
export const NoPermissionsPage = (props: ComptNoPermissionsPageProps) => (
  <div className="flex justify-center mt-32 screen" data-testid="no-permissions-message">
    <div className="flex flex-col items-center sm:block absolute mt-20">
      <h1 className="display1 compt-text--semibold text-center mb-1">{props.heading}</h1>
      <p className="body1 text-color-body2">{props.description}</p>
    </div>
  </div>
);

import React from 'react';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {TeamRosterSyncAttemptTable} from '@compt/pages/admin-pages/admin-team-roster-sync-logs/team-roster-sync-attempt/team-roster-sync-attempt-table.container';
import {featureEnabled, FeatureFlags} from '@compt/utils/feature-flags-helper';
import {Error404Page} from '@compt/pages/404-error-page/error-404-page';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';
import {USER_ROLES} from '@compt/utils/user-roles-helper';

export const TeamRosterSyncAttemptPage = () => {
  const hasRequiredRole = useHasRequiredRole([USER_ROLES.admin, USER_ROLES.stipendProgramManager]);

  if (!featureEnabled(FeatureFlags.TEAM_ROSTER_SYNC_LOGS) || !hasRequiredRole) {
    return <Error404Page />;
  }

  return (
    <ComptPage title="Roster Sync Logs" className="h-dvh">
      <TeamRosterSyncAttemptTable />
    </ComptPage>
  );
};

import React, {useEffect, useMemo, useState} from 'react';
import {NavLink, useSearchParams} from 'react-router-dom';
import {useDebounce} from '@uidotdev/usehooks';
import {
  useGetStipendExpenseQuery,
  useGetStipendExpensesQuery,
} from '@compt/app/services/api/stipend-expenses-slice';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {
  ActivityTableController as controller,
  MAX_EXPENSES_PER_PAGE,
} from './activity-table.controller';
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {DeleteStipendExpenseModal} from '../components/existing-claim-side-panel/delete-stipend-expense-modal';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {ExistingClaimSidePanel} from '../components/existing-claim-side-panel/existing-claim-side-panel';
import {StipendExpense} from '@compt/types/stipend-expense';
import {ComptTableContextProvider} from '@compt/common/compt-table/compt-table.context';

export const ActivityTable = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const expenseId = searchParams.get('expense');

  const [expenseToDelete, setExpenseToDelete] = useState<StipendExpense | null>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);

  const session = useGetSessionQuery();
  const userId = session.data?.user_id;
  const userCountryCode = session.data?.country as SupportedCountriesType;

  const expenseQuery = useGetStipendExpenseQuery(expenseId ?? skipToken);

  const companyQuery = useGetCompanyQuery(userId ?? skipToken);
  const companyId = companyQuery.data?.id;

  const onActionMenuClicked = (expense: StipendExpense, action: 'VIEW' | 'EDIT' | 'DELETE') => {
    switch (action) {
      case 'VIEW':
        setSearchParams({expense: `${expense.id}`});
        setIsEditing(false);
        break;

      case 'EDIT':
        setSearchParams({expense: `${expense.id}`});
        setIsEditing(true);
        break;

      case 'DELETE':
        setExpenseToDelete(expense);
        break;
    }
  };
  const columnDefinition = controller.getColumnDefinition(userCountryCode, onActionMenuClicked);
  const filterConfiguration = controller.getFilterConfiguration();
  const initialFilterValues = controller.getInitialFilterValues();

  const [expenseQueryValues, setExpenseQueryValues] = useState<FormattedFilterObject | undefined>();
  const debouncedQueryValues = useDebounce(expenseQueryValues, 300);
  const expensesQuery = useGetStipendExpensesQuery(
    {userId, params: debouncedQueryValues},
    {skip: !userId},
  );

  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No stipend claims were found with the current filters.'
        : 'No stipend claims have been submitted yet',
    [filtersApplied],
  );

  const noDataSubtitle = useMemo(
    () =>
      filtersApplied ? (
        <p />
      ) : (
        <p className="body1 text-center">
          You haven&apos;t submitted any stipend claims yet. Go to{' '}
          <NavLink to={'/'} className="text-color-link">
            My stipends
          </NavLink>{' '}
          to get started!
        </p>
      ),
    [filtersApplied],
  );
  useEffect(() => {
    if (expenseQuery.isError) {
      triggerCustomToast('error', 'The stipend claim was not found');
      setSearchParams({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expenseQuery, expensesQuery]);

  return (
    <ComptLoadingIndicator
      isLoading={companyQuery.isLoading || expensesQuery.isLoading}
      className="pt-72"
    >
      {companyQuery.data && expensesQuery.data && (
        <ComptTableContextProvider>
          <ExistingClaimSidePanel
            open={!!expenseId}
            editMode={isEditing}
            setEditMode={setIsEditing}
          />
          <DeleteStipendExpenseModal
            expenseToDelete={expenseToDelete}
            setExpenseToDelete={setExpenseToDelete}
          />
          <ComptTable
            company={companyQuery.data}
            tableId="stipend-activity-table"
            className="w-full"
            data={expensesQuery?.data?.results ?? []}
            dataLoading={companyQuery.isFetching || expensesQuery.isFetching}
            noDataTitleText={noDataTitleText}
            noDataSubtitle={noDataSubtitle}
            allowShowHide={false}
            columnDefinition={columnDefinition}
            filterConfiguration={filterConfiguration}
            initialFilterValues={initialFilterValues}
            allowPagination={true}
            totalCount={expensesQuery?.data?.count ?? 0}
            itemsPerPage={MAX_EXPENSES_PER_PAGE}
            onChangeQueryValues={(filterValues, pagination, ordering) =>
              controller.updateQueryParamsOnValuesChanged(
                companyId,
                setExpenseQueryValues,
                setFiltersApplied,
                pagination,
                filterValues,
                ordering,
              )
            }
            onRowClicked={(row) => setSearchParams({expense: row.id})}
            stickyLastColumn
          />
        </ComptTableContextProvider>
      )}
    </ComptLoadingIndicator>
  );
};

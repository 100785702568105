import React, {HTMLAttributes, ReactNode} from 'react';
import {twMerge} from 'tailwind-merge';

export interface ComptCardProps extends HTMLAttributes<HTMLElement> {
  children: ReactNode;
  className?: string;
}

export const ComptCard = (props: ComptCardProps) => (
  <div
    className={twMerge(`compt-card flex flex-col flex-start flex-[1_0_0%]
  border border-gray-300 rounded-xl ${props.className}`)}
  >
    {props.children}
  </div>
);

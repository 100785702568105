import React from 'react';

// Hook and methods
import {useForm} from 'react-hook-form';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

// RTK queries
import {
  useCreateLearningDevelopmentProgramMutation,
  useCreatePreApprovalRequestConfigurationMutation,
  useCreateReimbursementRequestConfigurationMutation,
} from '@compt/app/services/api/learning-development-slice';

// Components
import {ProgramOverviewFields} from './program-overview-fields';
import {ProgramFormFooter} from '../form-footer';
import {LearningDevelopmentStepper} from '../learning-development-stepper';

// Types
import {StepStatus} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {ProgramOverviewFieldValues} from './program-overview.types';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';
import {LnDProgramForms} from '../../learning-development.types';

interface CreateProgramOverviewFormProps {
  companyId: number;
}

export const CreateProgramOverview = (props: CreateProgramOverviewFormProps) => {
  const [, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const {setStepStatusMap} = useFormStepperContext();

  const [createProgram, {isLoading: isCreating}] = useCreateLearningDevelopmentProgramMutation();
  const [createPreApprovalRequestConfiguration] =
    useCreatePreApprovalRequestConfigurationMutation();
  const [createReimbursementRequestConfiguration] =
    useCreateReimbursementRequestConfigurationMutation();

  const formMethods = useForm<ProgramOverviewFieldValues>();

  function onOverviewCreate(
    form: ProgramOverviewFieldValues,
    buttonCallback: (createdData?: LearningDevelopmentProgram) => void,
  ) {
    createProgram({body: form, companyId: props.companyId}).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem creating your program',
          'Please try again.',
        );
        return;
      }

      const createdProgramId = results.data.id;
      setSearchParams({program_id: `${createdProgramId}`});

      // Set default configurations on the program.
      createPreApprovalRequestConfiguration({
        programId: createdProgramId,
        companyId: props.companyId,
      });
      createReimbursementRequestConfiguration({
        programId: createdProgramId,
        companyId: props.companyId,
      });

      buttonCallback(results.data);
    });
    return;
  }

  function onNextClick() {
    formMethods.handleSubmit((form) =>
      onOverviewCreate(form, (createdData?: LearningDevelopmentProgram) => {
        const createdProgramId = createdData?.id;

        setSearchParams({
          program_id: `${createdProgramId}`,
        });

        setStepStatusMap((prevState) => ({
          ...prevState,
          [LnDProgramForms.PROGRAM_OVERVIEW]: StepStatus.COMPLETE,
          [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    formMethods.handleSubmit((form) =>
      onOverviewCreate(form, () => {
        triggerCustomToast('success', 'Program saved as a draft');
      }),
    )();
  }

  return (
    <div className="flex flex-col h-full pb-900 px-600 justify-between">
      <LearningDevelopmentStepper>
        <ProgramOverviewFields formMethods={formMethods} readOnly={false} />
      </LearningDevelopmentStepper>
      <ProgramFormFooter
        className="flex flex-shrink-0"
        isUpdating={isCreating}
        readOnly={false}
        onCloseCancel={() => {
          formMethods.reset();
          navigate('/manage-programs');
        }}
        onPreviousClick={() => {}}
        onSaveDraftClick={onSaveDraftClick}
        onNextClick={onNextClick}
      />
    </div>
  );
};

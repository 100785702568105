import React from 'react';
import {UserAssignment} from '@compt/types/account';

export class AssignmentsTableController {
  getColumnDefinition(onLogInAsUserClicked: (user: UserAssignment) => void) {
    const columnDefinition = {
      name: {
        id: 'name',
        name: 'Name',
        selector: (user: UserAssignment) => (
          <div className="flex flex-col">
            <p className="body3 compt-text--medium">
              {user.first_name} {user.last_name}
            </p>
            <p className="body3 text-color-body2">{user.email}</p>
          </div>
        ),
        grow: 1,
        order: 1,
      },
      action: {
        id: 'action',
        selector: (user: UserAssignment) => (
          <div>
            {/**
             * TODO COMPT-4187: Update this button to login as assigned users
             */}
            <button onClick={() => onLogInAsUserClicked(user)}>
              <p
                className={`label3 text-color-link compt-link 
                inline-flex hover:underline cursor-pointer`}
              >
                Login
              </p>
            </button>
          </div>
        ),
        minWidth: '105px',
        grow: 0,
        order: 2,
      },
    };

    return columnDefinition;
  }
}

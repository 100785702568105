import React, {Dispatch, SetStateAction} from 'react';
import {Company} from '@compt/types/company';
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import DOMPurify from 'dompurify';

interface BusinessExpensePolicyModalProps {
  company: Company;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

export const BusinessExpensePolicyModal = (props: BusinessExpensePolicyModalProps) => {
  const {open, setOpen, company} = props;

  return (
    <ComptModalShell
      open={open}
      setOpen={() => setOpen(false)}
      specifiedTopPadding="pt-0 px-0 sm:p-6 sm:pt-0 sm:px-0 rounded-t"
      specifiedMaxWidth="5xl"
    >
      <div className="flex justify-between items-center bg-base-600 pr-4">
        <p className="compt-text--lg compt-text--semi-bold text-color-light p-6">
          {`${company?.name} Expense Policy`}
        </p>
        <ComptButton
          buttonType={ComptButtonType.BORDERLESS}
          iconId={ComptButtonIcon.X_STYLEABLE}
          iconClassName="stroke-white"
          onClick={() => setOpen(false)}
        />
      </div>
      <div className="p-6">
        <p
          className="body2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              company?.business_expense_configuration?.business_expense_policy,
            ),
          }}
        ></p>
      </div>
    </ComptModalShell>
  );
};

import React, {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';

interface RedirectPageProps {
  basePath?: string;
}

export const RedirectPage = (props: RedirectPageProps) => {
  const location = useLocation();
  const {pathname, search} = location;

  useEffect(() => {
    window.location.href = `/${props.basePath}/#${pathname}${search}`;
  }, [pathname]);

  return <ComptLoadingIndicator isLoading={true} loadingText="Redirecting..." />;
};

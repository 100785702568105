import React, {useEffect, useState} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {getFileExtension, getFileSize, stripImageNameFromS3URL} from '@compt/utils/image-helpers';

// Components
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

interface FileExtensionButtonProps {
  fileUrl: string;
  className?: string;
}

export const FileExtensionButton = (props: FileExtensionButtonProps) => {
  const {fileUrl} = props;

  const [fileSize, setFileSize] = useState<string | null>('');

  useEffect(() => {
    const fetchFileSize = async () => {
      const size = await getFileSize(fileUrl);
      setFileSize(size);
    };

    if (fileUrl) {
      fetchFileSize();
    }
  }, [fileUrl]);

  function getFileIconName(file: string) {
    const fileExtension = getFileExtension(file);
    const fileIconTypes = ['pdf', 'png', 'eps', 'svg', 'gif', 'tiff', 'webp', 'jpeg', 'jpg', 'img'];
    const matchedFileType = fileIconTypes.find((fileType) => fileType === fileExtension);
    return `file-${matchedFileType ? matchedFileType : 'img'}-icon`;
  }

  // TODO: change displayed name to reflect actual file name vs generated string in COMPT-5833
  const displayedName = stripImageNameFromS3URL(props.fileUrl);
  const fileIconName = getFileIconName(props.fileUrl);

  return (
    <a
      className={twMerge(
        `flex w-full border rounded-xl p-300 bg-surface-background ${props.className}`,
      )}
      href={props.fileUrl}
      download
    >
      <ComptSvgIcon
        iconName={fileIconName}
        className="mr-3"
        svgProp={{width: '40px', height: '40px'}}
      />
      <div className="flex flex-col items-start">
        <p className="body2 text-color-body1">{displayedName}</p>
        <p className="body3 text-color-body1">{fileSize}</p>
      </div>
    </a>
  );
};

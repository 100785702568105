import React, {useState} from 'react';
import {RequestField} from '@compt/types/learning-development/learning-development-program';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';
import {Switch} from '@headlessui/react';

export interface RequestFieldsProps {
  requestFields: RequestField[];
  onRemove?: (requestField: RequestField) => void;
}

export const RequestFields = ({requestFields, onRemove}: RequestFieldsProps) => {
  // TODO: refs for each rendered switch
  const [, setEnabled] = useState(false);

  const onActionMenuClicked = (field: RequestField, action: 'VIEW' | 'EDIT' | 'DELETE') => {
    switch (action) {
      case 'VIEW':
        // eslint-disable-next-line no-console
        console.log('View clicked!', field.field_name);
        break;
      case 'EDIT':
        // eslint-disable-next-line no-console
        console.log('Edit clicked!', field.field_name);
        break;
      case 'DELETE':
        // eslint-disable-next-line no-console
        console.log('Delete clicked!', field.field_name);
        break;
    }
  };

  const renderActionSelector = (
    requestField: RequestField,
    onActionMenuClicked: (requestField: RequestField, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) => {
    const _onMenuClicked = (requestField: RequestField, action: 'VIEW' | 'EDIT' | 'DELETE') => {
      onActionMenuClicked(requestField, action);
    };

    const actions = [];

    if (requestField.is_editable) {
      actions.push({
        label: 'Edit',
        onClick: () => _onMenuClicked(requestField, 'EDIT'),
      });
      actions.push({
        label: 'Delete',
        onClick: () => _onMenuClicked(requestField, 'DELETE'),
        textColor: 'text-red-600',
      });
    } else {
      actions.push({
        label: 'View',
        onClick: () => _onMenuClicked(requestField, 'VIEW'),
      });
    }

    return <SimpleActionMenu relatedActionItem={requestField} actions={actions} />;
  };

  return (
    <ul className="divide-y divide-gray-100">
      {requestFields.map((field) => (
        <li key={field.sequence} className="flex items-center justify-between gap-x-12 py-4">
          <div className="flex min-w-0 gap-x-2">
            <ComptSvgIcon iconName="menu-drag-icon" className="pt-0.5" />
            <div className="min-w-0 flex-auto">
              <ComptCheckboxField
                name={`selectField-${field.sequence}`}
                value={true}
                initialValue={field.is_default}
                label={field.field_name}
                className={`label3 text-color-body1 ${
                  field.is_default ? 'cursor-not-allowed' : ''
                }`}
                disabled={field.is_default}
              />
              <p className="ml-6 text-color-tertiary">{field.supporting_text}</p>
            </div>
          </div>
          <div>
            <span className="flex flex-grow flex-col">
              <Switch.Group>
                <div className="flex items-center justify-end">
                  <Switch
                    name={`requiredToggle-${field.sequence}`}
                    defaultChecked={field.is_default}
                    onChange={setEnabled}
                    disabled={field.is_default}
                  >
                    {({checked}) => (
                      <button
                        className={`${
                          field.is_default
                            ? 'bg-gray-200 cursor-not-allowed'
                            : checked
                            ? 'bg-stroke-primary'
                            : 'bg-gray-200'
                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                      >
                        <span
                          className={`${
                            field.is_default
                              ? 'translate-x-6'
                              : checked
                              ? 'translate-x-6'
                              : 'translate-x-1'
                          } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                        />
                      </button>
                    )}
                  </Switch>
                  <Switch.Label className="ml-2 mr-1 label4 text-color-body1" passive>
                    Required
                  </Switch.Label>
                  {renderActionSelector(field, onActionMenuClicked)}
                </div>
              </Switch.Group>
            </span>
          </div>
        </li>
      ))}
    </ul>
  );
};

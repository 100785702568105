import React, {Dispatch, SetStateAction} from 'react';
import {ComptSelectCategorySidePanelController} from './compt-select-category-side-panel.controller';
import {Allotment} from '@compt/types/allotments';
import {PerkCategory, PerkCategoryCustomizations} from '@compt/types/perk-category';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptCategoryCard} from '@compt/common/compt-category-card/compt-category-card';
import {SupportedCountriesType} from '@compt/utils/international-helpers';

export interface ComptCategorySelectSidePanelProps {
  title: string;
  allotments: Allotment[];
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: (category: PerkCategory, allotment?: Allotment) => void;
  open: boolean;
  selectedAllotment: Allotment | undefined;
  categoryCustomizations: PerkCategoryCustomizations[] | undefined;
  userCountryCode?: SupportedCountriesType;
}

const controller = new ComptSelectCategorySidePanelController();

const CategoryCardsList = (props: ComptCategorySelectSidePanelProps) => (
  <ul className="divide-y divide-gray-200 flex-1">
    {controller
      .getAvailableCategoryCards(
        props.allotments,
        props.selectedAllotment,
        props.onSubmit,
        props.setOpen,
      )
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((category) => (
        <ComptCategoryCard
          key={category.id}
          userCountryCode={props.userCountryCode}
          perkCategory={category}
          categoryCustomizations={props.categoryCustomizations}
          onCategoryClick={() => {
            props.onSubmit(category);
            props.setOpen(false);
          }}
          data-testid={`compt-side-panel-category-card${category.id}`}
        />
      ))}
  </ul>
);

export const ComptSelectCategorySidePanel = (props: ComptCategorySelectSidePanelProps) => (
  <ComptSidePanel open={props.open} className="max-w-[480px]">
    <ComptSidePanel.Header
      title={props.title}
      setOpen={() => controller.handleClose(props.setOpen)}
      headerIcon={{id: 'file-icon-blue'}}
      breadCrumbs={['Select a category']}
    />
    <ComptSidePanel.Content className="p-6">
      <div className="h-full">
        <CategoryCardsList {...props} />
      </div>
    </ComptSidePanel.Content>
  </ComptSidePanel>
);

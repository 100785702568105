import React, {Dispatch, SetStateAction} from 'react';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {ExpenseStatus, StipendExpense} from '@compt/types/stipend-expense';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {
  StatusFormats,
  statusMap,
  stipendExpenseStatusStrings,
} from '@compt/types/stipend-expenses/stipend-expenses';
import {
  formatCurrencyFromCountryCode,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';
import {Analytics} from '@compt/utils/analytics/analytics-helpers';
import {EVENT_ACTION, EVENT_CATEGORY} from '@compt/utils/analytics/types';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';

export const MAX_EXPENSES_PER_PAGE = 20;

export class ActivityTableController {
  static getFilterConfiguration() {
    const statusOptions = Object.entries(stipendExpenseStatusStrings)
      .map((status) => {
        const {statusName} = StatusFormats[status[1]];
        return {id: status[0], name: statusName};
      })
      .filter(Boolean);

    const filterConfiguration = {
      textSearch: {
        filterType: SearchFilter,
        label: 'Activity Table Search',
        options: [
          {
            id: 1,
            name: 'Activity Table Search',
            placeholder: 'Search',
          },
        ],
        nonCollapsible: true,
      },
      status: {
        filterType: SelectFilter,
        label: 'Filter',
        options: statusOptions,
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };

    return filterConfiguration;
  }

  static updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setExpenseQueryValues: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) {
      return;
    }

    const formattedFilter = formatFilterValuesOptions(filterValues, pagination, ordering);

    setExpenseQueryValues(formattedFilter);
    if (
      Array.isArray(filterValues?.status) &&
      filterValues?.status.length &&
      filterValues.status.length > 0
    ) {
      setFiltersApplied(true);
    } else if (
      filterValues?.textSearch &&
      filterValues?.textSearch?.length > 0 &&
      filterValues?.textSearch[0].length > 0
    ) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }

  static getInitialFilterValues() {
    const initialFilterValues = {
      textSearch: [''],
      status: [],
    };

    return initialFilterValues;
  }

  static getColumnDefinition(
    userCountryCode: SupportedCountriesType | undefined = 'US',
    onMenuClicked: (expense: StipendExpense, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const columnDefinition = {
      stipend: {
        id: 'stipend',
        name: 'Stipend',
        grow: 1,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">{expense.allotments[0]?.cycle.name}</p>
        ),
        order: 1,
        omit: false,
      },
      amount_of_expense: {
        id: 'amount_of_expense',
        name: 'Amount',
        grow: 1,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(
              expense.amount_claimed,
              userCountryCode,
              expense.currency,
            )}
          </p>
        ),
        order: 2,
        omit: false,
      },
      date_of_expense: {
        id: 'date_of_expense',
        name: 'Expense date',
        grow: 1,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">
            {formattedDate(expense.date_of_expense, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 3,
        omit: false,
      },
      closed_on: {
        id: 'closed_on',
        name: 'Payroll processed',
        grow: 1,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">
            {!expense.closed_on
              ? 'Unprocessed'
              : formattedDate(expense.closed_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 4,
      },
      category: {
        id: 'category',
        name: 'Category',
        grow: 1,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">
            {expense.allotments[0]?.perk_categories.find(
              (perk) => perk.id === expense.perk_category,
            )?.name || ''}
          </p>
        ),
        order: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1,
        selector: (expense: StipendExpense) => {
          const statusFormat = StatusFormats[statusMap(expense.status)];

          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.statusName}
            </ComptPill>
          );
        },
        order: 6,
        omit: false,
      },
      description: {
        id: 'description',
        name: 'Description',
        enableToolTip: true,
        grow: 2,
        selector: (expense: StipendExpense) => (
          <p data-tag="allowRowEvents">{expense.description}</p>
        ),
        order: 7,
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (expense: StipendExpense) => {
          const _onMenuClicked = (expense: StipendExpense, action: 'VIEW' | 'EDIT' | 'DELETE') => {
            const eventAction = {
              VIEW: EVENT_ACTION.CLICKED_VIEW_EXPENSE,
              EDIT: EVENT_ACTION.CLICKED_EDIT_EXPENSE,
              DELETE: EVENT_ACTION.CLICKED_DELETE_EXPENSE,
            };

            Analytics.sendEvent({
              action: eventAction[action],
              category: EVENT_CATEGORY.EMPLOYEE_MANAGED_EXPENSES,
              label: `expense_${expense.id}`,
            });
            onMenuClicked(expense, action);
          };
          const actions: Array<SimpleAction<StipendExpense>> = [
            {
              label: 'View',
              onClick: (expense) => _onMenuClicked(expense, 'VIEW'),
            },
          ];

          if (expense.status === ExpenseStatus.Open) {
            actions.push({
              label: 'Edit',
              onClick: (expense) => _onMenuClicked(expense, 'EDIT'),
            });
            actions.push({
              label: 'Delete',
              onClick: (expense) => _onMenuClicked(expense, 'DELETE'),
              textColor: 'text-red-600',
            });
          }

          return <SimpleActionMenu relatedActionItem={expense} actions={actions} />;
        },
        grow: 0,
        order: 8,
      },
    };

    return columnDefinition;
  }
}

const formatFilterValuesOptions = (
  filterValues?: FilterValues,
  pagination?: Pagination | null,
  ordering?: string | null,
) => {
  const formattedFilter: FormattedFilterObject = {};

  if (
    Array.isArray(filterValues?.status) &&
    filterValues?.status.length &&
    filterValues.status.length > 0
  ) {
    formattedFilter['status__in'] = filterValues?.status.map((status) => status.id).join(',');
  }

  if (filterValues?.textSearch && filterValues?.textSearch?.length > 0) {
    [formattedFilter['search']] = filterValues.textSearch;
  }

  formattedFilter.limit = MAX_EXPENSES_PER_PAGE;

  if (pagination) {
    formattedFilter.offset = MAX_EXPENSES_PER_PAGE * Math.max(pagination.page - 1, 0);
    formattedFilter.page = pagination.page;
  } else {
    formattedFilter.page = 1;
    formattedFilter.offset = 0;
  }

  if (ordering) {
    formattedFilter.ordering = ordering;
  }

  return formattedFilter;
};

import {
  ChangeValueTypes,
  FilterBarProps,
  FilterValueTypes,
  FilterValues,
} from './compt-filter-bar.types';

export class ComptFilterBarController {
  constructor(public props: FilterBarProps) {
    this.getFilterValues = this.getFilterValues.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  getFilterValues(key: keyof FilterValues): FilterValueTypes {
    if (this.props.filterValues[key]) {
      return this.props.filterValues[key];
    }

    return null;
  }

  getTotalsSelection(key: keyof FilterValues) {
    const filterValues = this.getFilterValues(key);

    if (!filterValues || typeof filterValues === 'string') {
      return '';
    }

    if ('lowerBound' in filterValues || 'upperBound' in filterValues) {
      return '1';
    }

    if (Array.isArray(filterValues)) {
      return filterValues.length;
    }
  }

  handleFilterChange(key: keyof FilterValues, changeValue: ChangeValueTypes) {
    this.props.onFilterChanged({filterKey: key, changeValue});
  }
}

import React, {createContext, useContext, useState} from 'react';

interface ComptTableContextProps {
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

const ComptTableContext = createContext<ComptTableContextProps>({
  currentPage: 0,
  setCurrentPage: () => null,
});

export const ComptTableContextProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const [currentPage, setCurrentPage] = useState(1);
  return (
    <ComptTableContext.Provider value={{currentPage, setCurrentPage}}>
      {children}
    </ComptTableContext.Provider>
  );
};

export const useComptTableContext = (): ComptTableContextProps => {
  const context = useContext(ComptTableContext);
  if (!context) {
    throw new Error('useComptTableContext must be used within a ComptPageContextProvider');
  }
  return context;
};

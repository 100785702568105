import {comptApiSlice, ComptRTKTags} from './api-slice';
import {
  AccountSettingsUpdate,
  PasswordChange,
  UserAssignment,
  UserSession,
  UserTag,
} from '@compt/types/account';
import {MutationTrigger} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  MutationDefinition,
} from '@reduxjs/toolkit/query';

export type CreateTagBody = Partial<UserTag> & {
  key: string;
  value: string;
};

export type UserTagMutation = MutationTrigger<
  MutationDefinition<
    Partial<CreateTagBody>,
    BaseQueryFn<
      string | FetchArgs,
      unknown,
      FetchBaseQueryError,
      NonNullable<unknown>,
      FetchBaseQueryMeta
    >,
    ComptRTKTags,
    UserTag,
    'api'
  >
>;

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updatePassword: builder.mutation<PasswordChange, PasswordChange>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/change_password',
          method: 'PUT',
          body,
        };
      },
    }),
    updateAccountSettings: builder.mutation<AccountSettingsUpdate, AccountSettingsUpdate>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/update_account',
          method: 'PUT',
          body,
        };
      },
    }),
    updateUserOnboardingInfo: builder.mutation<UserSession, UserSession>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query(body) {
        return {
          url: 'me/update_account',
          method: 'PUT',
          body,
        };
      },
    }),
    getAssignments: builder.query<UserAssignment[], void>({
      providesTags: [ComptRTKTags.USER_SESSION],
      query: () => ({
        url: 'me/assignments',
      }),
    }),
    updateUserTag: builder.mutation<UserTag, Partial<CreateTagBody>>({
      invalidatesTags: [ComptRTKTags.USER_SESSION],
      query: (tag: UserTag) => ({
        url: `me/tags/?key=${tag.key}&value=${tag.value}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useUpdatePasswordMutation,
  useUpdateAccountSettingsMutation,
  useUpdateUserOnboardingInfoMutation,
  useGetAssignmentsQuery,
  useUpdateUserTagMutation,
} = extendedComptApiSlice;

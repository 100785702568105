import {AnalyticsTool, SendEventProps} from '@compt/utils/analytics/types';
import {UserSession} from '@compt/types/account';

export class PostHog implements AnalyticsTool {
  private isAnalyticsEnabled = typeof window.posthog != 'undefined';

  identify(userSession: UserSession): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }

    window.posthog.identify(
      '{{ user.email }}', // Use user email as unique identifier
      {
        email: userSession.email,
        name: `${userSession.first_name} ${userSession.last_name}`,
        company: userSession.company_name,
      },
    );
  }

  sendEvent({action, category, label, value}: SendEventProps): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }
    window.posthog.capture(action, {
      action,
      category,
      label,
      value,
    });
  }

  sendPageView(_pathname: string, _search: string): void {
    if (!this.isAnalyticsEnabled) {
      return;
    }

    window.posthog.capture('$pageview');
  }
}

import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {
  DATE_FORMAT_OPTION,
  dateToISODateString,
  formattedDate,
  getTomorrowDateString,
  getUTCDateFromString,
} from '@compt/utils/date-helpers';
import {useNavigate} from 'react-router-dom';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptRadioField} from '@compt/common/forms/compt-radio-field/compt-radio-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';

// Types
import {DateString} from '@compt/types/common/date-string';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';

interface LndScheduleFieldValues {
  launch_preference: string;
  start_date: DateString;
}

interface ConfirmLndScheduleSidePanelProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  existingData: LearningDevelopmentProgram | undefined;
}

export const ConfirmLndScheduleSidePanel = (props: ConfirmLndScheduleSidePanelProps) => {
  const {existingData} = props;
  const navigate = useNavigate();

  const formMethods = useForm<LndScheduleFieldValues>({
    shouldUnregister: true,
  });
  const watchedLaunchPreference = formMethods.watch('launch_preference');

  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();

  function onFormSubmit(form: LndScheduleFieldValues) {
    if (!existingData) {
      triggerCustomToast(
        'error',
        'There was an error scheduling your program',
        'A program ID is not associated with your scheduling',
      );
      return;
    }

    const utcTodayDate = new Date();
    const todayDateString = dateToISODateString(utcTodayDate);

    const submission = {
      id: existingData.id,
      name: existingData.name,
      company: existingData.company,
      start_date: form.start_date || todayDateString,
    };

    updateProgram(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem scheduling your program',
          'Please try again.',
        );
        return;
      }

      const isDraftSubmission = {
        id: existingData.id,
        name: existingData.name,
        company: existingData.company,
        is_draft: false,
      };

      updateProgram(isDraftSubmission).then((results) => {
        if ('error' in results) {
          triggerCustomToast(
            'error',
            'There was a problem scheduling your program',
            'Please try again.',
          );
          return;
        }

        if (results.data.start_date === todayDateString) {
          triggerCustomToast(
            'success',
            'Program launched successfully!',
            `${results.data.name} is now live and available for employees to take advantage of.`,
          );
        } else {
          triggerCustomToast(
            'success',
            'Program scheduled successfully!',
            `${results.data.name} will be available for employees on ${formattedDate(
              results.data.start_date,
              DATE_FORMAT_OPTION['month dd yyyy'],
            )}.`,
            '',
          );
        }

        navigate('/manage-programs');
      });
    });
  }

  return (
    <ComptSidePanel open={props.open} className="max-w-2xl">
      <ComptSidePanel.Header
        title="Schedule program"
        setOpen={() => props.setOpen(false)}
        headerIcon={{id: 'calendar'}}
        formMethods={formMethods}
      />
      <ComptSidePanel.Content className="p-10">
        <ComptRadioField
          label="When would you like to launch this program?"
          id="launch_preference"
          name="launch_preference"
          options={launchPreferenceOptions}
          register={formMethods.register}
          control={formMethods.control}
          errors={formMethods.formState.errors.launch_preference}
          validation={{
            required: 'Please select a launch preference',
          }}
        />
        {watchedLaunchPreference === 'schedule_later' && (
          <div className="mb-10">
            <ComptDatePickerField
              name="start_date"
              label="Select launch date"
              control={formMethods.control}
              register={formMethods.register}
              validation={{required: 'Please select a launch date'}}
              errors={formMethods.formState.errors.start_date}
              allowFutureDates
              startDate={getUTCDateFromString(getTomorrowDateString()) || null}
            />
          </div>
        )}
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex gap-x-2">
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            disabled={isUpdating}
            onClick={() => {
              formMethods.reset();
              props.setOpen(false);
            }}
          >
            Cancel
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.PRIMARY}
            onClick={formMethods.handleSubmit(onFormSubmit)}
            disabled={isUpdating}
          >
            Submit
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};

const launchPreferenceOptions = [
  {id: 'launch_now', label: 'Launch now'},
  {
    id: 'schedule_later',
    label: 'Schedule for a later time',
  },
];

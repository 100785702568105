import React, {useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import _ from 'lodash';

// Hooks and methods
import {useDelay} from '@compt/utils/delay-helper';

// Types
import {Swiper as SwiperCore} from 'swiper/types';
import {SupportingDocument} from '@compt/types/supporting-document';

// UI components
import {SwiperSlide} from 'swiper/react';
import {ComptCarousel} from '../compt-carousel/compt-carousel';
import {ComptCarouselNav} from '../compt-carousel/compt-carousel-nav';
import {ComptFileUploadField} from '../forms/compt-file-upload-field/compt-file-upload-field';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';
import {ComptReceiptPagination} from './compt-receipt-pagination';
import comptColors from '@compt/styles/compt-colors';

interface ComptReceiptFormCarouselProps {
  formMethods: UseFormReturn<any, any, undefined>;
  userId: number | undefined;
  initialSupportingDocs?: SupportingDocument[];
  maxNumberOfSupportingDocs?: number;
  initialReceiptValue?: string;
  receiptLabel?: string;
  subLabel?: string;
  required?: boolean | string;
  receiptDomain?: string;
  supportingDocDomain?: string;
  receiptTestDataId?: string;
  readOnly?: boolean;
  expenseId?: string | null;
  ['data-testid']?: string;
}

export const ComptReceiptFormCarousel = ({
  maxNumberOfSupportingDocs = 3,
  receiptLabel = 'Upload receipt',
  subLabel = '',
  required = 'Receipt is required',
  supportingDocDomain = 'supporting_document',
  ...props
}: ComptReceiptFormCarouselProps) => {
  const {
    formMethods,
    userId,
    initialSupportingDocs,
    receiptDomain,
    receiptTestDataId,
    readOnly,
    expenseId,
  } = props;

  // Used to keep UI in sync with supporting document fields
  const [supportingKeys, setSupportingKeys] = useState<Array<string | null | SupportingDocument>>(
    initialSupportingDocs || [],
  );
  const [activeIndex, setActiveIndex] = useState(0);

  // Delay visibility to allow side panel transition
  const {isVisible} = useDelay();

  // Sync supporting keys to initial documents if initial documents have changed
  useEffect(() => {
    if (expenseId && initialSupportingDocs && !_.isEqual(supportingKeys, initialSupportingDocs)) {
      setSupportingKeys(initialSupportingDocs);
      slideTo(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialSupportingDocs, expenseId]);

  const [swiperRef, setSwiperRef] = useState<SwiperCore | null>(null);

  useEffect(() => {
    if (swiperRef) {
      swiperRef.on('slideChange', () => {
        setActiveIndex(swiperRef.activeIndex);
      });
    }
  }, [swiperRef]);

  const isReceiptPopulated = formMethods.watch('receipt_key');

  // Disable adding supporting documents when an existing supporting document field has not been populated
  // Or when max number of supporting documents have been applied
  const isSupportingDocDisabled =
    (supportingKeys.length > 0 && !supportingKeys[supportingKeys.length - 1]) ||
    supportingKeys.length === maxNumberOfSupportingDocs;

  // Sync supportingKeys state to react-hook-forms uploaded fields
  function receiptKeyChangeCallback(indexToChange: number) {
    const supportingKeysCopy = [...supportingKeys];

    return (receiptKeyValue: string | null) => {
      supportingKeysCopy[indexToChange] = receiptKeyValue ?? null;
      setSupportingKeys(supportingKeysCopy);
      return;
    };
  }

  function slideTo(index: number) {
    if (swiperRef) {
      swiperRef?.slideTo(index, 0);
    }
  }

  if (!isVisible) return null;

  return (
    <div className="flex flex-col w-full h-full items-center" data-testid={props['data-testid']}>
      <div className="compt-slider-container flex flex-col sm:w-full items-end">
        <ComptCarousel className="compt-slider-carousel mb-4" setSwiperRef={setSwiperRef}>
          <ComptCarouselNav
            errors={!!formMethods.formState.errors.receipt_key}
            numberOfSlides={supportingKeys.length}
          />
          <SwiperSlide key={`slide-receipt-${props.expenseId}`}>
            <ComptFileUploadField
              name="receipt_key"
              label={receiptLabel}
              subLabel={subLabel}
              control={formMethods.control}
              register={formMethods.register}
              resetField={formMethods.resetField}
              clearError={formMethods.clearErrors}
              setError={formMethods.setError}
              getValues={formMethods.getValues}
              unregister={formMethods.unregister}
              validation={{required: required}}
              errors={formMethods.formState.errors.receipt_key}
              userId={userId}
              previewReceipt
              domain={receiptDomain}
              initialValue={props.initialReceiptValue}
              comptFormFieldClasses="flex flex-col h-full w-full"
              data-testid={receiptTestDataId}
              disabled={readOnly}
            />
          </SwiperSlide>
          {supportingKeys.map((doc, index) => {
            const supportingDocName = `supporting_doc_${index}`;
            const initialValue = typeof doc === 'string' ? null : doc?.document_image;
            return (
              <SwiperSlide key={`${supportingDocName}-for-${props.expenseId}`}>
                <ComptFileUploadField
                  name={supportingDocName}
                  initialValue={initialValue}
                  label="Additional attachments"
                  control={formMethods.control}
                  register={formMethods.register}
                  resetField={formMethods.resetField}
                  clearError={formMethods.clearErrors}
                  setError={formMethods.setError}
                  getValues={formMethods.getValues}
                  unregister={formMethods.unregister}
                  errors={formMethods.formState.errors[supportingDocName]}
                  userId={userId}
                  previewReceipt={true}
                  onKeyChange={receiptKeyChangeCallback(index)}
                  comptFormFieldClasses="flex flex-col h-full w-full"
                  domain={supportingDocDomain}
                  disabled={readOnly}
                />
              </SwiperSlide>
            );
          })}
        </ComptCarousel>
      </div>
      {/* Hide if receipt is not added, or max number of supporting documents are added */}
      <div className="flex justify-between w-full">
        <div className="flex">
          {supportingKeys.length > 0 && (
            <ComptReceiptPagination
              paginationLength={supportingKeys.length + 1}
              activeIndex={activeIndex}
              slideTo={slideTo}
            />
          )}
        </div>
        {isReceiptPopulated && !readOnly && supportingKeys.length < maxNumberOfSupportingDocs && (
          <button
            type="button"
            className={'flex justify-end rounded-lg h-9 max-h-9 border py-2 px-3 bg-gray-000'}
            onClick={() => {
              if (!isSupportingDocDisabled) {
                setSupportingKeys([...supportingKeys, null]);
              }
            }}
          >
            <ComptSvgIcon
              className="my-auto"
              iconName="plus-icon-blue"
              svgProp={{width: '16px', height: '16px', stroke: comptColors.base[500]}}
            />
            <p className="body3 ml-2">Additional attachment</p>
          </button>
        )}
      </div>
    </div>
  );
};

import React, {useEffect} from 'react';

// Types
import {UseFormReturn} from 'react-hook-form';
import {
  CUSTOM_NOTIFICATION_TYPES,
  CustomNotification,
  CustomNotificationTypeKey,
  NOTIFICATION_CHANNEL_OPTIONS,
  NotificationChannel,
} from '@compt/types/custom-notifications';

// Components
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTinyMCEField} from '@compt/common/forms/compt-tiny-mce-field/compt-tiny-mce-field';

const notificationTypeOptions = Object.keys(CUSTOM_NOTIFICATION_TYPES).map(
  (notificationType) => CUSTOM_NOTIFICATION_TYPES[notificationType as CustomNotificationTypeKey],
);

interface CustomNotificationFormProps {
  formMethods: UseFormReturn<any>;
  selectedNotification?: CustomNotification | null;
  hasSlack: boolean | undefined;
}

export const CustomNotificationsForm = (props: CustomNotificationFormProps) => {
  const {formMethods, hasSlack} = props;
  const watchedNotificationType = formMethods.watch('notification_type');
  const watchedNotificationChannel = formMethods.watch('notification_channel');
  const watchedSubjectLine = formMethods.watch('subject_line');
  const watchedCustomMessage = formMethods.watch('custom_message');

  const notificationChannelOptions = [NOTIFICATION_CHANNEL_OPTIONS[NotificationChannel.EMAIL]];

  if (hasSlack) {
    notificationChannelOptions.push(NOTIFICATION_CHANNEL_OPTIONS[NotificationChannel.SLACK]);
  }

  /**If company does not have Slack or chosen notification type is email only,
   * automatically set notification channel to email.  */
  useEffect(() => {
    if (!hasSlack) {
      formMethods.setValue(
        'notification_channel',
        NOTIFICATION_CHANNEL_OPTIONS[NotificationChannel.EMAIL],
      );
      return;
    }

    if (watchedNotificationType?.emailOnly) {
      formMethods.setValue(
        'notification_channel',
        NOTIFICATION_CHANNEL_OPTIONS[NotificationChannel.EMAIL],
      );
    } else {
      formMethods.resetField('notification_channel');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasSlack, watchedNotificationType]);

  // If watched notification channel changes, reset subject line
  useEffect(() => {
    formMethods.resetField('subject_line');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedNotificationChannel]);

  return (
    <form>
      <fieldset disabled={formMethods.formState.isSubmitting}>
        <ComptDropDownField
          id="notification-type-dropdown"
          name="notification_type"
          label="Notification type"
          options={notificationTypeOptions}
          getDisplayText={(option) => option.displayName}
          getSecondaryText={(option) => option.description}
          getKey={(option) => option.type}
          placeholder="Select a notification type"
          register={formMethods.register}
          control={formMethods.control}
          validation={{
            required: 'Notification type is required',
          }}
          errors={formMethods.formState.errors.notification_type}
        />
        <ComptDropDownField
          id="notification-channel-dropdown"
          name="notification_channel"
          label="Notification channel"
          options={notificationChannelOptions}
          getDisplayText={(option) => option?.name}
          getKey={(option) => option?.id}
          placeholder="Select a notification channel"
          register={formMethods.register}
          control={formMethods.control}
          validation={{
            required: 'Notification channel is required',
          }}
          disabled={!hasSlack || watchedNotificationType?.emailOnly}
          errors={formMethods.formState.errors.notification_channel}
        />
        {watchedNotificationChannel?.id !== NotificationChannel.SLACK && (
          <p className="body2 text-color-body2 my-300">
            Note: either subject line or custom message must be filled in for Email notifications.
          </p>
        )}
        {watchedNotificationChannel?.id === NotificationChannel.EMAIL && (
          <ComptTextField
            name="subject_line"
            label="Subject line"
            control={formMethods.control}
            register={formMethods.register}
            validation={{
              required:
                watchedNotificationChannel?.id === NotificationChannel.EMAIL &&
                !watchedCustomMessage &&
                'Subject line is required',
            }}
            errors={formMethods.formState.errors.subject_line}
          />
        )}
        <ComptTinyMCEField
          formMethods={formMethods}
          id="custom-message"
          name="custom_message"
          label="Custom message"
          initialValue={props.selectedNotification?.custom_message}
          validation={{required: !watchedSubjectLine && 'Custom message is required'}}
        />
      </fieldset>
    </form>
  );
};

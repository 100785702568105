import React from 'react';

// Hooks and methods
import {UseFormReturn} from 'react-hook-form';

// Components
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTinyMCEField} from '@compt/common/forms/compt-tiny-mce-field/compt-tiny-mce-field';

// Types
import {ProgramOverviewFieldValues} from './program-overview.types';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';
import DOMPurify from 'dompurify';

interface ProgramOverviewFieldProps {
  formMethods: UseFormReturn<ProgramOverviewFieldValues>;
  readOnly: boolean;
  existingData?: LearningDevelopmentProgram;
}

export const ProgramOverviewFields = (props: ProgramOverviewFieldProps) => (
  <div className="max-w-2xl">
    <ComptTextField
      name="name"
      label="Program name"
      data-testid="program-name-field"
      control={props.formMethods.control}
      register={props.formMethods.register}
      validation={{required: 'Program name is required'}}
      placeholder={'Give your program a name'}
      errors={props.formMethods.formState.errors.name}
      autoComplete="off"
      disabled={props.readOnly}
      readOnly={props.readOnly}
    />
    {props.readOnly && props.existingData ? (
      <>
        <label
          data-testid="program-name-field-label"
          className="flex items-center body2 text-color-body1 mb-1"
        >
          Program description
        </label>
        <p
          className="body2 inner-html bg-surface-disabled rounded-md px-3 py-2"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(props.existingData.description),
          }}
        />
      </>
    ) : (
      <ComptTinyMCEField
        formMethods={props.formMethods}
        id="description"
        name="description"
        label="Program description"
        initialValue={''}
        validation={{required: 'Program description is required.'}}
      />
    )}
  </div>
);

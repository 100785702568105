import {comptApiSlice} from './api-slice';
import {NotificationMessagePost} from '@compt/types/notification';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    postToSlack: builder.mutation<void, NotificationMessagePost>({
      query: (body) => ({
        url: 'post-to-slack/',
        method: 'POST',
        body,
      }),
    }),
    postToMSTeams: builder.mutation<void, NotificationMessagePost>({
      query: (body) => ({
        url: 'post-to-ms-teams/',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {usePostToSlackMutation, usePostToMSTeamsMutation} = extendedComptApiSlice;

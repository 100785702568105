import React, {useState} from 'react';
import {SearchFilterProps} from './compt-filter-bar.types';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

export const SearchFilter = ({currentValues, options, handleChange}: SearchFilterProps) => {
  const [value, setValue] = useState(
    Array.isArray(currentValues) && typeof currentValues[0] === 'string' ? currentValues[0] : '',
  );

  const _handleChange = (newValue: string) => {
    if (newValue !== value) {
      setValue(newValue);
      handleChange(newValue);
    }
  };

  return (
    <div className="text-filter">
      {options?.map((option, i) => (
        <div key={i}>
          <div className="relative rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <ComptSvgIcon
                iconName={'magnifying-glass'}
                svgProp={{fillOpacity: 0}}
                className="pr-16"
              />
            </div>
            <input
              id={option.id?.toString()}
              className="block w-full rounded-md border-0 pl-10 text-gray-900
              ring-1 ring-inset ring-stroke-tertiary placeholder:text-gray-400
              sm:text-sm sm:leading-6 focus:ring-stroke-primary focus:ring-2"
              type="text"
              placeholder={option.placeholder || 'Start typing to search...'}
              onKeyUp={(e) => (e.key === 'Enter' ? _handleChange(e.currentTarget.value) : null)}
              value={value}
              onBlur={(e) => _handleChange(e.target.value)}
              onChange={(e) => _handleChange(e.target.value)}
              autoComplete="off"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

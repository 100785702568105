import React, {HTMLAttributes, ReactElement, useEffect} from 'react';
import {FieldValues, UseFormReturn} from 'react-hook-form';
import {
  DeprecatedComptFormFieldProps,
  ComptMultiOptionControlFieldProps,
} from '../compt-form-field/compt-form-field';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';
import {ComptTextField} from '@compt/common/forms/compt-text-field/compt-text-field';
import {ComptTextAreaField} from '@compt/common/forms/compt-text-area-field/compt-text-area-field';
import {ComptCurrencyField} from '@compt/common/forms/compt-currency-field/compt-currency-field';
import {ComptFileUploadField} from '@compt/common/forms/compt-file-upload-field/compt-file-upload-field';
import {ComptDropDownField} from '@compt/common/forms/compt-dropdown-field/compt-dropdown-field';
import {ComptCheckboxField} from '@compt/common/forms/compt-checkbox-field/compt-checkbox-field';
import {ComptRadioField} from '../compt-radio-field/compt-radio-field';
import {ComptNumberField} from '../compt-number-field/compt-number-field';

type FieldType =
  | typeof ComptDatePickerField
  | typeof ComptTextField
  | typeof ComptTextAreaField
  | typeof ComptCurrencyField
  | typeof ComptFileUploadField
  | typeof ComptDropDownField
  | typeof ComptCheckboxField
  | typeof ComptRadioField
  | typeof ComptNumberField;

export interface ComptFormFieldConfiguration extends DeprecatedComptFormFieldProps {
  fieldType: FieldType;
}

export interface ComptFormMultiSelectControlConfiguration
  extends ComptMultiOptionControlFieldProps {
  fieldType: FieldType;
}

export type ComptFormFieldTypes = Array<
  ComptFormFieldConfiguration | ComptFormMultiSelectControlConfiguration
>;

export interface ComptFormFieldSectionProps extends HTMLAttributes<HTMLElement> {
  fields: ComptFormFieldTypes;
  grid?: boolean;
  disabled?: boolean;
}

export interface ComptFormProps extends HTMLAttributes<HTMLElement> {
  formMethods: UseFormReturn<FieldValues, any, undefined>;
  secondaryButtonComponent?: ReactElement;
  submitButtonText?: string;
  fieldSections: ComptFormFieldSectionProps[];
  defaultValues?: FieldValues;
  computedRateComponent?: ReactElement | null;
}

export enum ComptFormSize {
  LARGE = 'LARGE',
  SMALL = 'SMALL',
}

/**
 * @deprecated inline the HTML instead.
 */
export const ComptForm = (props: ComptFormProps) => {
  const {register, formState, setValue, control, reset, resetField} = props.formMethods;
  const {defaultValues} = props;

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
  }, [defaultValues, reset]);

  return (
    <form className="compt-form">
      <div className="space-y-12 sm:space-y-16">
        <div>
          <div
            className={`
              space-y-8 border-gray-200 divide-gray-200 pb-12 sm:space-y-0
              sm:divide-y sm:pb-0
            `}
          >
            {props.fieldSections.map((fieldSection, i) => (
              <div
                key={`compt-field-section-${i}`}
                className={`compt-field-section space-y-2 sm:space-y-0 border-gray-900/10
                 ${fieldSection.grid ? 'sm:grid sm:grid-cols-2 gap-4 justify-between' : ''}`}
              >
                {fieldSection.fields.map((field, i) => (
                  // Suppressing this until we revamp this form helper.
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  <field.fieldType
                    {...field}
                    name={field.name || field.id}
                    key={`compt-field-${i}`}
                    errors={formState?.errors?.[field.name || field.id || '']}
                    register={register}
                    setValue={setValue}
                    resetField={resetField}
                    control={control}
                  />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      {props.computedRateComponent && props.computedRateComponent}
      <div className="grid grid-flow-col w-full gap-3 mt-8">{props.children}</div>
    </form>
  );
};

import React from 'react';

// Types
import {CUSTOM_NOTIFICATION_TYPES, CustomNotification} from '@compt/types/custom-notifications';

export class AdminCustomizableNotificationsController {
  static getColumnDefinition(onActionClicked: (notification: CustomNotification) => void) {
    const columnDefinition = {
      subject: {
        order: 1,
        id: 'subject',
        name: 'Subject',
        omit: false,
        grow: 1,
        selector: (notification: CustomNotification) => (
          <p data-tag="allowRowEvents">{notification.subject_line}</p>
        ),
      },
      notification_type: {
        order: 3,
        id: 'notification_type',
        name: 'Notification type',
        omit: false,
        grow: 1,
        selector: (notification: CustomNotification) => {
          const text = CUSTOM_NOTIFICATION_TYPES[notification.notification_type].displayName;
          return <p data-tag="allowRowEvents">{text}</p>;
        },
      },
      notification_channel: {
        order: 4,
        id: 'notification_channel',
        name: 'Channel',
        omit: false,
        grow: 1,
        selector: (notification: CustomNotification) => (
          <p className="capitalize" data-tag="allowRowEvents">
            {notification.notification_channel.toLowerCase()}
          </p>
        ),
      },
      enabled: {
        order: 5,
        id: 'enabled',
        name: 'Enabled/disabled',
        omit: false,
        grow: 1,
        selector: (notification: CustomNotification) => {
          const text = notification.enabled ? 'Enabled' : 'Disabled';
          return <p data-tag="allowRowEvents">{text}</p>;
        },
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        grow: 0,
        order: 7,
        selector: (notification: CustomNotification) => (
          <p
            data-tag="allowRowEvents"
            className="text-color-link cursor-pointer"
            onClick={() => onActionClicked(notification)}
          >
            View
          </p>
        ),
      },
    };

    return columnDefinition;
  }
}

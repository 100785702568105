import React, {useContext} from 'react';
import {useUpdateUserTagMutation} from '@compt/app/services/api/accounts-slice';
import {FeatureFlags, featureEnabled} from '@compt/utils/feature-flags-helper';
import {ComptButton, ComptButtonSize, ComptButtonType} from '../compt-button/compt-button';
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {HelpScout} from '@compt/utils/analytics/vendors/help-scout';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {SideNavContext} from './side-nav-context';

interface DesignFeedbackProps {
  closeSecondaryNav: () => void;
}
export const DesignFeedback = (props: DesignFeedbackProps) => {
  const {isAdminView} = useContext(SideNavContext);
  const isMobileView = useIsMobileView();
  const helpScout = new HelpScout();

  const [updateUserTag] = useUpdateUserTagMutation();

  const disableNewDesign = () => {
    updateUserTag({key: FeatureFlags.USER_DISABLED_NEW_REACT_APP, value: 'enabled'}).then(
      (data) => {
        if ('error' in data) {
          triggerCustomToast('error', 'There was a problem going back to the old design');
          return;
        }
        window.location.replace('/app/#?opted-out=true');
        window.location.reload();
      },
    );
  };

  if (!featureEnabled(FeatureFlags.ENABLE_NEW_REACT_APP)) {
    return null;
  }

  return (
    <>
      <>
        <hr className="border my-1" />
        <div
          className={`my-4 ${isMobileView ? 'px-3' : ''} text-center design-feedback-container`}
          onMouseEnter={() => props.closeSecondaryNav()}
        >
          <p className="body3 mb-3">
            We&apos;ve redesigned the employee experience to have a new look and feel! (Currently in
            Beta)
          </p>
          <div className="grid gap-y-2">
            {!isAdminView && (
              <ComptButton
                size={ComptButtonSize.SMALL}
                buttonType={ComptButtonType.OUTLINED}
                onClick={() => disableNewDesign()}
              >
                Back to the Old Design
              </ComptButton>
            )}
            <ComptButton
              size={ComptButtonSize.SMALL}
              buttonType={ComptButtonType.PRIMARY}
              onClick={() => helpScout.sendFeedback()}
              textClassName="text-white"
            >
              Give Design Feedback
            </ComptButton>
          </div>
        </div>
      </>
    </>
  );
};

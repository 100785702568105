import React from 'react';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {PerkCategory} from '@compt/types/perk-category';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {ComptTooltip} from '@compt/common/compt-tooltip/compt-tooltip.container';
import {ComptTooltipPlacement} from '@compt/common/compt-tooltip/compt-tooltip.controller';

interface VendorCardProps {
  logoSrc: string;
  title: string;
  offer?: string;
  description: string;
  perkCategories?: PerkCategory[];
}

export const VendorCard = ({
  logoSrc,
  title,
  description,
  offer,
  perkCategories,
}: VendorCardProps) => (
  <ComptCard className="hover:bg-gray-100">
    <div className="flex flex-col items-center p-4 px-6 text-center gap-4 h-[270px] cursor-pointer">
      <div
        className="absolute w-[128px] h-[128px] flex justify-center items-center bg-white 
  left-1/2 top-[-24px] transform -translate-x-1/2 rounded-xl shadow-[0px_6px_20px_0px_rgba(0,0,0,0.08)]"
      >
        <img src={logoSrc} alt={title} className="w-full h-full object-cover rounded-xl" />
        {offer && (
          <ComptPill className="absolute bottom-3" pillType="badge" pillSize={ComptPillSize.SMALL}>
            Offer
          </ComptPill>
        )}
      </div>

      <div className="flex flex-col items-center gap-2 max-w-[198px] mt-[112px]">
        <h3 className="text-xl font-semibold text-gray-800 mb-1">{title}</h3>
        <p className="text-md text-gray-600">{description}</p>
        {perkCategories && perkCategories.length > 0 && (
          <ComptTooltip
            placement={ComptTooltipPlacement.BOTTOM}
            message={
              <div className="compt-text--xs text-white">
                {perkCategories?.map((category, index) => <p key={index}>{category.name}</p>)}
              </div>
            }
          >
            <div className="flex -space-x-2">
              {perkCategories.map(({internal_id, id}) => (
                <ComptSvgIcon
                  key={id}
                  iconName={internal_id}
                  isCategoryIcon
                  svgProp={{width: '20px', height: '20px'}}
                  className="w-8 h-8 border-2 border-white bg-[white] rounded-full
            flex items-center justify-center"
                />
              ))}
            </div>
          </ComptTooltip>
        )}
      </div>
    </div>
  </ComptCard>
);

import React, {useState} from 'react';

// Hooks and methods
import {useIsMobileView} from '@compt/utils/mobile-helpers';
import {useSearchParams} from 'react-router-dom';
import {useHasRequiredRole} from '@compt/utils/permission-helpers';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllEmployeeProgramBudgetsQuery} from '@compt/app/services/api/employee-learning-development-slice';

// Types
import {USER_ROLES} from '@compt/utils/user-roles-helper';

// Components
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPage} from '@compt/common/compt-page/compt-page';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {LearningActivityFeed} from '@compt/pages/learning-page/components/learning-activity-feed';
import {LearningCard} from '@compt/pages/learning-page/components/learning-card';
import {EmployeeLearningDevelopmentBudgetEligibility} from '@compt/types/learning-development/employee-learning-development-budget-eligibility';
import {HowDoesItWorkModal} from './components/how-does-it-work-modal';
import {NoPermissionsPage} from '../no-permissions-page/no-permissions-page';
import {ReimbursementSidePanel} from '@compt/pages/learning-page/components/reimbursement-side-panel';
import {Error500Page} from '../500-error-page/error-500-page';

export const LearningPage = () => {
  const isMobileView = useIsMobileView();

  const sessionQuery = useGetSessionQuery();
  const companyQuery = useGetCompanyQuery(sessionQuery.data?.user_id ?? skipToken);

  const employeePrograms = useGetAllEmployeeProgramBudgetsQuery(
    companyQuery?.data?.id ?? skipToken,
  );

  const userCountryCode = sessionQuery.data?.country;

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const [searchParams] = useSearchParams();

  const preApprovalRequestId = searchParams.get('pre_approval_id');

  const hasRequiredRole = useHasRequiredRole([USER_ROLES.learningAndDevelopmentEligible]);

  if (isMobileView) {
    return (
      <NoPermissionsPage
        heading="This page is not yet formatted for mobile view"
        description="Please access this page from a desktop view!"
      />
    );
  }

  if (!hasRequiredRole) {
    return (
      <NoPermissionsPage
        heading="Looks like you don't have a program yet, or may not be eligible"
        description={
          'We recommend reaching out to your HR team for any questions or help getting set up!'
        }
      />
    );
  }

  if (sessionQuery.isError || companyQuery.isError || employeePrograms.isError)
    return <Error500Page />;

  return (
    <div className="flex h-full">
      <HowDoesItWorkModal open={tooltipOpen} setOpen={setTooltipOpen} />
      {companyQuery.data && (
        <ReimbursementSidePanel requestId={preApprovalRequestId} companyId={companyQuery.data.id} />
      )}
      <ComptPage
        title="Professional development"
        subtitle="Submit and track requests towards your professional development"
        className="grow px-600 pb-600 pt-6 h-full overflow-scroll"
        includeBottomHR={false}
        useDefaultMargins={false}
        action={
          <button
            className={`flex items-center ${isMobileView ? 'mt-2 mb-6 pt-4 pb-2' : 'pr-5'}`}
            onClick={() => setTooltipOpen(true)}
          >
            <ComptSvgIcon iconName="alert-circle-question-blue-icon" className="pr-2" />
            <p className="body1 compt-link inline-flex hover:underline cursor-pointer">
              How does it work?
            </p>
          </button>
        }
      >
        <ComptLoadingIndicator isLoading={companyQuery.isLoading}>
          {sessionQuery.data && companyQuery.data && (
            <LearningActivityFeed company={companyQuery.data} />
          )}
        </ComptLoadingIndicator>
      </ComptPage>
      {employeePrograms.data?.results && employeePrograms.data.results.length > 0 && (
        <div className="w-[386px] min-w-[386px] border-l border-stroke-divider1 h-full overflow-scroll">
          <div className="flex flex-col">
            {employeePrograms.data.results.map(
              (budget: EmployeeLearningDevelopmentBudgetEligibility) => (
                <div
                  key={`${budget.id}-card`}
                  id={`budget-${budget.id}`}
                  className="w-full border-b border-stroke-divider1"
                >
                  <div className="non-monetary-card py-6 pl-6 pr-10">
                    <h2 className="mb-400">{budget.program.name}</h2>
                    <LearningCard budget={budget} userCountryCode={userCountryCode} />
                  </div>
                </div>
              ),
            )}
          </div>
        </div>
      )}
    </div>
  );
};

import React from 'react';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {FAQ} from '@compt/types/faq';
import DOMPurify from 'dompurify';

interface FaqProps {
  id?: number;
  faq: FAQ;
  openIndex: number | null;
  handleAccordionChange: (id: number) => void;
}

export const FaqItem = (props: FaqProps) => {
  const isOpen = props.openIndex === props.id;

  return (
    <>
      <div
        className={`flex flex-row justify-between my-300 w-full
         cursor-pointer items-center ${isOpen && '-mb-4'}`}
        onClick={() => {
          if (props.id || props.id === 0) {
            props.handleAccordionChange(props.id);
          }
        }}
      >
        <p className="body2 text-color-heading sm:heading3 w-3/4">{props.faq.question}</p>
        <ComptSvgIcon
          iconName="chevron-down-icon"
          className={`${
            isOpen && 'rotate-180'
          } hover:bg-surface-secondary-tint rounded-full w-14 h-14 
              flex justify-center items-center`}
          labelHidden={false}
          ariaLabel={`${isOpen ? 'chevron-up' : 'chevron-down'}`}
          svgProp={{width: '28px', height: '28px'}}
        />
      </div>
      {isOpen && (
        <div className="pt-2">
          <p
            className="body3 text-color-body1 sm:body1 inner-html mb-4"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.faq.answer),
            }}
          />
        </div>
      )}
      <hr />
    </>
  );
};

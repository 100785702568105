import React, {Dispatch, SetStateAction} from 'react';

// Hooks and methods
import DOMPurify from 'dompurify';

// Components
import {ComptModalShell} from '@compt/common/compt-modal-shell/compt-modal-shell';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {
  ComptButton,
  ComptButtonIcon,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';

interface ModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  title: string;
  description: string;
}

export const LnDFullDescriptionModal = (props: ModalProps) => (
  <ComptModalShell
    open={props.open}
    setOpen={() => props.setOpen(false)}
    specifiedTopPadding="p-0"
    className=" sm:p-0"
  >
    <div className="flex justify-between pt-400 pb-300 px-400 sm:border-b">
      <div className="flex items-center">
        <div
          className={
            'border border-brand-500 bg-brand-50 rounded-lg shadow-sm flex items-center p-3 mr-300'
          }
        >
          <ComptSvgIcon
            iconName="alert-circle-blue-icon"
            svgProp={{width: '20px', height: '20px'}}
          />
        </div>
        <h2>{props.title}</h2>
      </div>
      <ComptButton
        className="w-1"
        buttonType={ComptButtonType.BORDERLESS}
        iconId={ComptButtonIcon.X_STYLEABLE}
        iconClassName="stroke-gray-500"
        onClick={() => props.setOpen(false)}
      />
    </div>
    <div className="px-400 sm:pt-100 pb-200">
      <p
        className="body3 inner-html"
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(props.description),
        }}
      />
    </div>
    <div className="p-400 border-t">
      <ComptButton
        buttonType={ComptButtonType.SECONDARY}
        className="w-full"
        onClick={() => props.setOpen(false)}
      >
        Done
      </ComptButton>
    </div>
  </ComptModalShell>
);

import {comptApiSlice, ComptRTKTags} from './api-slice';
import {CustomNotification} from '@compt/types/custom-notifications';
import {ListResultsBase} from '@compt/types/common/list-results';
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';

const extendedComptApiSlice = comptApiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCustomNotifications: builder.query<ListResultsBase<CustomNotification>, number>({
      query: (companyId: number) => ({
        url: `companies/${companyId}/custom_notifications?limit=${MAX_ITEMS_PER_PAGE}`,
      }),
      providesTags: [ComptRTKTags.CUSTOM_NOTIFICATIONS],
    }),
    createCustomNotification: builder.mutation<CustomNotification, Partial<CustomNotification>>({
      invalidatesTags: [ComptRTKTags.CUSTOM_NOTIFICATIONS],
      query(body) {
        return {
          url: `companies/${body.company}/custom_notifications`,
          method: 'POST',
          body,
        };
      },
    }),
    updateCustomNotification: builder.mutation<CustomNotification, CustomNotification>({
      invalidatesTags: [ComptRTKTags.CUSTOM_NOTIFICATIONS],
      query(body) {
        return {
          url: `companies/${body.company}/custom_notifications/${body.id}`,
          method: 'PUT',
          body,
        };
      },
    }),
    deleteCustomNotification: builder.mutation<null, CustomNotification>({
      invalidatesTags: [ComptRTKTags.CUSTOM_NOTIFICATIONS],
      query(body) {
        return {
          url: `companies/${body.company}/custom_notifications/${body.id}`,
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useGetCustomNotificationsQuery,
  useCreateCustomNotificationMutation,
  useUpdateCustomNotificationMutation,
  useDeleteCustomNotificationMutation,
} = extendedComptApiSlice;

import React from 'react';
import _ from 'lodash';
import {ComptFormField} from '@compt/common/forms/compt-form-field/compt-form-field';
import {FieldPathByValue, FieldValues, PathValue} from 'react-hook-form';
import {ComptFormControlFieldBaseProps} from '@compt/types/form/compt-forms';
import {twMerge} from 'tailwind-merge';

export interface ComptTextAreaFieldProps<
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
> extends ComptFormControlFieldBaseProps<string, TFieldValues, TName> {
  'data-testid'?: string;
  rows?: number;
  readOnly?: boolean;
  maxLength?: number;
  watchedValue?: PathValue<TFieldValues, TName> | null | undefined;
}

export const ComptTextAreaField = <
  TFieldValues extends FieldValues,
  TName extends FieldPathByValue<TFieldValues, string>,
>(
  props: ComptTextAreaFieldProps<TFieldValues, TName>,
) => {
  const textLength = props.watchedValue?.length ?? 0;

  const field = (
    <div className="relative">
      <textarea
        id={props.id || _.kebabCase(props.label)}
        name={props.name}
        rows={props.rows ?? 3}
        className={`
          block w-full rounded-md border-0 py-100 px-200 shadow-sm ring-1
          body1 text-color-body2
          ring-inset placeholder:text-gray-400 sm:leading-6 focus:ring-2
          disabled:bg-surface-disabled disabled:text-disabled-on-light
          ${
            props.errors
              ? 'ring-stroke-critical focus:ring-stroke-critical'
              : 'ring-stroke-tertiary focus:ring-stroke-focus'
          }
        `}
        defaultValue={props.initialValue || ''}
        data-testid={props['data-testid']}
        placeholder={props.placeholder || ''}
        {...(props.register && props.validation
          ? props.register(props.name, props.validation)
          : [])}
        disabled={props.disabled}
      />
      {props.maxLength && !props.readOnly && (
        <p
          className={twMerge(
            `body1 text-right ${
              textLength <= props.maxLength ? 'text-color-body2' : 'text-color-error'
            }`,
          )}
        >
          {textLength}/{props.maxLength}
        </p>
      )}
    </div>
  );

  return (
    <ComptFormField
      id={props.id}
      field={field}
      label={props.label}
      subLabel={props.subLabel}
      validation={props.validation}
      errors={props.errors}
      data-testid={props['data-testid']}
      readOnly={props.readOnly}
    />
  );
};

import React, {useEffect, useRef, useState} from 'react';

// Hooks and methods
import {twMerge} from 'tailwind-merge';
import {Controller, ControllerRenderProps, useFormContext, UseFormReturn} from 'react-hook-form';
import {
  CurrencyOption,
  currencyOptions,
  getCurrencySymbol,
} from '@compt/utils/international-helpers';

// Types
import {GroupTableFieldValues} from './compt-group-table.types';

// Components
import {Listbox} from '@headlessui/react';
import {Float} from '@headlessui-float/react';
import {ComptSvgIcon} from '../compt-svg-icon/compt-svg-icon';

// Styles
import comptColors from '@compt/styles/compt-colors';

interface AmountAndCurrencyFieldProps {
  errors: any;
  rowNum: number;
}

export const AmountAndCurrencyField = (props: AmountAndCurrencyFieldProps) => {
  const formMethods: UseFormReturn<GroupTableFieldValues> = useFormContext();

  const sortedCurrencyOptions = sortCurrencies(currencyOptions);

  const [amountPaddingLeft, setAmountPaddingLeft] = useState(12); // Default padding in pixels
  const leftIconRef = useRef<HTMLDivElement>(null);

  const currencyErrors = props.errors?.currency;
  const amountErrors = props.errors?.amount;

  const watchedCurrencyCode = formMethods.watch(`amountOverrides.${props.rowNum}.currency`);

  // Dynamically set padding based on width of currency symbol
  useEffect(() => {
    if (leftIconRef.current) {
      const iconWidth = leftIconRef.current?.offsetWidth;
      setAmountPaddingLeft(iconWidth + 16);
    }
  }, [watchedCurrencyCode, currencyErrors, amountErrors]);

  function sortCurrencies(currencies: CurrencyOption[]): CurrencyOption[] {
    return currencies.sort((a, b) => {
      if (a.name === 'US Dollar') return -1;
      if (b.name === 'US Dollar') return 1;
      return a.name.localeCompare(b.name);
    });
  }

  function onAmountInputBlur(
    e: React.FocusEvent<HTMLInputElement>,
    field: ControllerRenderProps<GroupTableFieldValues, `amountOverrides.${number}.amount`>,
  ) {
    let {value} = e.target;
    if (!value) return;

    value = parseFloat(value).toFixed(2);

    field.onChange(value);
    field.onBlur();
  }

  return (
    <div className="w-full flex flex-col items-center mt-2 py-1">
      <div className="w-11/12 relative flex items-center">
        <span
          className={`h-6 ml-2 absolute inset-y-0 box-border top-1/2 flex items-center
        left-[2px] transform -translate-y-1/2 text-color-body2 `}
          ref={leftIconRef}
        >
          {watchedCurrencyCode && getCurrencySymbol(watchedCurrencyCode)}
        </span>{' '}
        <Controller
          name={`amountOverrides.${props.rowNum}.amount`}
          control={formMethods.control}
          rules={{required: 'Amount is required'}}
          render={({field}) => (
            <input
              required
              type="number"
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => onAmountInputBlur(e, field)}
              className={twMerge(`box-border pl-6 border
            border-stroke-divider1 w-full rounded-md text-color-body1 ${
              amountErrors && 'ring-1 ring-stroke-critical'
            } `)}
              style={{
                paddingLeft: `${amountPaddingLeft}px`,
                backgroundColor: comptColors.gray['050'],
              }}
            />
          )}
        />
        <span
          className={`w-6 z-40 mr-3 absolute flex
        top-1/2 right-[40px] transform -translate-y-1/2`}
        >
          <Controller
            control={formMethods.control}
            rules={{required: true}}
            name={`amountOverrides.${props.rowNum}.currency`}
            render={({field: {onChange, value, ref}}) => (
              <Listbox value={value} onChange={onChange}>
                <Float portal flip placement="left-end">
                  <Listbox.Button ref={ref} className="flex relative group">
                    <p className="body1 mr-2 w-[34px]">{value}</p>
                    <ComptSvgIcon
                      iconName="chevron-down-icon"
                      svgProp={{width: '24px', height: '24px'}}
                    />
                  </Listbox.Button>
                  <Listbox.Options
                    className={`absolute max-h-32 rounded-md left-0 z-10
                 bg-surface-background py-1 min-w-fit shadow-lg ring-1 ring-black 
                 ring-opacity-5 focus:outline-none sm:text-sm overflow-auto`}
                  >
                    {sortedCurrencyOptions.map((currencyOption) => (
                      <Listbox.Option
                        key={currencyOption.code}
                        value={currencyOption.code}
                        className={({active}) =>
                          twMerge(`
                    relative flex cursor-default select-none py-2 px-3 w-48
                    ${active && ' bg-gray-200 cursor-pointer'}
                    `)
                        }
                      >
                        {({selected}) => (
                          <div className="flex justify-between items-center w-full">
                            <p className="pr-2">
                              {currencyOption.name} ({currencyOption.code})
                            </p>
                            {selected && (
                              <ComptSvgIcon
                                iconName="check-icon"
                                svgProp={{width: '16px', height: '16px'}}
                              />
                            )}
                          </div>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Float>
              </Listbox>
            )}
          />
        </span>
      </div>
      {amountErrors && (
        <p className="text-color-error body3 my-2 ml-5 self-start">{amountErrors?.message}</p>
      )}
    </div>
  );
};
